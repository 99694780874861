import React, { useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as _ from "lodash";
import FliptGrid from "../../../components/fliptGrid";
import {
  ColorCodedStatusRenderer,
  LinkContainerRenderer,
} from "../../../components/fliptGrid/cellRenderers";
import PopupCellRenderer from "./popup/popupCellRenderer";
import { Creators as contractManagementActions } from "../../../redux/reducers/api/contractManagement";
import { Creators as NetworkCreationActions } from "../../../redux/reducers/api/networkCreationManagement";
import { Creators as AppActions } from "../../../redux/reducers/app";

import "./styles.scss";
import { defaultTabConfigs, processorHeaders } from "./data/tabData";

const processorTab = (props) => {
  console.log("processorTab", props);
  const { data, state, actions, checkActionType } = props;
  const { selectedTab, processorUpdateData } = state;
  const agGridRef = React.createRef();
  const processorForm = {
    processors: [],
    data_only: true,
    offset: 0,
    limit: 20,
    max_version_only: true,
  };
  const processorCellRendererParams = {
    action: {
      cellRenderer: PopupCellRenderer,
      searchArgs: {
        onClick: (action, rowdata) => checkActionType(action, rowdata),
      },
      width: 100,
    },
    processor_name: {
      cellRenderer: LinkContainerRenderer,
      searchArgs: [
        {
          key: "processor_name",
          path: "/create-processor",
          searchArgs: {
            doc_id: "doc_id",
            doc_version: "doc_version",
          },
          state: {
            // bin: "bin",
            bin_pcn_details: "bin_pcn_details",
            doc_id: "doc_id",
            doc_version: "doc_version",
            editMode: false,
            // pcn: "pcn",
            processor_end_date: "processor_end_date",
            processor_name: "processor_name",
            processor_start_date: "processor_start_date",
            processor_type: "processor_type",
            status: "status",
            // transfer_bin: "bin_pcn_details",
            // transfer_pcn: "bin_pcn_details",
          },
        },
      ],
      overrideHeader: "Processor Name",
      width: 350,
    },
    status: {
      cellRenderer: ColorCodedStatusRenderer,
    },
    pcn: {
      overrideHeader: "PCNs",
    },
    bin: {
      overrideHeader: "BINs",
    },
  };

  useEffect(() => {
    if (selectedTab === defaultTabConfigs[1].id) {
      agGridRef.current.api.refreshServerSideStore({
        route: null,
        purge: true,
      });
    }
  }, []);

  useEffect(() => {
    if (selectedTab === defaultTabConfigs[1].id) {
      agGridRef.current.api.refreshServerSideStore({
        route: null,
        purge: true,
      });
    }
  }, [processorUpdateData]);

  const serverSideGridConfig = {
    rowModel: "serverSide",
    serverSideStoreType: "partial",
    cacheBlockSize: 20,
    pagination: true,
    paginationPageSize: 20,
  };

  const serverSideGridParams = {
    form: processorForm,
    apiId: "processor-lookup",
    sagaToCall: actions.getProcessorData,
    headers: processorHeaders,
  };

  return (
    <div className="content maxHeight">
      <FliptGrid
        headers={processorHeaders}
        cellRendererParams={processorCellRendererParams}
        serverSideGridConfig={serverSideGridConfig}
        serverSideGridParams={serverSideGridParams}
        agGridRef={agGridRef}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  state: {
    contractSummaryData: state.contractManagement.contractSummaryData,
    selectedTab: state.contractManagement.selectedTab,
    contractData: state.contractManagement.contractData,
    allNetworkData: state.networkTierCreation.allNetworkTiersData,
    processorUpdateData: state.contractManagement.processorUpdateData,
  },
});

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...contractManagementActions,
    ...NetworkCreationActions,
    ...AppActions,
  };

  return {
    actions: bindActionCreators(allActions, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(processorTab);
