import React, { useState, useRef, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { Icon, Input } from "semantic-ui-react";
import { connect } from "react-redux";

import { Creators as AppActions } from "../../../redux/reducers/app";
import GlobalEditsTab from "../clientConfiguration/hierarchyDashboardTab/GlobalEditsTab";
import { Creators as EmergencyOverrideActions } from "../../../redux/reducers/api/emergencyOverride";

import BreadCrumb from "../../../components/v2/breadCrumb";
import { Creators as GlobalEditsActions } from "../../../redux/reducers/api/globalEdits";

import EmergencyOverrideDashboard from "../emergencyOverrides/EmergencyOverrideDashboard";
import FliptButton from "../../../components/v2/fliptButton";
import CreateNewGlobalEdit from "./CreateNewGlobalEdit";
const GlobalEditDashboard = (props) => {
  const { state, actions } = props;
  const { page } = state;
  useEffect(() => {
    actions.setPage("dashboard");
  }, []);

  return (
    <div id="emergency-override-configuration">
      <BreadCrumb {...props} />
      {page === "dashboard" ? (
        <EmergencyOverrideDashboard
          title={"Global Edit Configuration"}
          configuration_type={"Benefit"}
        />
      ) : null}
      {page === "create-override" ? <CreateNewGlobalEdit /> : null}
      {page === "create-override" ? <GlobalEditsTab /> : null}
      {page === "dashboard" ? null : <hr />}
      <footer className="footer-container">
        {page === "create-override" ? (
          <FliptButton
            onClick={() => actions.setPage("dashboard")}
            className="button-primary"
            name="Exit"
          />
        ) : null}
        {page === "create-override" ? (
          <FliptButton
            onClick={() => actions.validateSaveAndExit()}
            className="button-primary"
            name="Save & Exit"
          />
        ) : null}
      </footer>
    </div>
  );
};

const mapStateToProps = (state) => ({
  state: {
    user: state?.user,
    page: state.emergencyOverride?.page,
  },
});

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions,
    ...GlobalEditsActions,
  };

  return {
    actions: bindActionCreators(allActions, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GlobalEditDashboard);
