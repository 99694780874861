import React, { useState, useEffect } from 'react'
import FliptGrid from '../../../../../components/fliptGrid'
import { duplicateTherapyHeader } from './data'
import { duplicateTherapyRowCellInfo } from './rowCellInfo'
import { DropdownEditor, InputTextEditor } from '../../../../../components/fliptGrid/cellEditors'
import { DeleteRowRenderer, PreviewCellRenderer } from '../../../../../components/fliptGrid/cellRenderers'
import { medispan_allowance, messageType, rejectCode, rejectType, significance_code, standardMessage } from '../data/options'
import { Creators as DrugUtilizationReviewActions } from '../../../../../redux/reducers/api/drugUtilizationReview'
import { Creators as AppActions } from '../../../../../redux/reducers/app'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'


const duplicateTherapyGrid = (props) => {
    const { setData, data, delRow, actions, state: { classOptionData }, viewOnly } = props
    duplicateTherapyRowCellInfo.reject_type.options = rejectType.map(e => ({ key: e, text: e, value: e }))
    duplicateTherapyRowCellInfo.reject_code.options = rejectCode.map(e => ({ key: e, text: e, value: e }))
    duplicateTherapyRowCellInfo.message_type.options = messageType.map(e => ({ key: e, text: e, value: e }))
    duplicateTherapyRowCellInfo.medispan_duplicate_allowance_indicator.options = medispan_allowance.map(e => ({ key: e, text: e, value: e }))
    duplicateTherapyRowCellInfo.significance_code.options = significance_code.map(e => ({ key: e, text: e, value: e }))

    const selectedClass = data?.conditions?.length ? data?.conditions.map((e) => e.class_description) : []
    const tempHeader = [...duplicateTherapyHeader]
    if (viewOnly) tempHeader.shift()

    useEffect(() => {
        const optionData = classOptionData.map((data) => {
            return ({ key: data.class_identification_code, text: data.class_description, value: data.class_description, disabled: selectedClass.includes(data.class_description) })
        })
        duplicateTherapyRowCellInfo.class_description.options = optionData
    }, [classOptionData])


    const handleChange = (el, dropdown, rowIndex) => {
        const { name, value } = dropdown || el.currentTarget
        const conditions = data?.conditions ? [...data?.conditions] : []
        let row = { ...(conditions[rowIndex] || {}), [name]: value }
        if (name === 'significance_code') {
            actions.getClassOption({ significance_code: value })
            row['class_description'] = ''
        }
        if (name === 'class_description') {
            const activeIdIndex = classOptionData.findIndex((data) => data.class_description === value);
            if (activeIdIndex >= 0) {
                row['class_description'] = classOptionData[activeIdIndex].class_description
                row['class_identification_code'] = classOptionData[activeIdIndex].class_identification_code
            }
        }
        if (name === "message_type") {
            if (value === "Standard") {
                const { reject_code } = row
                // default rejectMessage to 'DUR Reject Error'
                const rejectMessage = (reject_code && standardMessage[reject_code]) || 'DUR Reject Error'
                row = { ...row, reject_message: rejectMessage }
                duplicateTherapyRowCellInfo.reject_message.disabled = true
            } else {
                row = { ...row, reject_message: '' }
                duplicateTherapyRowCellInfo.reject_message.disabled = false
            }
        }
        conditions[rowIndex] = row
        if (duplicateTherapyRowCellInfo[name]?.type !== 'dropdown') {
            _.debounce(() => setData({ ...data, conditions }), 900)
            return
        }
        setData({ ...data, conditions })
    }
    const cellRendererParams = {
        action: {
            cellRenderer: DeleteRowRenderer,
            state: {
                onClick: delRow,
            },
            width: 95,
        },
        reject_type: {
            overrideHeader: 'Action Taken',
        },
        duplication_allowance_indicator: {
            overrideHeader: 'Custom Duplicate Occurrence Allowed',
            cellRenderer: PreviewCellRenderer,
            searchArgs: {
                key: 'duplication_allowance_indicator',
                parseData: (e, row) => {
                    if (data?.medispan_duplicate_allowance_indicator === "Yes") { return "Not Applicable" };
                }
            },
        },


    }

    const cellEditorParams = {
        medispan_duplicate_allowance_indicator: {
            cellEditor: DropdownEditor,
            onChange: handleChange,
            disabled: viewOnly
        },
        duplication_allowance_indicator: {
            cellEditor: InputTextEditor,
            validation: 'numeric',
            onChange: handleChange,
            value: function (data) {
                if (data?.medispan_duplicate_allowance_indicator === "Yes") { return "Not Applicable" };
            },
            disabled: viewOnly,
            isDisabled: function (data) {
                return data?.medispan_duplicate_allowance_indicator === 'Yes';
            }
        },
        message_type: {
            cellEditor: DropdownEditor,
            onChange: handleChange,
            disabled: viewOnly
        },
        reject_code: {
            cellEditor: DropdownEditor,
            onChange: handleChange,
            isDisabled: function (data) {
                return data?.reject_type === 'Message'
            },
            disabled: viewOnly
        },
        reject_message: {
            cellEditor: InputTextEditor,
            onChange: handleChange,
            isDisabled: function (data) {
                return data?.message_type === 'Standard'
            },
            disabled: viewOnly
        },
        reject_type: {
            cellEditor: DropdownEditor,
            onChange: handleChange,
            disabled: viewOnly
        },
        reject_type: {
            cellEditor: DropdownEditor,
            onChange: handleChange,
        },
        significance_code: {
            cellEditor: DropdownEditor,
            onChange: handleChange,
            disabled: viewOnly
        },
        action_taken: {
            cellEditor: DropdownEditor,
            onChange: handleChange,
            disabled: viewOnly
        },
        class_identification_code: {
            cellEditor: DropdownEditor,
            onChange: handleChange,
            disabled: viewOnly
        },
        class_description: {
            cellEditor: DropdownEditor,
            onChange: handleChange,
            disabled: viewOnly
        },
    }
    return (
        <div className="grid-container">
            <FliptGrid data={data?.conditions || []} headers={tempHeader} rowCellInfo={duplicateTherapyRowCellInfo} cellEditorParams={cellEditorParams} cellRendererParams={cellRendererParams} />
        </div>
    )
}

const mapStateToProps = (state) => ({
    state: {
        userHierarchy: state?.user?.userHierarchy ?? {},
        classOptionData: state.drugUtilizationReview.classOptionData,
    },
})

const mapDispatchToProps = (dispatch) => {
    const allActions = {
        ...AppActions,
        ...DrugUtilizationReviewActions,
    }

    return {
        actions: bindActionCreators(allActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(duplicateTherapyGrid)
