import React, { useState } from "react";
import { Link } from "react-router-dom";

//import './styles.scss'
import FliptPopup from "../../../../components/fliptPopup";
import { createQueryString } from "../../../../utils/utilities";
import { opioidTabConfigs } from "../tabData";

const PopupCellRenderer = (props) => {
  const { data, searchArgs } = props;
  const { onClick, selectedTab } = searchArgs;
  const { status, program_status } = data;
  const tStatus = status || program_status;
  const [visible, setVisible] = useState(false);
  const show = () => setVisible(true);
  const hide = () => setVisible(false);
  const params = {
    doc_id: "doc_id",
    doc_name: "doc_name",
    doc_version: "doc_version",
  };
  Object.keys(params).forEach(
    (key) => (params[key] = params[key] in data ? data[params[key]] : "")
  );
  const editQs = createQueryString(params);
  const dropDownContent = (
    <div className="menu-container">
      {tStatus === "Draft" ? (
        <Link
          to={{
            pathname:
              selectedTab === opioidTabConfigs[0].id
                ? "/create-opioid-management"
                : "/add-opioid-dur",
            search: editQs,
            state: {
              editMode: true,
              selectedTab: selectedTab,
              data: data,
              isOpioid: true,
            },
          }}
        >
          <div
            role="link"
            tabIndex={-1}
            disabled={tStatus !== "Draft"}
            className="menu-item"
          >
            Edit
          </div>
        </Link>
      ) : (
        <div
          role="link"
          tabIndex={-1}
          disabled={tStatus !== "Draft"}
          className="menu-item"
        >
          Edit
        </div>
      )}
      <div
        role="button"
        tabIndex={-2}
        disabled={tStatus !== "Draft"}
        onClick={() => hide() || (onClick && onClick("publish_doc", data))}
        className={`menu-item`}
      >
        Publish
      </div>
      <div
        role="button"
        tabIndex={-3}
        disabled={tStatus !== "Published"}
        onClick={() =>
          hide() || (onClick && onClick("create_new_version", data))
        }
        className={`menu-item`}
      >
        Create New Version
      </div>
    </div>
  );
  const popupStyle = {
    boxShadow: "none !important",
    backgroundColor: "transparent",
    border: 0,
  };
  return (
    <FliptPopup
      basic
      eventsEnabled
      id="drug-management-popup"
      onClose={hide}
      onOpen={show}
      open={visible}
      style={popupStyle}
      trigger={
        <button
          style={{
            backgroundColor: "transparent",
            color: "red",
            fontSize: "1.5em",
          }}
          type="button"
          className="btn btn-primary"
          onClick={visible ? hide : show}
        >
          {" "}
          ...{" "}
        </button>
      }
    >
      {dropDownContent}
    </FliptPopup>
  );
};

export default PopupCellRenderer;
