// import DeleteIcon from '@mui/icons-material/Delete';
// import FindInPageIcon from '@mui/icons-material/FindInPage';
import React, { useEffect, } from "react";
import _ from "lodash";
import { Dropdown, Icon, Table } from "semantic-ui-react";
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import './styles.scss';
import Config from "../../config";
import FliptButton from "../v2/fliptButton";
import FliptDropdown from '../v2/fliptDropdown';
import FliptInput from '../form/fliptInput';
import QualifierRow from "./QualifierRow";
import { Creators as AppActions } from '../../redux/reducers/app'
import { Creators as QualifierActions } from '../../redux/reducers/api/qualifiers'
import { PARENT, getParentDefaultFields, getParentLabel, selectModelsAndQualifiersByParentType } from './utils';

const qlTypeOptions = [
	{ key: 'per_day', text: 'Per Day', value: 'per_day' },
	{ key: 'per_prescription', text: 'Per Prescription', value: 'per_prescription' },
	{ key: 'per_period', text: 'Per Period', value: 'per_period' },
]

const qlSupTypeOptions = {
	per_day: [
		{ key: '', text: '', value: '' },
		{ key: 'ql_ratio', text: 'QL Ratio', value: 'ql_ratio' },
	],
	per_prescription: [
		{ key: 'max_quantity', text: 'Max Quantity', value: 'max_quantity' },
		{ key: 'max_days_supply', text: 'Max Days Supply', value: 'max_days_supply' },
	],
	per_period: [
		{ key: 'max_quantity', text: 'Max Quantity', value: 'max_quantity' },
		{ key: 'max_days_supply', text: 'Max Days Supply', value: 'max_days_supply' },
		{ key: 'max_fills', text: 'Max Number of Fills', value: 'max_fills' },
	],
}

const rebateTypeOptions = [
	{ key: 'fixed', text: 'Fixed', value: 'fixed' },
	{ key: 'wac_percentage', text: 'WAC Percentage', value: 'wac_percentage' },
	{ key: 'percentage', text: 'Percentage', value: 'percentage' },
]

function logicTreeInterpreter(rows) {
	const logicTree = {
		parentRule: 'OR',
		order: [],
		dropdownRulePosition: {},
	};
	const colorList = ["#F1EFEF", "#C3C1C1"];
	try {
		const rawLogicTree = (rows.find((row) => row.parent_type && row.logic_tree) || {}).logic_tree || [];
		logicTree.rawLogicTree = rawLogicTree;
		if (rawLogicTree.length === 0) {
			return logicTree;
		}
		for (let index = 0; index < rawLogicTree.length; index++) {
			const logicRow = rawLogicTree[index];
			if (index === 0) {
				logicTree.parentRule = logicRow;
				continue;
			}
			if (Array.isArray(logicRow)) {
				const idList = logicRow.slice(1)
				idList.forEach((id) => logicTree[id] = colorList[index % 2])
				logicTree.order.push(idList.shift())
				const parentToRenderDropDown = logicTree.order.length - 1
				const groupLogic = logicRow[0]
				logicTree[parentToRenderDropDown] = groupLogic;
				logicTree.dropdownRulePosition[parentToRenderDropDown] = index;
				logicTree.order.push(...idList)
			} else if (typeof logicRow === 'string') {
				logicTree.order.push(logicRow)
			}
		}
		return logicTree
	} catch (error) {
		return logicTree;
	}

}

const sortRowsByParentLogicTree = (rows, logicTree) => {
	const sortedRows = [];
	const { order } = logicTree;
	for (let index = 0; index < order.length; index++) {
		const id = order[index];
		const row = rows.find((row) => row.id === id);
		sortedRows.push(row);
	}
	return sortedRows;
}

const Qualifiers = (props) => {
	const { actions, state, parentType } = props
	const { rawRows, models } = state;
	const logicTree = logicTreeInterpreter(rawRows)
	const rows = parentType ? sortRowsByParentLogicTree(rawRows, logicTree) : rawRows;
	let qualifiersFlattened = null;
	const opsByType = {
		def: ["=", "!="],
		number: ["=", "!=", ">", "<", ">=", "<=", "in"],
		integer: ["=", "!=", ">", "<", ">=", "<=", "in"],
		string: ["=", "!=", "like", "contains", "startswith", "endswith", "in"]
	};

	const getFieldRecord = (models = [], qualifierKey) => {
		if (!qualifiersFlattened) {
			qualifiersFlattened = {};
			models.reduce(function (accumulator, currentModel, i) {
				let model = currentModel.model;
				for (let f of currentModel.properties) {
					accumulator[model + "." + f.name] = f;
				}
				return accumulator;
			}, qualifiersFlattened);
		}
		return qualifiersFlattened[qualifierKey];
	}

	useEffect(() => {
		// prevents multiple actions dispatches for getMultipleQualifierCriteria
		// in case parent component has multiple condition levels and multiple
		// Qualifier components are rendered on the screen at once
		if (!props.preventMultipleGetCalls || props.stepLevel === 0) {
			setTimeout(actions.getMultipleQualifierCriteria({ criteria_ids: props.allCriteriaIDs }, Config.COMPONENT_DATA_LOAD_TIME));
		}

		const inclusions = selectModelsAndQualifiersByParentType(parentType);
		setTimeout(() => actions.getQualifierModels({ model_ids: props.model_ids, inclusions }), Config.COMPONENT_DATA_LOAD_TIME);
	}, [])

	const updateParentRow = (updateData, parentIndex) => {
		const newRows = [...rows];
		newRows[parentIndex] = { ...rows[parentIndex], ...updateData };
		actions.setQualifierRows({
			stepLevel: props.stepLevel,
			rows: newRows
		})
	}

	const updateRow = (column, rowIndex, updateData) => {
		// columns: qualifier, op, value
		const { childIndex, parentIndex } = rowIndex;
		if (column === "field") {
			// on field change, reset op and value
			updateData = {
				field: updateData.field,
				op: "=",
				value: ""
			};
			const [model, field] = updateData.field?.split(".");
			const modelRecord = models.find((m) => m.model === model);
			const fieldRecord = modelRecord?.properties.find((f) => f.name === field);
			if (fieldRecord.lookup) {
				actions.getQualifierLookup({ lookup_id: fieldRecord.lookup, qualifier: updateData.field });
			}
		}
		const newRows = [...rows];
		newRows[parentIndex].criteria[childIndex] = { ...rows[parentIndex].criteria[childIndex], ...updateData };
		actions.setQualifierRows({
			stepLevel: props.stepLevel,
			rows: newRows
		})
	};

	const updateLogicTreeOperator = (parentIndex, operator) => {
		const newLogicTree = [...logicTree.rawLogicTree];
		if (parentIndex === 0) {
			newLogicTree[parentIndex] = operator;
		} else {
			newLogicTree[parentIndex][0] = operator;
		}
		const rowsToSet = rows.map((row) => {
			return {
				...row,
				logic_tree: newLogicTree,
			}
		});
		actions.setQualifierRows({
			stepLevel: props.stepLevel,
			rows: rowsToSet
		})
	}

	const getNextSequence = () => {
		const order = [...logicTree.order] || [];
		if (order.length === 0) return 1;
		const largestInSequence = order.sort((a, b) => {
			// expect sequence to be partenType + number
			// strip parentType and sort by number
			const numA = parseInt(a.slice(parentType.length), 10);
			const numB = parseInt(b.slice(parentType.length), 10);
			// sort largest to smallest
			return numB - numA;
		})[0]
		return parseInt(largestInSequence.slice(parentType.length), 10) + 1;
	}

	const addParentRow = (parentID) => {
		const parentDefaultFields = getParentDefaultFields(parentType);
		const nextSequence = getNextSequence();
		const id = parentType + nextSequence;
		let newRows = []
		const newParentObject = { ...parentDefaultFields, logic_tree: [logicTree.parentRule, id], id, parent_type: parentType, criteria: [] };
		if (rows.length === 0) {
			newRows = [newParentObject];
		} else {
			newRows = [...rows, newParentObject]
		}
		const logic_tree = (rows[0] || {}).logic_tree || [logicTree.parentRule];
		const parentLogicTreeIndex = logic_tree.findIndex((id) => {
			if (Array.isArray(id)) {
				return id.includes(parentID);
			}
			return id === parentID;
		});
		if (parentLogicTreeIndex === -1) {
			logic_tree.push(id);
		} else if (Array.isArray(logic_tree[parentLogicTreeIndex])) {
			logic_tree[parentLogicTreeIndex].push(id);
		} else if (typeof logic_tree[parentLogicTreeIndex] === 'string') {
			logic_tree[parentLogicTreeIndex] = ["AND", logic_tree[parentLogicTreeIndex], id];
		}
		const rowsToSet = newRows.map((row) => {
			return {
				...row,
				logic_tree,
			}
		});
		actions.setQualifierRows({
			stepLevel: props.stepLevel,
			rows: rowsToSet
		})

	}

	const removeParentRow = (parentID) => {
		const logic_tree = (rows[0] || {}).logic_tree || [logicTree.parentRule];
		const newRows = rows.filter((row) => row.id !== parentID);
		const newLogicTree = logic_tree.reduce((tree, id) => {
			if (Array.isArray(id)) {
				const cleanSubList = id.filter((childId) => childId !== parentID);
				if (cleanSubList.length > 2) {
					tree.push(cleanSubList);
				} else if (cleanSubList.length === 2) {
					tree.push(cleanSubList[1]);
				}
			}
			if (typeof id === 'string' && id !== parentID) {
				tree.push(id);
			}
			return tree;
		}, [])
		const rowsToSet = newRows.map((row) => {
			return {
				...row,
				logic_tree: newLogicTree,
			}
		});
		actions.setQualifierRows({
			stepLevel: props.stepLevel,
			rows: rowsToSet
		})
	}

	const addCriteriaRow = (parentIndex) => {
		const newRows = _.cloneDeep(rows);
		const row = newRows[parentIndex]
		if (row) {
			row.criteria.push({ field: "", op: "=", value: "", id: 'condition' + (row.criteria.length + 1) });
			newRows[parentIndex] = row
		} else {
			const newParent = {
				parent_type: '',
				criteria: [{ field: "", op: "=", value: "", id: 'condition1' }],
			}
			newRows.push(newParent);
		}
		actions.setQualifierRows({
			stepLevel: props.stepLevel, rows: newRows
		})
	};

	const deleteCriteriaRow = (rowIndex) => {
		const { childIndex, parentIndex } = rowIndex;
		const newRows = [...rows];
		newRows[parentIndex].criteria = newRows[parentIndex].criteria.filter((row, i) => i !== childIndex);
		actions.setQualifierRows({
			stepLevel: props.stepLevel,
			rows: newRows
		})
	};

	const renderLegacyQualifier = (parent, parentIndex) => {
		return (
			<>
				<table className="qualifiers-table" key={parentIndex}>
					<thead className="criteria-header">
						<tr>
							<th className={'criteria-header-label'}>Qualifier</th>
							<th className={'criteria-header-label'}>Operator</th>
							<th className={'criteria-header-label'}>Value</th>
							<th className={'criteria-header-label'}>Action</th>
						</tr>
					</thead>
					<tbody>
						{parent.criteria?.length && models.length ?
							parent.criteria.map((row, childIndex) => (
								<QualifierRow
									rowIndex={{ parentIndex, childIndex }}
									rowData={row}
									deleteRow={deleteCriteriaRow}
									updateRow={updateRow}
									models={models}
									opsByType={opsByType}
									getFieldRecord={getFieldRecord}
								/>
							)) :
							<tr>
								<td className="criteria-table-data-emtpy" colspan="4">
									No qualifiers defined.
								</td>
							</tr>
						}
					</tbody>
				</table>
				<section className="criteria-footer-section">
					<FliptButton name={"Add Criteria"} onClick={() => addCriteriaRow(parentIndex)} className={'criteria-footer-button'}>
						<Icon name="add" size="" className="criteria-footer-icon" />
					</FliptButton>
				</section>
			</>
		)
	}

	const renderQuantityLimitQualifier = (parent, parentIndex) => {
		const displayOperator = logicTree[parentIndex];
		const dropdownRulePosition = logicTree.dropdownRulePosition[parentIndex];
		const addBottomBorderRadius = logicTree[parentIndex + 1];
		return (
			<section style={{
				backgroundColor: logicTree[parent.id],
				borderTopLeftRadius: (displayOperator ? "8px" : ""),
				borderTopRightRadius: (displayOperator ? "8px" : ""),
				borderBottomLeftRadius: (addBottomBorderRadius ? "8px" : ""),
				borderBottomRightRadius: (addBottomBorderRadius ? "8px" : ""),
			}} className='parent-container'>
				{displayOperator ?
					<section className='parent-operator-container child-one'>
						<FliptDropdown
							className='parent-operator-dropdown'
							options={[
								{ key: 'OR', text: 'OR', value: 'OR' },
								{ key: 'AND', text: 'AND', value: 'AND' },
							]}
							onChange={(el, dropdown) => updateLogicTreeOperator(dropdownRulePosition, dropdown.value)}
							value={displayOperator}
						/>
					</section>
					: <section className='parent-operator-container' />}
				<section className="child-two">
					<section>
						<Table className={`qualifiers-table qualifiers-parent-table ${parent.criteria?.length > 0 ? 'no-bottom-radius' : ''}`}>
							<Table.Header className="criteria-header">
								<Table.Row>
									<Table.HeaderCell className={'criteria-header-label'}>
										QL Type
									</Table.HeaderCell>
									<Table.HeaderCell className={'criteria-header-label'}>
										QL Subtype
									</Table.HeaderCell>
									<Table.HeaderCell className={'criteria-header-label'}>
										Value
									</Table.HeaderCell>
									<Table.HeaderCell className={'criteria-header-label'}>
										Lookback
									</Table.HeaderCell>
									<Table.HeaderCell className={'criteria-header-label'}>
										Actions
									</Table.HeaderCell>
								</Table.Row>
							</Table.Header>
							<Table.Body>
								<Table.Row>
									<Table.Cell>
										<FliptDropdown
											placeholder='Quantity Limit Type'
											options={qlTypeOptions}
											value={parent.ql_type}
											name='ql_type'
											onChange={(e, dropdown) => updateParentRow({ ql_type: dropdown.value }, parentIndex)}
										/>
									</Table.Cell>
									<Table.Cell>
										<FliptDropdown
											name='subtype'
											placeholder='Type'
											options={qlSupTypeOptions[parent.ql_type || 'per_day']}
											value={parent.subtype}
											onChange={(e, dropdown) => updateParentRow({ subtype: dropdown.value }, parentIndex)}
										/>
									</Table.Cell>
									<Table.Cell>
										<FliptInput
											className='parent-input'
											placeholder=''
											value={parent.value}
											name='value'
											onChange={(el) => updateParentRow({ value: el.target.value }, parentIndex)}
											type='number'
										/>
									</Table.Cell>
									<Table.Cell>
										<FliptInput
											className='parent-input'
											placeholder=''
											value={parent.lookback}
											name='lookback'
											onChange={(el) => updateParentRow({ lookback: el.target.value }, parentIndex)}
											type='number'
										/>
									</Table.Cell>
									<Table.Cell>
										<Dropdown icon={<Icon size="large" name="angle down" />} pointing='top right' className='link item'>
											<Dropdown.Menu>
												<Dropdown.Item
													onClick={() => addParentRow(parent.id)}
												>
													Add QL
												</Dropdown.Item>
												<Dropdown.Item
													onClick={() => addCriteriaRow(parentIndex)}
												>
													Add Criteria
												</Dropdown.Item>
												<Dropdown.Item
													onClick={() => removeParentRow(parent.id, parentIndex)}
												>
													Delete
												</Dropdown.Item>
											</Dropdown.Menu>
										</Dropdown>
									</Table.Cell>
								</Table.Row>
							</Table.Body>
						</Table>

					</section>
					<section>
						{parent.criteria?.length && models.length ? (
							<table style={{ backgroundColor: 'white', borderTopLeftRadius: '0px', borderTopRightRadius: '0px' }} className="qualifiers-table" key={parentIndex}>
								<thead className="criteria-header">
									<tr>
										<th className={'criteria-header-label'}>Qualifier</th>
										<th className={'criteria-header-label'}>Operator</th>
										<th className={'criteria-header-label'}>Value</th>
										<th className={'criteria-header-label'}>Action</th>
									</tr>
								</thead>
								<tbody>
									{parent.criteria.map((row, childIndex) => (
										<QualifierRow
											rowIndex={{ parentIndex, childIndex }}
											rowData={row}
											deleteRow={deleteCriteriaRow}
											updateRow={updateRow}
											models={models}
											opsByType={opsByType}
											getFieldRecord={getFieldRecord}
										/>
									))}
								</tbody>
							</table>
						) : null}
					</section>
				</section>
			</section>
		)
	}

	const renderRebatesQualifier = (parent, parentIndex) => {
		const displayOperator = logicTree[parentIndex];
		const dropdownRulePosition = logicTree.dropdownRulePosition[parentIndex];
		const addBottomBorderRadius = logicTree[parentIndex + 1];
		return (
			<section style={{
				backgroundColor: logicTree[parent.id],
				borderTopLeftRadius: (displayOperator ? "8px" : ""),
				borderTopRightRadius: (displayOperator ? "8px" : ""),
				borderBottomLeftRadius: (addBottomBorderRadius ? "8px" : ""),
				borderBottomRightRadius: (addBottomBorderRadius ? "8px" : ""),
			}} className='parent-container'>
				{displayOperator ?
					<section className='parent-operator-container child-one'>
						<FliptDropdown
							className='parent-operator-dropdown'
							options={[
								{ key: 'OR', text: 'OR', value: 'OR' },
								{ key: 'AND', text: 'AND', value: 'AND' },
							]}
							onChange={(el, dropdown) => updateLogicTreeOperator(dropdownRulePosition, dropdown.value)}
							value={displayOperator}
						/>
					</section>
					: <section className='parent-operator-container' />}
				<section className="child-two">
					<section>
						<Table className={`qualifiers-table qualifiers-parent-table ${parent.criteria?.length > 0 ? 'no-bottom-radius' : ''}`}>
							<Table.Header className="criteria-header">
								<Table.Row>
									<Table.HeaderCell className={'criteria-header-label'}>
										POS Rebate Type
									</Table.HeaderCell>
									<Table.HeaderCell className={'criteria-header-label'}>
										POS Rebate Factor
									</Table.HeaderCell>
									<Table.HeaderCell className={'criteria-header-label'} />
									<Table.HeaderCell className={'criteria-header-label'} />
									<Table.HeaderCell className={'criteria-header-label'}>
										Actions
									</Table.HeaderCell>
								</Table.Row>
							</Table.Header>
							<Table.Body>
								<Table.Row>
									<Table.Cell width={2}>
										<FliptDropdown
											placeholder='POS Rebate Type'
											options={rebateTypeOptions}
											value={parent.pos_rebate_type}
											name='pos_rebate_type'
											onChange={(e, dropdown) => updateParentRow({ pos_rebate_type: dropdown.value }, parentIndex)}
										/>
									</Table.Cell>
									<Table.Cell width={4}>
										<FliptInput
											className='parent-input'
											placeholder='POS Rebate Factor'
											name='pos_rebate_factor'
											type='number'
											value={parent.pos_rebate_factor}
											onChange={(el) => updateParentRow({ pos_rebate_factor: el.target.value }, parentIndex)}
										/>
									</Table.Cell>
									<Table.Cell width={1} />
									<Table.Cell width={2} />
									{/* <Table.Cell>
										<FliptInput
											className='parent-input'
											placeholder=''
											value={parent.value}
											name='value'
											onChange={(el) => updateParentRow({ value: el.target.value }, parentIndex)}
											type='number'
										/>
									</Table.Cell> */}
									{/* <Table.Cell>
										<FliptInput
											className='parent-input'
											placeholder=''
											value={parent.lookback}
											name='lookback'
											onChange={(el) => updateParentRow({ lookback: el.target.value }, parentIndex)}
											type='number'
										/>
									</Table.Cell> */}
									<Table.Cell width={1}>
										<Dropdown icon={<Icon size="large" name="angle down" />} pointing='top right' className='link item'>
											<Dropdown.Menu>
												<Dropdown.Item
													onClick={() => addParentRow(parent.id)}
												>
													Add Rebate Condition
												</Dropdown.Item>
												<Dropdown.Item
													onClick={() => addCriteriaRow(parentIndex)}
												>
													Add Criteria
												</Dropdown.Item>
												<Dropdown.Item
													onClick={() => removeParentRow(parent.id, parentIndex)}
												>
													Delete
												</Dropdown.Item>
											</Dropdown.Menu>
										</Dropdown>
									</Table.Cell>
								</Table.Row>
							</Table.Body>
						</Table>

					</section>
					<section>
						{parent.criteria?.length && models.length ? (
							<table style={{ backgroundColor: 'white', borderTopLeftRadius: '0px', borderTopRightRadius: '0px' }} className="qualifiers-table" key={parentIndex}>
								<thead className="criteria-header">
									<tr>
										<th className={'criteria-header-label'}>Qualifier</th>
										<th className={'criteria-header-label'}>Operator</th>
										<th className={'criteria-header-label'}>Value</th>
										<th className={'criteria-header-label'}>Action</th>
									</tr>
								</thead>
								<tbody>
									{parent.criteria.map((row, childIndex) => (
										<QualifierRow
											rowIndex={{ parentIndex, childIndex }}
											rowData={row}
											deleteRow={deleteCriteriaRow}
											updateRow={updateRow}
											models={models}
											opsByType={opsByType}
											getFieldRecord={getFieldRecord}
										/>
									))}
								</tbody>
							</table>
						) : null}
					</section>
				</section>
			</section>
		)
	}

	const parentOrLegacyRenderer = (parent, parentIndex) => {
		const { parent_type } = parent;
		switch (parent_type) {
			case PARENT.QL:
				return renderQuantityLimitQualifier(parent, parentIndex)
			case PARENT.REBATE:
				return renderRebatesQualifier(parent, parentIndex)
			default:
				return renderLegacyQualifier(parent, parentIndex)
		}
	}

	return (
		<>
			<div className="criteria-wrap" id="qualifiers">
				{parentType ? (
					<section className={'parent-qualifier-container' + ' ' + (rows.length > 1 && models.length ? 'parent-qualifier-border' : '')}>
						<section className='parent-controls-container'>
							{rows.length > 1 && models.length ?
								<FliptDropdown
									className='parent-operator-dropdown'
									options={[
										{ key: 'OR', text: 'OR', value: 'OR' },
										{ key: 'AND', text: 'AND', value: 'AND' },
									]}
									onChange={(el, dropdown) => updateLogicTreeOperator(0, dropdown.value)}
									value={logicTree?.parentRule}
								/>
								: <section />}
							<section>
								<FliptButton name={`Add ${getParentLabel(parentType)}`} onClick={() => addParentRow(0)} className={'criteria-footer-button'}>
									<Icon name="add" size="" className="criteria-footer-icon" />
								</FliptButton>
							</section>

						</section>
						<section>
							{
								rows.length && models.length ?
									rows.map((parent, parentIndex) => parentOrLegacyRenderer(parent, parentIndex)) :
									(
										<section>

											<div className="criteria-table-data-emtpy">
												No qualifiers defined.
											</div>
										</section>
									)
							}
						</section>
					</section>
				) : (rows.length && models.length ?
					rows.map((parent, parentIndex) => parentOrLegacyRenderer(parent, parentIndex)) :
					(<>
						<div className="criteria-table-data-emtpy">
							No qualifiers defined.
						</div>
						<section className="criteria-footer-section">
							<FliptButton name={"Add Criteria"} onClick={() => addCriteriaRow(0)} className={'criteria-footer-button'}>
								<Icon name="add" size="" className="criteria-footer-icon" />
							</FliptButton>
						</section>
					</>
					))}
			</div>
		</>
	)
}

const mapStateToProps = (state, props) => ({
	active: props.active ?? true,
	state: {
		app: state.app,
		qualifierRows: state.qualifiers.qualifierRows,
		models: state.qualifiers.qualifierModels || [],
		rawRows: state.qualifiers?.qualifierRows[props.stepLevel] || [],
	},
})

const mapDispatchToProps = (dispatch) => {
	const allActions = {
		...AppActions,
		...QualifierActions,
	}

	return {
		actions: bindActionCreators(allActions, dispatch),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Qualifiers);
