import React, { useState, useEffect } from 'react'
import FliptGrid from '../../../../../components/fliptGrid'
import { drugToDrugHeader } from './data'
import { DatePickerEditor, DropdownEditor, InputTextEditor } from '../../../../../components/fliptGrid/cellEditors'
import { DeleteRowRenderer, PreviewCellRenderer } from '../../../../../components/fliptGrid/cellRenderers'
import { documentationLevel, managementLevel, messageType, rejectCode, rejectType, severity, standardMessage } from '../data/options'
import { rowCellInfo } from './rowCellInfo'


export default function drugToDrugInteractionGrid(props) {
    const { setData, data, delRow, viewOnly } = props

    rowCellInfo.reject_type.options = rejectType.map(e => ({ key: e, text: e, value: e }))
    rowCellInfo.reject_code.options = rejectCode.map(e => ({ key: e, text: e, value: e }))
    rowCellInfo.message_type.options = messageType.map(e => ({ key: e, text: e, value: e }))
    rowCellInfo.management_level.options = managementLevel
    rowCellInfo.severity_level.options = severity
    rowCellInfo.documentation_level.options = documentationLevel

    const tempHeader = [...drugToDrugHeader]
    if (viewOnly) tempHeader.shift()
    const handleChange = (el, dropdown, rowIndex, gridApi) => {
        const { name, value } = dropdown || el.currentTarget
        const conditions = data?.conditions ? [...data?.conditions] : []
        let row = { ...(conditions[rowIndex] || {}), [name]: value }
        if (name === "message_type") {
            if (value === "Standard") {
                const { reject_code } = row
                // default rejectMessage to 'DUR Reject Error'
                const rejectMessage = (reject_code && standardMessage[reject_code]) || 'DUR Reject Error'
                row = { ...row, reject_message: rejectMessage }
                rowCellInfo.reject_message.disabled = true
            } else {
                row = { ...row, reject_message: '' }
                rowCellInfo.reject_message.disabled = false
            }
        }
        conditions[rowIndex] = row
        if (rowCellInfo[name]?.type !== 'dropdown') {
            _.debounce(() => setData({ ...data, conditions }), 900)
            return
        }
        setData({ ...data, conditions })
    }
    const getTextForValue = (options, value) => {
        if (!value?.length) return
        return value.map((data) => options.find(e => e.value === data)?.text || '')
    }
    const cellRendererParams = {
        action: {
            cellRenderer: DeleteRowRenderer,
            state: {
                onClick: delRow,
            },
            width: 95,
        },
        reject_type: {
            overrideHeader: 'Action Taken'
        },
        date_of_config: {
            cellRenderer: PreviewCellRenderer,
            searchArgs: {
                key: 'date_of_config',
                parseData: (e, row) => e && moment(e).format('MM-DD-YYYY'),
            },
        },
        severity_level: {
            cellRenderer: PreviewCellRenderer,
            searchArgs: {
                key: 'severity_level',
                parseData: (e, row) => getTextForValue(severity, row['severity_level']) || '',
            },
        },
        management_level: {
            cellRenderer: PreviewCellRenderer,
            searchArgs: {
                key: 'management_level',
                parseData: (e, row) => getTextForValue(managementLevel, row['management_level']) || '',
            },
        },
        documentation_level: {
            cellRenderer: PreviewCellRenderer,
            searchArgs: {
                key: 'documentation_level',
                parseData: (e, row) => getTextForValue(documentationLevel, row['documentation_level']) || '',
            },
        },
        prod_effective_date: {
            cellRenderer: PreviewCellRenderer,
            searchArgs: {
                key: 'prod_effective_date',
                parseData: (e, row) => e && moment(e).format('MM-DD-YYYY') || '',
            },
        },
        prod_term_date: {
            cellRenderer: PreviewCellRenderer,
            searchArgs: {
                key: 'prod_term_date',
                parseData: (e, row) => e && moment(e).format('MM-DD-YYYY') || '',
            },
        },
    }

    const cellEditorParams = {
        reject_type: {
            cellEditor: DropdownEditor,
            onChange: handleChange,
            disabled: viewOnly
        },
        reject_code: {
            cellEditor: DropdownEditor,
            onChange: handleChange,
            disabled: viewOnly,
            isDisabled: function (data) {
                return data?.reject_type === 'Message'
            },
        },
        message_type: {
            cellEditor: DropdownEditor,
            onChange: handleChange,
            disabled: viewOnly
        },
        reject_message: {
            cellEditor: InputTextEditor,
            onChange: handleChange,
            disabled: viewOnly,
            isDisabled: function (data) {
                return data?.message_type === 'Standard'
            },
        },
        management_level: {
            cellEditor: DropdownEditor,
            onChange: handleChange,
            disabled: viewOnly
        },
        severity_level: {
            cellEditor: DropdownEditor,
            onChange: handleChange,
            disabled: viewOnly
        },
        documentation_level: {
            cellEditor: DropdownEditor,
            onChange: handleChange,
            disabled: viewOnly
        },
        application: {
            cellEditor: InputTextEditor,
            onChange: handleChange,
            disabled: viewOnly
        },
        version: {
            cellEditor: InputTextEditor,
            onChange: handleChange,
            disabled: viewOnly
        },
        date_of_config: {
            cellEditor: DatePickerEditor,
            onChange: handleChange,
            disabled: viewOnly
        },
        prod_effective_date: {
            cellEditor: DatePickerEditor,
            onChange: handleChange,
            disabled: viewOnly
        },
        prod_term_date: {
            cellEditor: DatePickerEditor,
            onChange: handleChange,
            disabled: viewOnly
        },
        lookback_period: {
            cellEditor: InputTextEditor,
            onChange: handleChange,
            disabled: viewOnly
        },
        onset: {
            cellEditor: DropdownEditor,
            onChange: handleChange,
            disabled: viewOnly
        }
    }
    return (
        <div className="grid-container">
            <FliptGrid data={data?.conditions || []} headers={tempHeader} rowCellInfo={rowCellInfo} cellEditorParams={cellEditorParams} cellRendererParams={cellRendererParams} />
        </div>
    )
}
