import React, { Component } from "react";
import FliptButton from "../v2/fliptButton";

import "./styles.scss";
import Config from "../../config";
const { FAST_IT_EMAIL } = Config;

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, errorInfo: "", error: "" };
  }
  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    // should log error to server
    console.error("Application Exception Caught: ", errorInfo, error);
    this.setState({ errorInfo });
  }

  render() {
    const { hasError } = this.state;
    return hasError ? (
      <div className="error-container">
        <section className="error-box">
          <img
            className="error-image"
            src="/images/error_page.png"
            alt="we spilled the bottle of pills"
          />
          <h2 className="error-text">Something went wrong on our end</h2>
          <h4 className="error-subheader">
            Please try your last action again. If the problem continues to
            persist, please contact support at&nbsp;
            <a
              className="support-email"
              href="mailto:{FAST_IT_EMAIL}"
              alt="link to it email"
            >
              {FAST_IT_EMAIL}
            </a>
          </h4>
          <section className="error-actions">
            <FliptButton
              className="primary"
              onClick={() => {
                this.setState({ hasError: false });
                this.props.history.push("/");
              }}
            >
              Return Home
            </FliptButton>
          </section>
        </section>
      </div>
    ) : (
      this.props.children
    );
  }
}

export default ErrorBoundary;
