import React, { useState, useEffect } from 'react'
import FliptGrid from '../../../../../components/fliptGrid'
import { opioidHeaders } from './data'
import { opioidRowCellInfo } from './rowCellInfo'
import { DropdownEditor, InputTextEditor, } from '../../../../../components/fliptGrid/cellEditors'
import { DeleteRowRenderer } from '../../../../../components/fliptGrid/cellRenderers'
import { ageGendereditType, fillsAllowedType, memberType, messageType, rejectCode, rejectType, standardMessage } from '../data/options'


export default function opioidGrid(props) {
    const { setData, data, delRow, viewOnly } = props
    opioidRowCellInfo.reject_type.options = rejectType.map(e => ({ key: e, text: e, value: e }))
    opioidRowCellInfo.reject_code.options = rejectCode.map(e => ({ key: e, text: e, value: e }))
    opioidRowCellInfo.message_type.options = messageType.map(e => ({ key: e, text: e, value: e }))
    opioidRowCellInfo.member_type.options = memberType.map(e => ({ key: e, text: e, value: e }))

    const tempHeader = [...opioidHeaders]
    if (viewOnly) tempHeader.shift()
    const handleChange = (el, dropdown, rowIndex) => {
        const { name, value } = dropdown || el.currentTarget
        const conditions = [...(data?.conditions || [])]
        let row = { ...(conditions[rowIndex] || {}), [name]: value }
        if (name === "message_type") {
            if (value === "Standard") {
                const { reject_code } = row
                // default rejectMessage to 'DUR Reject Error'
                const rejectMessage = (reject_code && standardMessage[reject_code]) || 'DUR Reject Error'
                row = { ...row, reject_message: rejectMessage }
                opioidRowCellInfo.reject_message.disabled = true
            } else {
                row = { ...row, reject_message: '' }
                opioidRowCellInfo.reject_message.disabled = false
            }
        }
        conditions[rowIndex] = row
        if (opioidRowCellInfo[name]?.type !== 'dropdown') {
            _.debounce(() => setData({ ...data, conditions }), 900)
            return
        }
        setData({ ...data, conditions })
    }
    const cellRendererParams = {
        action: {
            cellRenderer: DeleteRowRenderer,
            state: {
                onClick: delRow,
            },
            width: 95,
        },
        reject_type: {
            overrideHeader: 'Action Taken',
        },
        short_acting_mme: {
            overrideHeader: 'Short Acting MME Limit'
        },
        long_acting_mme: {
            overrideHeader: 'Long Acting MME Limit'
        },
        mme_limit: {
            overrideHeader: 'MME Limit'
        },
        first_fill_days_supply_limitation: {
            overrideHeader: 'First Fill DS Limitation'
        },
        days_supply_limitation: {
            overrideHeader: 'DS Limitation'
        },
        non_naive_lookback: {
            overrideHeader: 'Lookback Period'
        },
        non_naive_threshold: {
            overrideHeader: 'Threshold'
        }
    }

    const cellEditorParams = {
        member_type: {
            cellEditor: DropdownEditor,
            onChange: handleChange,
            disabled: viewOnly
        },
        underage_fills_allowed: {
            cellEditor: DropdownEditor,
            onChange: handleChange,
            disabled: viewOnly
        },
        mme_limit: {
            cellEditor: InputTextEditor,
            validation: 'numeric',
            onChange: handleChange,
            disabled: viewOnly
        },
        minimum_age: {
            cellEditor: InputTextEditor,
            validation: 'numeric',
            onChange: handleChange,
            disabled: viewOnly
        },
        long_acting_mme: {
            cellEditor: InputTextEditor,
            validation: 'numeric',
            onChange: handleChange,
            //disabled: viewOnly
        },
        short_acting_mme: {
            cellEditor: InputTextEditor,
            validation: 'numeric',
            onChange: handleChange,
            disabled: viewOnly
        },
        first_fill_days_supply_limitation: {
            cellEditor: InputTextEditor,
            validation: 'numeric',
            onChange: handleChange,
            isDisabled: function (data) {
                return data?.member_type === 'Non-Naive'
            },
            disabled: viewOnly
        },

        days_supply_limitation: {
            cellEditor: InputTextEditor,
            validation: 'numeric',
            onChange: handleChange,
            disabled: viewOnly
        },
        non_naive_lookback: {
            cellEditor: InputTextEditor,
            validation: 'numeric',
            onChange: handleChange,
            disabled: viewOnly
        },
        non_naive_threshold: {
            cellEditor: InputTextEditor,
            validation: 'numeric',
            onChange: handleChange,
            disabled: viewOnly
        },
        message_type: {
            cellEditor: DropdownEditor,
            onChange: handleChange,
            disabled: viewOnly
        },
        reject_code: {
            cellEditor: DropdownEditor,
            onChange: handleChange,
            isDisabled: function (data) {
                return data?.reject_type
            },
            value: '88',
            disabled: viewOnly
        },
        reject_type: {
            cellEditor: DropdownEditor,
            onChange: handleChange,
            disabled: viewOnly
        },
        reject_message: {
            cellEditor: InputTextEditor,
            onChange: handleChange,
            isDisabled: function (data) {
                return data?.message_type === 'Standard'
            },
            disabled: viewOnly
        },
    }
    return (
        <div className="grid-container">
            <FliptGrid data={data?.conditions || []} headers={tempHeader} rowCellInfo={opioidRowCellInfo} cellEditorParams={cellEditorParams} cellRendererParams={cellRendererParams} />
        </div>
    )
}
