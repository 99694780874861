import React, { useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Creators as AppActions } from "../../../redux/reducers/app";
import { Creators as EmergencyOverrideActions } from "../../../redux/reducers/api/emergencyOverride";
import { Creators as ProgramListCreationActions } from "../../../redux/reducers/api/programListManagement";
import { Checkbox, Icon, Label, Table, TextArea } from "semantic-ui-react";
import _ from "lodash";

import FliptButton from "../../../components/v2/fliptButton";
import FliptDropdown from "../../../components/v2/fliptDropdown";
import FliptInput from "../../../components/form/fliptInput";
import FliptPharmacySearch from "../../../components/v2/fliptPharmacySearch";
import { RadioSelection } from "./components";

function SelectTextBox(props) {
  return (
    <section className="select-text-box-wrapper">
      <Checkbox
        className="select-checkbox-label"
        name={props.checkBoxName}
        label={props.CheckboxLabel}
        checked={props.checked}
        onChange={props.checkboxChange}
        value={props.checked}
      />
      <article className="select-text-area-container">
        <span className="select-text-area-label">{props.textboxLabel}</span>
        <TextArea
          className="select-text-area"
          name={props.textAreaName}
          placeholder={props.textAreaPlaceholder}
          value={props.textAreaValue}
          onChange={props.textAreaChange}
          disabled={!props.checked}
        />
      </article>
    </section>
  );
}

function SelectMultiInput(props) {
  return (
    <section className="select-multi-wrapper">
      <Checkbox
        className="select-checkbox-label"
        label={props.CheckboxLabel}
        checked={props.checked}
        onChange={props.checkboxChange}
      />
      {props.checked ? (
        <article className="select-multi-container">
          <section className="select-input-wrapper">
            <span className="select-input-label">Max Days Supply</span>
            <FliptInput
              className="tooltip-input padded"
              placeholder={props.multiInputPlaceholder}
              value={props.maxDaysOfSupply}
              name="max_ds"
              onChange={(el) => props.multiInputChange(el, props.index)}
            />
          </section>
          <section className="select-input-wrapper">
            <span className="select-input-label">Max Quantity</span>
            <FliptInput
              className="tooltip-input padded"
              placeholder={props.multiInputPlaceholder}
              value={props.maxQuantity}
              name="max_quantity"
              onChange={(el) => props.multiInputChange(el, props.index)}
            />
          </section>
          <section className="select-input-wrapper">
            <span className="select-input-label">Max Number of Fills</span>
            <FliptInput
              className="tooltip-input padded"
              placeholder={props.multiInputPlaceholder}
              value={props.maxFills}
              name="max_fills"
              onChange={(el) => props.multiInputChange(el, props.index)}
            />
          </section>
        </article>
      ) : null}
    </section>
  );
}

function BypassRow(props) {
  return (
    <Table.Row>
      <Table.Cell className="eo-table-cell">
        <FliptDropdown
          className="bypass-code-dropdown"
          placeholder="Select Bypass Code"
          options={props.options}
          name={"edit"}
          value={props.edit}
          onChange={(el, dropdown) => props.onChange(el, dropdown, props.index)}
        />
      </Table.Cell>
      <Table.Cell className="eo-table-cell">
        <FliptInput
          className="bypass-input"
          placeholder="Enter Max Days Supply"
          value={props.max_ds}
          name="max_ds"
          onChange={(el) => props.onChange(el, null, props.index)}
        />
      </Table.Cell>
      <Table.Cell className="eo-table-cell">
        <FliptInput
          className="bypass-input"
          placeholder="Enter Max Quantity"
          value={props.max_quantity}
          name="max_quantity"
          onChange={(el) => props.onChange(el, null, props.index)}
        />
      </Table.Cell>
      <Table.Cell className="eo-table-cell">
        <FliptInput
          className="bypass-input"
          placeholder="Enter Max Number of Fills"
          value={props.max_fills}
          name="max_fills"
          onChange={(el) => props.onChange(el, null, props.index)}
        />
      </Table.Cell>
      <Table.Cell className="eo-table-cell">
        <FliptButton
          className="button-primary"
          onClick={() => props.onDelete(props.index)}
        >
          <Icon name="trash" size="small" className="" />
        </FliptButton>
      </Table.Cell>
    </Table.Row>
  );
}

function EmergencyConfiguration(props) {
  const { actions, state } = props;
  const { programListDrafts = [], emergencyOverride } = state;

  useEffect(() => {
    actions.getAllProgramListData();
  }, []);

  const {
    bypass_edit_configuration,
    bypass_options,
    emergency_location_configuration,
    patient_location_configuration,
    origin_5_required,
    origin_5_missing_response,
    scc_13_required,
    scc_13_missing_response,
    pharmacy_exclusions,
    zero_dollar_copay_drugs,
  } = emergencyOverride;

  const _updateMultiCheckbox = (el, dropdown, index) => {
    const { checked } = dropdown;
    const newBypassEditConfiguration = [...bypass_edit_configuration];
    const selectionToEdit = Object.assign(
      {},
      newBypassEditConfiguration[index]
    );
    selectionToEdit["selected"] = checked;
    newBypassEditConfiguration[index] = selectionToEdit;
    actions.updateEmergencyOverrideField({
      name: "bypass_edit_configuration",
      value: newBypassEditConfiguration,
    });
  };

  const _updateMultiInput = (el, dropdown, index) => {
    let name, value;
    if (dropdown) {
      name = dropdown.name;
      value = dropdown.value;
    } else {
      name = el.currentTarget.name;
      value = el.currentTarget.value;
    }

    const newBypassEditConfiguration = [...bypass_edit_configuration];
    const selectionToEdit = Object.assign(
      {},
      newBypassEditConfiguration[index]
    );
    selectionToEdit[name] = value;
    newBypassEditConfiguration[index] = selectionToEdit;
    actions.updateEmergencyOverrideField({
      name: "bypass_edit_configuration",
      value: newBypassEditConfiguration,
    });
  };

  const _updateEditFields = (el, dropdown) => {
    const { name, value } = dropdown || el.currentTarget;
    actions.updateEmergencyOverrideField({ name, value });
  };

  const _checkboxChange = (el, dropdown) => {
    const { name, checked } = dropdown;
    actions.updateEmergencyOverrideField({ name, value: checked });
  };

  const _handleMultiCheckbox = (el, dropdown, listName) => {
    const { name, checked } = dropdown;
    if (!listName) return;
    const list = emergencyOverride[listName];
    let newList = Array.isArray(list) ? [...list] : [list];
    if (checked) {
      newList.push(name);
    } else {
      newList = list.filter((label) => label !== name);
    }
    actions.updateEmergencyOverrideField({ name: listName, value: newList });
  };

  const updatePharmacyData = (data) => {
    let pharmacyExclusions = [...pharmacy_exclusions];
    if (Array.isArray(data)) {
      pharmacyExclusions = pharmacyExclusions.concat(data);
    } else {
      pharmacyExclusions.push(data);
    }
    actions.updateEmergencyOverrideField({
      name: "pharmacy_exclusions",
      value: pharmacyExclusions,
    });
  };

  const _findPharmacy = () => {
    const { actions } = props;
    actions.setModalComponent({
      modalProperties: { size: "large" },
      contents: (
        <FliptPharmacySearch
          updatePharmacyData={(data) => updatePharmacyData(data)}
          searchForm={{ pharmacyNpi: "" }}
          editMode={true}
        />
      ),
    });
    actions.toggleModal();
  };

  const _appendBypassConfiguration = () => {
    const newBypassEditConfiguration = [...bypass_edit_configuration];
    newBypassEditConfiguration.push({
      edit: "",
      max_ds: "",
      max_quantity: "",
      max_fills: "",
    });
    actions.updateEmergencyOverrideField({
      name: "bypass_edit_configuration",
      value: newBypassEditConfiguration,
    });
  };
  const _handleOnDeleteBypassRow = (index) => {
    const newBypassEditConfiguration = bypass_edit_configuration.filter(
      (_, idx) => idx !== index
    );
    actions.updateEmergencyOverrideField({
      name: "bypass_edit_configuration",
      value: newBypassEditConfiguration,
    });
  };

  const _handleProgramSelection = (el, dropdown) => {
    const { name, value } = dropdown;
    const newProgramList = [...zero_dollar_copay_drugs];
    const newProgram = programListDrafts[value];
    if (!newProgram) return;
    newProgramList.push(programListDrafts[value]);
    actions.updateEmergencyOverrideField({
      name: "zero_dollar_copay_drugs",
      value: newProgramList,
    });
  };

  const _removePharmacy = (pharmacyNpi) => {
    const newPharmacyExclusions = pharmacy_exclusions.filter(
      (pharmacy) => pharmacy.pharmacynpi !== pharmacyNpi
    );
    actions.updateEmergencyOverrideField({
      name: "pharmacy_exclusions",
      value: newPharmacyExclusions,
    });
  };

  const renderPharmacyExclusionBadges = () =>
    pharmacy_exclusions.map((pharmacy) => (
      <Label key={pharmacy.pharmacynpi}>
        {pharmacy.pharmacyname}
        <Icon
          name="delete"
          onClick={() => _removePharmacy(pharmacy.pharmacynpi)}
        />
      </Label>
    ));

  const _removeProgramList = (id) => {
    const newProgramList = zero_dollar_copay_drugs.filter(
      (program) => program.id !== id
    );
    actions.updateEmergencyOverrideField({
      name: "zero_dollar_copay_drugs",
      value: newProgramList,
    });
  };

  const renderProgramListBadges = () =>
    zero_dollar_copay_drugs.map((program) => (
      <Label key={program.id}>
        {program.module_name}
        <Icon name="delete" onClick={() => _removeProgramList(program.id)} />
      </Label>
    ));

  const programOptions = _.map(programListDrafts, (program, index) => ({
    key: program.id,
    text: program.module_name,
    value: index,
  }));

  return (
    <div className="page-container">
      <header>
        <h1 className="page-h1">Emergency Configuration</h1>
      </header>
      <section className="double-column-container">
        <section className="radio-selection-wrapper">
          <article className="radio-header-container">
            <span className={"radio-header-label-large"}>
              Emergency Location Configuration
            </span>
          </article>
          <section className={"radio-button-container-column"}>
            <Checkbox
              className="select-checkbox-text"
              label={"Pharmacy Location"}
              name={"pharmacy"}
              checked={emergency_location_configuration?.includes("pharmacy")}
              onChange={(el, dropdown) =>
                _handleMultiCheckbox(
                  el,
                  dropdown,
                  "emergency_location_configuration"
                )
              }
            />
            <Checkbox
              className="select-checkbox-text"
              label={"Member Location"}
              name={"member"}
              checked={emergency_location_configuration?.includes("member")}
              onChange={(el, dropdown) =>
                _handleMultiCheckbox(
                  el,
                  dropdown,
                  "emergency_location_configuration"
                )
              }
            />
          </section>
        </section>
        <section className="radio-selection-wrapper">
          <article className="radio-header-container">
            <span className={"radio-header-label-large"}>
              Patient Location Configuration
            </span>
          </article>
          <section className={"radio-button-container-column"}>
            <Checkbox
              className="select-checkbox-text"
              label={"Patient Zip/Postal Code on submitted claim (325-CP)"}
              name={"on_submitted_claim"}
              checked={patient_location_configuration?.includes(
                "on_submitted_claim"
              )}
              onChange={(el, dropdown) =>
                _handleMultiCheckbox(
                  el,
                  dropdown,
                  "patient_location_configuration"
                )
              }
            />
            <Checkbox
              className="select-checkbox-text"
              label={"Patient Address from Eligibility"}
              name={"from_eligibility"}
              checked={patient_location_configuration?.includes(
                "from_eligibility"
              )}
              onChange={(el, dropdown) =>
                _handleMultiCheckbox(
                  el,
                  dropdown,
                  "patient_location_configuration"
                )
              }
            />
          </section>
        </section>
      </section>
      <section>
        <h3 className="page-h3">Claim Submission Configuration</h3>
        <section className="double-column-container">
          <SelectTextBox
            CheckboxLabel='Prescription Origin Code of "5" Required'
            checkBoxName={"origin_5_required"}
            checked={origin_5_required}
            checkboxChange={_checkboxChange}
            textboxLabel="Claim Response if Prescription Origin Code is Missing"
            textAreaPlaceholder="Enter a description..."
            textAreaName={"origin_5_missing_response"}
            textAreaValue={origin_5_missing_response}
            textAreaChange={_updateEditFields}
          />
          <SelectTextBox
            CheckboxLabel='SCC "13" Required'
            checkBoxName={"scc_13_required"}
            checked={scc_13_required}
            checkboxChange={_checkboxChange}
            textboxLabel="Claim Response if Submission Clarification Code is Missing"
            textAreaPlaceholder="Enter a description..."
            textAreaName={"scc_13_missing_response"}
            textAreaValue={scc_13_missing_response}
            textAreaChange={_updateEditFields}
          />
        </section>
      </section>
      <section className="double-column-container">
        <section>
          <h3 className="page-h3">Pharmacy Exclusions</h3>
          <section className="tooltip-input-wrapper-row">
            <FliptDropdown
              className="tooltip-input"
              placeholder="Excluded Pharmacies"
            />
            <FliptButton className="button-primary" onClick={_findPharmacy}>
              <Icon name="search" size="tiny" className="" />
            </FliptButton>
          </section>
          <section className="program-list-badge-container">
            {renderPharmacyExclusionBadges()}
          </section>
        </section>
        <section>
          <h3 className="page-h3">Zero Dollar Member Cost Share Products</h3>
          <section className="program-list-container">
            <FliptDropdown
              className="program-list-dropdown"
              placeholder="Select Member Cost Share Product"
              options={programOptions}
              fluid
              search
              value={""}
              onChange={_handleProgramSelection}
            />
            <section className="program-list-badge-container">
              {renderProgramListBadges()}
            </section>
          </section>
        </section>
      </section>
      <section>
        <h3 className="page-h3">Bypass Edit Configuration</h3>
        <section className="bypass-container">
          <Table className="eo-table" style={{ marginTop: "0px" }}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell className="eo-table-header-cell">
                  Code
                </Table.HeaderCell>
                <Table.HeaderCell className="eo-table-header-cell">
                  Max Days Supply
                </Table.HeaderCell>
                <Table.HeaderCell className="eo-table-header-cell">
                  Max Quantity
                </Table.HeaderCell>
                <Table.HeaderCell className="eo-table-header-cell">
                  Max Number of Fills
                </Table.HeaderCell>
                <Table.HeaderCell className="eo-table-header-cell">
                  Action
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {bypass_edit_configuration &&
              bypass_edit_configuration.length > 0 ? (
                bypass_edit_configuration.map((record, index) => (
                  <BypassRow
                    {...record}
                    index={index}
                    options={bypass_options}
                    onChange={_updateMultiInput}
                    onDelete={_handleOnDeleteBypassRow}
                  />
                ))
              ) : (
                <Table.Row>
                  <Table.Cell
                    className="eo-table-cell"
                    colSpan={5}
                    textAlign="center"
                  >
                    No bypass edits configured.
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
          <section className="dashboard-button-container">
            <FliptButton
              className="button-primary"
              onClick={_appendBypassConfiguration}
            >
              <Icon name="plus" size="small" className="" />
              Add Code
            </FliptButton>
          </section>
        </section>
      </section>
    </div>
  );
}

const mapStateToProps = (state) => ({
  state: {
    app: state.app,
    user: state.user,
    emergencyOverride: state.emergencyOverride,
    programListDrafts: state.rpm.documents,
  },
});

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions,
    ...EmergencyOverrideActions,
    ...ProgramListCreationActions,
  };

  return {
    actions: bindActionCreators(allActions, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmergencyConfiguration);
