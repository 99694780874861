import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { createQueryString } from '../../../../utils/utilities'
import FliptPopup from '../../../../components/fliptPopup'
import '../styles.scss'

const NewDrugPopupCellRenderer = (props) => {
  const { searchArgs, data } = props
  const { path, qsArgs, doc_type, state, onPublishClick, onNewVersionClick, onImpactAnalysisClick, screen, limit } = searchArgs
  const [visible, setVisible] = useState(false)
  const show = () => setVisible(true)
  const hide = () => setVisible(false)
  const stateData = {}

  if (state) {
    Object.keys(state).forEach((key) => {
      const colName = key === 'editMode' ? 'editMode' : state[key]
      const val = data[colName]
      stateData[key] = val || data[key] || state[key]
    })

  }
  const publish = () => {
    const params = {
      doc_id: data.doc_id,
      doc_type,
      doc_name: doc_type === 'global_pharmacy_network' ? data.doc_name : data.network_name,
      doc_version: doc_type === 'global_pharmacy_network' ? data.doc_version : data.version,
      status: 'Published'
    }
    onPublishClick(params)
  }

  const createNewVersion = () => {
    const params = {
      doc_id: data.doc_id,
      doc_type,
      doc_name: data.network_name,
      doc_version: data.version
    }
    onNewVersionClick(params)
  }

  const impactAnalysisClick = () => {
    const params = [{
      doc_id: data.doc_id,
      doc_type,
      module: doc_type,
      module_name: data.network_name,
      doc_version: data.version,
    }]
    onImpactAnalysisClick(params)
  }

  const bindData = (qsArgs) => {
    return Object.keys(qsArgs).reduce((obj, key) => {
      obj[key] = data[qsArgs[key]] || qsArgs[key]
      return obj
      //{ doc_id: 'tier_id' }
    }, {})
  }


  const viewLink = {
    pharmacyNetwork: {
      path: '/pharmacy-network-lookup',
      args: {
        doc_id: data.doc_id,
        doc_version: data.doc_version,
        // limit: 100,
        offset: 0
      }
    },
    networkTier: {
      path: '/pharmacy-network-tier-lookup',
      args: {
        network_tier: data.network_name,
      }
    },
    networkEdits: {
      path: '/pharmacy-network-tier-lookup',
      args: {
        network_tier: data.network_name,
      }
    }
  }

  const dropDownContent = (
    <div className="menu-container">
      {data.status.toLowerCase() === 'draft' ? <Link to={{ pathname: path, state: stateData, search: createQueryString(bindData({
        doc_id: data.doc_id,
        doc_version: data.version,
      })) }}>
        <div
          role="link"
          tabIndex={0}
          className="menu-item"
        >
          Edit
        </div>
      </Link> : null}
      {data.status.toLowerCase() === 'published' ? <Link>
        <div
          role="link"
          tabIndex={-1}
          className="menu-item"
          onClick={() => createNewVersion()}
        >
          Create New Version
        </div>
      </Link> : data.status.toLowerCase() === 'draft' ? <Link>
        <div
          role="link"
          tabIndex={-1}
          className="menu-item"
          onClick={() => publish()}
        >
          Publish
        </div>
      </Link> : null}
      {['global_network_tier', 'global_network_edit'].includes(doc_type) &&
        <Link
          onClick={() => impactAnalysisClick()}
        >
          <div
            role="link"
            tabIndex={0}
            className="menu-item"
          >
            Impact Analysis
          </div>
        </Link>
      }
      {((screen in viewLink) && ((data.version != "1.0") || (data.version === "1.0" && data.status.toLowerCase() === 'published'))) ?
        <Link to={{
          pathname: viewLink[screen].path,
          search: createQueryString(bindData(viewLink[screen].args))
        }}>
          <div
            role="link"
            tabIndex={-1}
            className="menu-item"
          >
            View Pharmacies
          </div>
        </Link > : null}
    </div >
  )

  const popupStyle = {
    boxShadow: 'none',
    backgroundColor: 'transparent',
    border: 0,
  }

  return (
    <FliptPopup
      basic
      eventsEnabled
      onClose={hide}
      onOpen={show}
      open={visible}
      style={popupStyle}
      trigger={<button type="button" className="btn btn-primary" onClick={visible ? hide : show}> ... </button>}
    >
      {dropDownContent}
    </FliptPopup>
  )
}

export default NewDrugPopupCellRenderer
