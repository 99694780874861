import React, { useState, useEffect } from 'react'
import FliptGrid from '../../../../../components/fliptGrid'
import { regimenComplianceHeader } from './data'
import { DatePickerEditor, DropdownEditor, InputTextEditor } from '../../../../../components/fliptGrid/cellEditors'
import { DeleteRowRenderer, PreviewCellRenderer } from '../../../../../components/fliptGrid/cellRenderers'
import { regimenCompliancemessageType, rejectCode, rejectType, standardMessage, gpiLevel, maintenance_drug } from '../data/options'
import { regimenComplianceRowCellInfo } from './rowCellInfo'


export default function RegimenComplianceGrid(props) {
    const { setData, data, delRow, viewOnly } = props

    regimenComplianceRowCellInfo.reject_type.options = rejectType.map(e => ({ key: e, text: e, value: e }))
    regimenComplianceRowCellInfo.reject_code.options = rejectCode.map(e => ({ key: e, text: e, value: e }))
    regimenComplianceRowCellInfo.message_type.options = regimenCompliancemessageType.map(e => ({ key: e, text: e, value: e }))
    regimenComplianceRowCellInfo.gpi_level.options = gpiLevel.map(e => ({ key: e, text: e, value: e }))
    regimenComplianceRowCellInfo.maintenance_drug.options = maintenance_drug.map(e => ({ key: e, text: e, value: e }))
    const tempHeader = [...regimenComplianceHeader]
    if (viewOnly) tempHeader.shift()
    const handleChange = (el, dropdown, rowIndex, gridApi) => {
        const { name, value } = dropdown || el.currentTarget
        const conditions = data?.conditions || []
        let row = { ...(conditions[rowIndex] || {}), [name]: value }
        if (name === "message_type") {
            if (value === "Standard") {
                const { reject_code } = row
                // default rejectMessage to 'DUR Reject Error'
                const rejectMessage = (reject_code && standardMessage[reject_code]) || 'DUR Reject Error'
                row = { ...row, reject_message: rejectMessage }
                regimenComplianceRowCellInfo.reject_message.disabled = true
            } else {
                row = { ...row, reject_message: '' }
                regimenComplianceRowCellInfo.reject_message.disabled = false
            }
        }
        conditions[rowIndex] = row
        if (regimenComplianceRowCellInfo[name]?.type !== 'dropdown') {
            _.debounce(() => setData({ ...data, conditions }), 900)
            return
        }
        setData({ ...data, conditions })
    }
    const getTextForValue = (options, value) => {
        if (!value?.length) return
        return value.map((data) => options.find(e => e.value === data)?.text || '')
    }
    const cellRendererParams = {
        action: {
            cellRenderer: DeleteRowRenderer,
            state: {
                onClick: delRow,
            },
            width: 95,
        },
        reject_code: {
            cellRenderer: PreviewCellRenderer,
            searchArgs: {
                key: 'reject_code',
                parseData: (e, row) => {
                    return '88'
                }
            }
        },
        gpi_level: {
            cellRenderer: PreviewCellRenderer,
            searchArgs: {
                key: 'gpi_level',
                parseData: (e, row) => {
                    return 'GPI 14'

                }
            }
        },
        reject_type: {
            overrideHeader: 'Action Taken',
            cellRenderer: PreviewCellRenderer,
            searchArgs: {
                key: 'reject_type',
                parseData: (e, row) => {
                    return 'Message'
                }
            },
        },
        percent_day_below_compliance: {
            overrideHeader: 'Percent Day below Compliance %',
        },
        prod_effective_date: {
            cellRenderer: PreviewCellRenderer,
            searchArgs: {
                key: 'prod_effective_date',
                parseData: (e, row) => e && moment(e).format('MM-DD-YYYY') || '',
            },
        },

        message_type: {
            cellRenderer: PreviewCellRenderer,
            searchArgs: {
                key: 'message_type',
                parseData: (e, row) => {
                    return 'Custom'
                }
            },
        },
        reject_message: {
            overrideHeader: 'Display Message',
        }
    }

    const cellEditorParams = {
        edit_type: {
            cellEditor: DropdownEditor,
            onChange: handleChange,
        },
        message_type: {
            cellEditor: DropdownEditor,
            onChange: handleChange,
        },
        message_type: {
            cellEditor: InputTextEditor,
            onChange: handleChange,
        },
        reject_message: {
            cellEditor: InputTextEditor,
            onChange: handleChange,
        },
        maintenance_drug: {
            cellEditor: DropdownEditor,
            onChange: handleChange,
        },
        version: {
            cellEditor: InputTextEditor,
            onChange: handleChange,
        },
        prod_effective_date: {
            cellEditor: DatePickerEditor,
            onChange: handleChange,
        },
        min_day_supply: {
            cellEditor: InputTextEditor,
            onChange: handleChange,
        },
        percent_day_below_compliance: {
            cellEditor: InputTextEditor,
            onChange: handleChange,
        },
        max_day_below_compliance: {
            cellEditor: InputTextEditor,
            onChange: handleChange,
        },
    }
    return (
        <div>
            <div className="grid-container">
                <FliptGrid data={data?.conditions || []} headers={tempHeader} rowCellInfo={regimenComplianceRowCellInfo} cellEditorParams={cellEditorParams} cellRendererParams={cellRendererParams} />
            </div>
        </div>
    )
}
