import { onsetType } from "../data/options";

export const rowCellInfo = {
    reject_message: {
        type: 'input',
        disabled: false,
    },
    reject_code: {
        type: 'dropdown',
        disabled: false,
    },

    reject_type: {
        type: 'dropdown',
        disabled: false,
    },
    message_type: {
        type: 'dropdown',
        disabled: false,
    },
    reject_message: {
        type: 'input',
        disabled: false,
    },
    management_level: {
        type: 'dropdown',
        disabled: false,
        multiple: true
    },
    severity_level: {
        type: 'dropdown',
        disabled: false,
        multiple: true
    },
    documentation_level: {
        type: 'dropdown',
        disabled: false,
        multiple: true
    },
    application: {
        type: 'input',
        disabled: false,
    },
    version: {
        type: 'dropdown',
        disabled: false,
        options: [],
    },
    date_of_config: {
        type: 'dropdown',
        disabled: false,
    },
    prod_effective_date: {
        type: 'dropdown',
        disabled: false,
        options: [],
    },
    prod_term_date: {
        type: 'dropdown',
        disabled: false,
        options: [],
    },
    onset: {
        type: 'dropdown',
        disabled: false,
        options: onsetType,
        multiple: true
    },
}

export const opioidRowCellInfo = {
    non_naive_lookback: {
        type: 'input',
        disabled: false,
    },
    non_naive_threshold: {
        type: 'input',
        disabled: false,
    },
    minimum_age: {
        type: 'input',
        disabled: false,
    },
    short_acting_mme: {
        type: 'input',
        disabled: false,
    },
    first_fill_days_supply_limitation: {
        type: 'input',
        disabled: false,
    },
    long_acting_mme: {
        type: 'input',
        disabled: false,
    },
    days_supply_limitation: {
        type: 'input',
        disabled: false,
    },
    mme_limit: {
        type: 'input',
        disabled: false,
    },
    member_type: {
        type: 'dropdown',
        disabled: false,
    },
    reject_message: {
        type: 'input',
        disabled: false,
    },
    reject_code: {
        type: 'dropdown',
        disabled: false,
    },
    reject_type: {
        type: 'dropdown',
        disabled: false,
    },
    message_type: {
        type: 'dropdown',
        disabled: false,
    },
    underage_fills_allowed: {
        type: 'dropdown',
        disabled: false,
    },
}

export const dosageRowCellInfo = {
    reject_message: {
        type: 'input',
        disabled: false,
    },
    reject_code: {
        type: 'dropdown',
        disabled: false,
    },
    reject_type: {
        type: 'dropdown',
        disabled: false,
    },
    message_type: {
        type: 'dropdown',
        disabled: false,
    },
    edit_type: {
        type: 'dropdown',
        disabled: false,
    },
}

export const restrictionRowCellInfo = {
    reject_message: {
        type: 'input',
        disabled: false,
    },
    reject_code: {
        type: 'dropdown',
        disabled: false,
    },
    reject_type: {
        type: 'dropdown',
        disabled: false,
    },
    message_type: {
        type: 'dropdown',
        disabled: false,
    },
    edit_type: {
        type: 'dropdown',
        disabled: false,
    },
}
export const duplicateRxRowCellInfo = {
    reject_message: {
        type: 'input',
        disabled: false,
    },
    reject_code: {
        type: 'dropdown',
        disabled: false,
    },

    reject_type: {
        type: 'dropdown',
        disabled: false,
    },
    message_type: {
        type: 'dropdown',
        disabled: false,
    },
    gpi_level: {
        type: 'dropdown',
        disabled: false,
    },
    fill_threshold: {
        type: 'input',
        disabled: false,
    },
}
export const duplicateTherapyRowCellInfo = {
    reject_message: {
        type: 'input',
        disabled: false,
    },
    message_type: {
        type: 'dropdown',
        disabled: false,
    },
    reject_code: {
        type: 'dropdown',
        disabled: false,
    },
    reject_type: {
        type: 'dropdown',
        disabled: false,
    },
    medispan_duplicate_allowance_indicator: {
        type: 'dropdown',
        disabled: false,
    },
    duplication_allowance_indicator: {
        type: 'input',
        disabled: false,
    },
    significance_code: {
        type: 'dropdown',
        disabled: false,
    },
    class_description: {
        type: 'dropdown',
        disabled: false,
    },
}

export const regimenComplianceRowCellInfo = {
    reject_message: {
        type: 'input',
        disabled: false,
    },
    reject_type: {
        type: 'dropdown',
        disabled: false,
    },
    reject_code: {
        type: 'dropdown',
        disabled: true,
    },
    message_type: {
        type: 'dropdown',
        disabled: false,
    },
    gpi_level: {
        type: 'dropdown',
        disabled: false,
    },
    min_day_supply: {
        type: 'input',
        disabled: false,
    },
    percent_day_below_compliance: {
        type: 'input',
        disabled: false,
    },
    maintenance_drug: {
        type: 'dropdown',
        disabled: false,
    },
    action: {
        type: 'dropdown',
        disabled: false,
    },
}
