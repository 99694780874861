import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import _ from 'lodash'
import { Creators as PlanManagementActions } from '../../../../redux/reducers/api/planManagement'
import FliptSeparator from '../../../../components/form/fliptSeparator'
import FormularyCopay from '../formularyCopay'
import CustomCopay from '../customCopay'
import PricingMixmaster from '../pricingMixmaster'
import Limitations from '../benefitLimitation'
import SectionHeader from './sectionHeader'

import { convertDateTimeToDate, convertStringToSnakeCase } from '../../../../utils/utilities'
import './styles.scss'
import Grandfathering from '../grandfathering'
import CustomMessaging from '../customMessaging'
import TransitionFill from '../transitionfill'

class Review extends Component {
  constructor(props) {
    super(props)
    const { state } = this.props
    const { formulary_copay, custom_copay } = state
    this.state = {
      formularyCopayData: this.constructCopayData(formulary_copay, 'formulary_copay'),
      customCopayData: this.constructCopayData(custom_copay, 'custom_copay'),
      grandfathering: state.grandfathering,
      custom_messaging: state.custom_messaging,
      reRender: 0,
    }
  }

  constructCopayData = (copays, section) => {
    let groupedData
    if (section === 'formulary_copay') {
      const tierData = copays?.filter(({ tier_id, tier_name }) => !!tier_id && !!tier_name)
      groupedData = _.groupBy(tierData, 'tier_id')
    } else if (section === 'custom_copay') {
      const programData = copays?.filter(({ program_id, program_name }) => !!program_id && !!program_name)
      groupedData = _.groupBy(programData, 'program_id')
    }

    const ids = Object.keys(groupedData)
    const resultRows = []

    ids.forEach((id) => {
      const tierData = groupedData[id]
      let tierName
      let programName
      let form
      const tierConditions = []
      tierData.forEach((tierDataRow) => {
        const {
          accumulate_deductible,
          accumulate_out_of_pocket,
          deductible_exempt,
          tier_name,
          program_name,
          pharmacy_type,
          cost_share,
        } = tierDataRow
        tierName = tier_name
        programName = program_name
        form = {
          accumulate_deductible,
          accumulate_out_of_pocket,
          deductible_exempt,
        }
        Object.values(cost_share).forEach((costShareRow) => {
          tierConditions.push({ ...costShareRow, network_tier: pharmacy_type ?? '' })
        })
      })
      resultRows.push({
        tier_id: section === 'formulary_copay' ? id : '',
        tier_name: tierName || '',
        program_id: section === 'custom_copay' ? id : '',
        program_name: programName || '',
        form,
        tierConditions,
      })
    })
    const resultData = section === 'formulary_copay' ? _.groupBy(resultRows, 'tier_id') : _.groupBy(resultRows, 'program_id')
    const flatResultData = Object.values(resultData).flat()

    return flatResultData
  }

  getCSValues = (dataArr) => {
    const values = dataArr.map((data) => {
      const dataKeys = Object.keys(data)
      const res = dataKeys.map((key) => {
        if (key.endsWith('_name')) {
          return data[key]
        }
        return null
      })
      return res.filter((val) => val !== null)
    })

    return values.join(', ')
  }

  renderFormSection = (sectionName, sectionFields, fieldNames, separatorKeys) => {
    const { getTabContents } = this.props
    const fiedsToSkip = ['formulary_id']
    return (
      <>
        <SectionHeader sectionName={sectionName} getTabContents={getTabContents} />
        <div className="fieldsContainer">
          {fieldNames.map((fieldName) => {
            if (fiedsToSkip.includes(fieldName)) return null
            if (fieldName === 'deductible_exempt' && sectionFields[fieldName].value.toLowerCase() === 'Y' && sectionName === 'Accumulators') {
              fiedsToSkip.push('deductible_type', 'family_deductible_limit', 'individual_deductible_embedded', 'individual_deductible_limit')
            }
            if (fieldName === 'lob' && sectionFields[fieldName].value.toLowerCase() === 'commercial') {
              fiedsToSkip.push('medicare_d_standard', 'medicare_plan_type', 'medicaid_plan')
            }
            if (sectionName === 'Application Only' && fieldName === 'app_pharmacy_popup_message') {
              let [all, nj00] = (sectionFields[fieldName].value || []).map(x => [x.message, x.no_access_message])
              if (_.isEmpty(all))
                all = _.fill(Array(2), '')
              if (_.isEmpty(nj00))
                nj00 = _.fill(Array(2), '')
              return ([
                <div className="field">
                  <div className="fieldName">Message (All)</div>
                  <div className="fieldValue">{all[0]}</div>
                </div>,
                <div className="field">
                  <div className="fieldName">No Access Message (All)</div>
                  <div className="fieldValue">{all[1]}</div>
                </div>,
                <div className="field">
                  <div className="fieldName">Message (NJ00)</div>
                  <div className="fieldValue">{nj00[0]}</div>
                </div>,
                <div className="field">
                  <div className="fieldName">No Access Message (NJ00)</div>
                  <div className="fieldValue">{nj00[1]}</div>
                </div>,
              ])
            }

            const { display_name, value } = sectionFields[fieldName]
            const values = Array.isArray(value) ? this.getCSValues(value) : value

            return ([
              <div className="field">
                <div className="fieldName">{display_name}</div>
                <div className="fieldValue">{values === true ? 'Y' : values === false ? 'N' : values}</div>
              </div>,
              separatorKeys.includes(fieldName) && <FliptSeparator />,
            ])
          })}
        </div>
      </>
    )
  }

  renderCustomSection = (sectionName, sectionFields, fieldNames, separatorKeys) => {
    const { getTabContents } = this.props
    const tabNameSnakeCase = convertStringToSnakeCase(sectionName.toLowerCase())
    if (sectionName == 'DAW Settings') {
      sectionName = 'PSC (DAW)'
    }
    return (
      <>
        <SectionHeader sectionName={sectionName} getTabContents={getTabContents} />
        <div className="fieldsContainer">
          {this.getComponent(tabNameSnakeCase, sectionFields, fieldNames, separatorKeys)}
        </div>
      </>
    )
  }

  renderCopaySection = (sectionName) => {
    const { getTabContents } = this.props
    const tabNameSnakeCase = convertStringToSnakeCase(sectionName.toLowerCase())

    return (
      <>
        <SectionHeader sectionName={sectionName} getTabContents={getTabContents} />
        <div className="fieldsContainer">
          {this.getComponent(tabNameSnakeCase)}
        </div>
      </>
    )
  }
  renderGrandfatheringSection = (sectionName) => {
    const { getTabContents } = this.props
    const tabNameSnakeCase = convertStringToSnakeCase(sectionName.toLowerCase())

    return (
      <>
        <SectionHeader sectionName={sectionName} getTabContents={getTabContents} />
        <div className="fieldsContainer" >
          {this.getComponent(tabNameSnakeCase)}
        </div>
      </>
    )
  }
  renderTransitionSection = (sectionName) => {
    const { getTabContents } = this.props
    const tabNameSnakeCase = convertStringToSnakeCase(sectionName.toLowerCase())

    return (
      <>
        <SectionHeader sectionName={sectionName} getTabContents={getTabContents} />
        <div className="fieldsContainer" >
          {this.getComponent(tabNameSnakeCase)}
        </div>
      </>
    )
  }
  renderCustomMessagingSection = (sectionName) => {
    const { getTabContents } = this.props
    const tabNameSnakeCase = convertStringToSnakeCase(sectionName.toLowerCase())

    return (
      <>
        <SectionHeader sectionName={sectionName} getTabContents={getTabContents} />
        <div className="fieldsContainer" >
          {this.getComponent(tabNameSnakeCase)}
        </div>
      </>
    )
  }
  renderPricingMMSection = (sectionName, fields) => {
    const { getTabContents } = this.props
    const { reRender } = this.state
    const { price_source = [], otc_inclusion = [], otc_price_source = [] } = fields
    const state = {
      price_source,
      otc_inclusion,
      otc_price_source,
    }
    return (
      <>
        <SectionHeader sectionName={sectionName} getTabContents={getTabContents} />
        <div className="fieldsContainer">
          <PricingMixmaster key={reRender} state={state} fieldDetails={[]} />
        </div>
      </>
    )
  }

  renderLimitationsSection = (sectionName, fields, plan_sponsor_cap) => {
    const { getTabContents } = this.props
    const { reRender } = this.state
    const state = {
      limitations: fields,
      plan_sponsor_cap: plan_sponsor_cap
    }
    return (
      <>
        <SectionHeader sectionName={sectionName} getTabContents={getTabContents} />
        <div className="fieldsContainer">
          <Limitations key={`Limitations_${reRender}`} state={state} fieldDetails={[]} />
        </div>
      </>
    )
  }
  renderTransitionSection = (sectionName, fields) => {
    const { getTabContents } = this.props
    const { reRender } = this.state
    const state = {
      transitionData: fields.transition_fill
    }
    return (
      <>
        <SectionHeader sectionName={sectionName} getTabContents={getTabContents} />
        <div className="fieldsContainer">
          <TransitionFill key={`transition_${reRender}`} state={state} fieldDetails={[]} />
        </div>
      </>
    )
  }

  getComponent = (tabName, sectionFields, fieldNames, separatorKeys) => {
    const { formularyCopayData, customCopayData, reRender, grandfathering, custom_messaging } = this.state
    const formularyCopayState = {
      tiersData: formularyCopayData,
    }

    const customCopayState = {
      programsData: customCopayData,
    }

    const customComponents = {
      plan_design_details: () => this.renderPlanDesignDetails(sectionFields),
      claim_processing_config: () => this.renderClaimProcessingConfig(sectionFields),
      formulary_copay: () => <FormularyCopay key={`formulary_copay_${reRender}`} tabName={tabName} state={formularyCopayState} />,
      custom_copay: () => <CustomCopay key={`custom_copay_${reRender}`} tabName={tabName} state={customCopayState} />,
      grandfathering: () => <Grandfathering key={`grandfathering_${reRender}`} tabName={tabName} state={grandfathering} />,
      transition: () => <TransitionFill key={`transition_fill_${reRender}`} tabName={tabName} state={sectionFields} />,
      custom_messaging: () => <CustomMessaging key={`custom_messaging_${reRender}`} tabName={tabName} state={{ customMessagingData: custom_messaging.custom_messaging }} />,
      listing_hierarchy: () => this.renderListingHierarchy(sectionFields, fieldNames, separatorKeys),
      daw_settings: () => this.renderDawSettings(sectionFields),
      compounds: () => this.renderCompounds(sectionFields),
    }
    return customComponents[tabName]()
  }

  renderPlanDesignDetails = (sectionFields) => {
    const { plan_name, effective_start_date, effective_end_date, lob,
      formulary_name,
      medicaid_plan, medicare_pbp, medicare_contract_id,
      medicare_plan_type, medicare_plan_id } = sectionFields
    const planInfo = []

    return ([
      <>
        <div className="field">
          <div className="fieldName">Plan Name</div>
          <div className="fieldValue">{plan_name}</div>
        </div>
        <div className="field">
          <div className="fieldName">Effective Start Date</div>
          <div className="fieldValue">{convertDateTimeToDate(effective_start_date)}</div>
        </div>
        <div className="field">
          <div className="fieldName">Effective End Date</div>
          <div className="fieldValue">{convertDateTimeToDate(effective_end_date)}</div>
        </div>
        <div className="field">
          <div className="fieldName">Line of Business</div>
          <div className="fieldValue">{lob}</div>
        </div>
        <FliptSeparator />
        <div className="field">
          <div className="fieldName">Type of Medicare Plans</div>
          <div className="fieldValue">{medicare_plan_type}</div>
        </div>
        <div className="field">
          <div className="fieldName">Medicare Contract ID</div>
          <div className="fieldValue">{medicare_contract_id}</div>
        </div>
        <div className="field">
          <div className="fieldName">Medicare PBP</div>
          <div className="fieldValue">{medicare_pbp}</div>
        </div>
        <div className="field">
          <div className="fieldName">Medicaid Plan</div>
          <div className="fieldValue">{medicaid_plan}</div>
        </div>
        <div className="field">
          <div className="fieldName">Formulary</div>
          <div className="fieldValue">{formulary_name}</div>
        </div>
      </>,
    ])
  }
  renderClaimProcessingConfig = (sectionFields) => {
    const configData = sectionFields?.carry_over_accumulation_settings || {}
    return (
      <div className="fields-container">
        <div className="field">
          <div className="fieldName">Carry Over Accumulations Rx OOP</div>
          <div className="fieldValue">{configData?.carry_over_accumulations_rx_oop}</div>
        </div>
        <div className="field">
          <div className="fieldName">Carry Over Accumulations Rx Deductible</div>
          <div className="fieldValue">{configData?.carry_over_accumulations_rx_ded}</div>
        </div>
        <div className="field">
          <div className="fieldName">Carry Over Accumulations Medical OOP</div>
          <div className="fieldValue">{configData?.carry_over_accumulations_med_oop}</div>
        </div>
        <div className="field">
          <div className="fieldName">Carry Over Accumulations Medical Deductible</div>
          <div className="fieldValue">{configData?.carry_over_accumulations_med_ded}</div>
        </div>
      </div>
    )
  }

  renderListingHierarchy = (sectionFields, fieldNames) => {
    const { um_ranking, ...umTypes } = sectionFields

    const umRankings = um_ranking?.map(({ rank, um_type }) => (
      <div className="field">
        <div className="fieldName">{`Rank ${rank}`}</div>
        <div className="fieldValue">{um_type}</div>
      </div>
    ))
    const separator = <FliptSeparator />
    const res = [umRankings, separator]
    const programRanks = this.renderProgramRanks(umTypes?.programs)
    const paRanks = this.renderPARanks(umTypes?.clinical_pa)
    const qlRanks = this.renderQLRanks(umTypes?.clinical_ql)
    const stRanks = this.renderSTRanks(umTypes?.clinical_step_therapy)

    fieldNames.map((fieldName) => {
      if (fieldName === 'programs') {
        const subHeading = this.renderHierarchySubHeading('Programs')
        res.push(subHeading, programRanks, separator)
      } else if (fieldName === 'clinical_pa') {
        const subHeading = this.renderHierarchySubHeading('Prior Authorization')
        res.push(subHeading, paRanks, separator)
      } else if (fieldName === 'clinical_ql') {
        const subHeading = this.renderHierarchySubHeading('Quantity Limit')
        res.push(subHeading, qlRanks, separator)
      } else if (fieldName === 'clinical_step_therapy') {
        const subHeading = this.renderHierarchySubHeading('Step Therapy')
        res.push(subHeading, stRanks, separator)
      }
      return null
    })

    return res
  }

  renderHierarchySubHeading = (subHeading) => (
    <div className="subTitle fliptSeparator">
      {`${subHeading}`}
      <br />
    </div>
  )

  renderProgramRanks = (ranks) => (
    ranks?.map(({ program_name }, index) => (
      <div className="field">
        <div className="fieldName">{`Rank ${index + 1}`}</div>
        <div className="fieldValue">{program_name}</div>
      </div>
    ))
  )

  renderPARanks = (ranks) => (
    ranks?.map(({ pa_name }, index) => (
      <div className="field">
        <div className="fieldName">{`Rank ${index + 1}`}</div>
        <div className="fieldValue">{pa_name}</div>
      </div>
    ))
  )

  renderQLRanks = (ranks) => (
    ranks?.map(({ ql_name }, index) => (
      <div className="field">
        <div className="fieldName">{`Rank ${index + 1}`}</div>
        <div className="fieldValue">{ql_name}</div>
      </div>
    ))
  )

  renderSTRanks = (ranks) => (
    ranks?.map(({ step_therapy_name }, index) => (
      <div className="field">
        <div className="fieldName">{`Rank ${index + 1}`}</div>
        <div className="fieldValue">{step_therapy_name}</div>
      </div>
    ))
  )

  renderDawSettings = (sectionFields) => {
    const { psc_settings } = sectionFields
    //PSC (DAW)
    return psc_settings?.map((dawSetting, index) => ([
      <div className="subTitle fliptSeparator">
        {`DAW Setting Level ${index}`}
        <br />
      </div>,
      <>
        <div className="field">
          <div className="fieldName">Generic Pricing</div>
          <div className="fieldValue">{dawSetting?.generic_pricing}</div>
        </div>
        <div className="field">
          <div className="fieldName">Member Choice Penalty</div>
          <div className="fieldValue">{dawSetting?.member_choice_penalty}</div>
        </div>
        <div className="field">
          <div className="fieldName">Penalty Ded Accum</div>
          <div className="fieldValue">{dawSetting?.penalty_ded_accum}</div>
        </div>
        <div className="field">
          <div className="fieldName">Penalty OOP Accum</div>
          <div className="fieldValue">{dawSetting?.penalty_oop_accum}</div>
        </div>
        <div className="field">
          <div className="fieldName">Reject</div>
          <div className="fieldValue">{dawSetting?.reject}</div>
        </div>
        <FliptSeparator />
      </>,
    ]))
  }

  renderCompounds = (sectionFields) => {
    const { compound_coverage, allow_scc8, minimum_compounds_price, maximum_compounds_price } = sectionFields

    return ([
      <div className="subTitle fliptSeparator">
        Compounds
        <br />
      </div>,
      <>
        <div className="field">
          <div className="fieldName">Compound Coverage</div>
          <div className="fieldValue">{compound_coverage?.includes("_") ? compound_coverage.replaceAll("_", " ") : compound_coverage}</div>
        </div>
        <div className="field">
          <div className="fieldName">Allow Submission Clarification Code 8</div>
          <div className="fieldValue">{allow_scc8 ? allow_scc8 : ""}</div>
        </div>
        <div className="field">
          <div className="fieldName">Minimum Compounds Price</div>
          <div className="fieldValue">{`${minimum_compounds_price ? minimum_compounds_price : 0}`}</div>
        </div>
        <div className="field">
          <div className="fieldName">Maximum Compounds Price</div>
          <div className="fieldValue">{`${maximum_compounds_price ? maximum_compounds_price : 0}`}</div>
        </div>
        <FliptSeparator />
      </>,
    ])
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevProps, this.props)) {
      const { state } = this.props
      const { formulary_copay, custom_copay } = state
      this.setState({
        formularyCopayData: this.constructCopayData(formulary_copay, 'formulary_copay'),
        customCopayData: this.constructCopayData(custom_copay, 'custom_copay'),
        grandfathering: state.grandfathering,
        custom_messaging: state.custom_messaging,
        reRender: this.state.reRender + 1
      })
    }

  }

  componentWillUnmount() {
    const { clearBenefitReviewData } = this.props.actions
    clearBenefitReviewData()
  }

  render() {
    const { state } = this.props
    const {
      plan_design_details,
      compounds,
      deductibles,
      limitations,
      pricing_mixmaster,
      transition,
      listing_hierarchy,
      daw_settings,
      utilization_management,
      application_only,
      claim_processing_config,
      plan_sponsor_cap,
    } = state
    const planDesignFieldNames = plan_design_details ? Object.keys(plan_design_details) : []
    const utilizationManagement = utilization_management ? Object.keys(utilization_management) : []
    const compoundsFieldNames = compounds ? Object.keys(compounds) : []
    const applicationOnlyFieldNames = application_only ? Object.keys(application_only) : []
    const deductiblesFieldNames = deductibles ? Object.keys(deductibles) : []
    const listingHierarchyFieldNames = listing_hierarchy ? Object.keys(listing_hierarchy) : []
    const dawSettingsFieldNames = daw_settings ? Object.keys(daw_settings) : []
    const claimProcessingConfigFieldNames = claim_processing_config ? Object.keys(claim_processing_config) : []


    const planSettingsSeparatorKeys = ['no_alternatives', 'display_member_id']
    const deductiblesSeparatorKeys = ['deductible_exempt', 'deductible_type', 'apply_deductible_after_oop_met']
    const pharmacyNetworksSeparatorKeys = ['network_edits']
    // const rraSeparatorKeys = ['rra_daysofsupply_fillcount', 'manufacturer_rra_penalty_type', 'specialty_rra_penalty_type', 'standard_rra_penalty_type']
    const rewardsPenaltiesSeparatorKeys = ['rbp_fallback_zone_pricing', 'brand_covered_upto_generic_copay_flag']
    const listingHierarchySeparatorKeys = ['um_ranking', 'programs', 'clinical_pa', 'clinical_ql', 'clinical_step_therapy']
    const claimProcessingConfigKeys = ['carry_over_accumulations_med_ded', 'carry_over_accumulations_med_oop', 'carry_over_accumulations_rx_ded', 'carry_over_accumulations_rx_oop']
    return (
      <div id="reviewBenefitPlan">
        <div className="content">
          {this.renderCustomSection('Plan Design Details', plan_design_details, planDesignFieldNames)}
          {this.renderFormSection('Utilization Management', utilization_management, utilizationManagement, [])}
          {this.renderCustomSection('Compounds', compounds, compoundsFieldNames)}
          {/* {this.renderFormSection('Accumulators', deductibles, deductiblesFieldNames, deductiblesSeparatorKeys)} */}
          {this.renderCustomMessagingSection('Custom Messaging')}
          {this.renderCopaySection('Formulary Copay')}
          {this.renderCopaySection('Custom Copay')}
          {this.renderPricingMMSection('Pricing Mixmaster', pricing_mixmaster)}
          {/* {this.renderTransitionSection('Transition', transition)} */}
          {this.renderLimitationsSection('Limitations', limitations, plan_sponsor_cap)}
          {this.renderCustomSection('Listing Hierarchy', listing_hierarchy, listingHierarchyFieldNames, listingHierarchySeparatorKeys)}
          {this.renderCustomSection('DAW Settings', daw_settings, dawSettingsFieldNames)}
          {this.renderGrandfatheringSection('Grandfathering')}
          {/* {this.renderFormSection('Application Only', application_only, applicationOnlyFieldNames, [])} */}
          {this.renderCustomSection('Claim Processing Config', claim_processing_config, claimProcessingConfigFieldNames, claimProcessingConfigKeys)}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const { benefitReview, planDesignData } = state.planManagement

  const {
    compounds,
    deductibles,
    pharmacy_networks,
    formulary_copay,
    custom_copay,
    benefit_limitation,
    pricing_mixmaster,
    listing_hierarchy,
    daw_settings,
    utilization_management,
    transition,
    application_only,
    grandfathering,
    custom_messaging,
    claim_processing_config
  } = benefitReview || {}
  return ({
    state: {
      app: state.app,
      utilization_management: utilization_management ?? [],
      plan_design_details: planDesignData ?? [],
      compounds: compounds ?? [],
      deductibles: deductibles ?? [],
      pharmacy_networks: pharmacy_networks ?? [],
      formulary_copay: formulary_copay?.copays ?? [],
      custom_copay: custom_copay?.copays ?? [],
      limitations: benefit_limitation?.limitations ?? [],
      plan_sponsor_cap: benefit_limitation?.plan_sponsor_cap ?? [],
      pricing_mixmaster: pricing_mixmaster ?? [],
      listing_hierarchy: listing_hierarchy ?? [],
      daw_settings: daw_settings ?? [],
      transition: transition ?? [],
      application_only: application_only ?? [],
      claim_processing_config: claim_processing_config ?? [],
      grandfathering: grandfathering ?? [],
      custom_messaging: custom_messaging ?? [],
    },
  })
}
const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...PlanManagementActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Review)
