import React, { Component } from 'react'
import moment from 'moment'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import './styles.scss'
import BreadCrumb from '../../../../components/breadCrumb'
import ApprovalModal from '../../../../components/approvalOptions/approvalModal'
import Config from '../../../../config'
import FliptGrid from '../../../../components/fliptGrid'
import { Creators as AppActions } from '../../../../redux/reducers/app'
import { Creators as RPMActions } from '../../../../redux/reducers/rpm'
import { Creators as PlanManagementActions } from '../../../../redux/reducers/api/planManagement'
import increaseRowHeight from '../../../../components/fliptGrid/cellClickEvents'
import { ApprovalQueueColorCodedStatusRenderer, EllipsisRpmRenderer } from '../../../../components/fliptGrid/cellRenderers'
import { filterRowData } from '../../../../utils/utilities'
import { calcGridRowHeight } from '../../../../utils/utilities'


const REJECTED_STATUS_STR = 'REJECTED'
const APPROVED_STATUS_STR = 'APPROVED'
const TEST_APPROVED_STATUS_STR = 'TEST_APPROVED'
const FOR_REVIEW_STR = 'FOR_REVIEW'
class ApprovalQueue extends Component {
  componentDidMount() {
    const { actions } = this.props

    setTimeout(() => actions.rpmLoadApprovalsQueue(), Config.COMPONENT_DATA_LOAD_TIME)
  }

  _viewUrl = (module) => {
    const viewURlData = {
      FORMULARY: '/formulary',
      FORMULARY_EXPORT_CONFIGURATION: '/formulary-export-configuration-builder',
      FORMULARY_TIER: '/formulary-tier',
      POS_REBATE: '/pos-rebate',
      PRIOR_AUTHORIZATION: '/prior-authorization',
      PROGRAM_LIST: '/program-list',
      QUANTITY_LIMIT: '/quantity-limit',
      STEP_THERAPY: '/step-therapy',
    }
    return viewURlData[module] || ''
  }
  _getRowHeight = (params) => calcGridRowHeight(params.data?.approver)

  _showApprovalModal = (submitType, data) => {
    const { actions } = this.props

    actions.setModalComponent({
      modalProperties: {
        size: 'large',
      },
      contents: this._generateModal(submitType, data),
    })
    actions.toggleModal()
  }

  _clickHandler = (data) => {
    const { actions } = this.props
    const { status } = data

    // don't want to send keys to the api that were added by 
    // client and don't belong in the document
    let requestData = { ...data }
    delete requestData.object_name
    delete requestData.object_type

    if ((status === 'PUBLISH_READY')) {
      requestData["republish"] = "0"
      actions.rpmSendForPublish(requestData)
    }

    if ([APPROVED_STATUS_STR, TEST_APPROVED_STATUS_STR, FOR_REVIEW_STR, REJECTED_STATUS_STR].includes(status)) {
      if (status === TEST_APPROVED_STATUS_STR) {
        requestData["republish"] = "0"
      }
      actions.rpmApproveRejectDocument(requestData)
    }
  }

  _calculateTimeElapsed = (data) => {
    const today = moment(new Date())
    const submittedDate = moment(data.date_submitted)

    return `${today.diff(submittedDate, 'days')} days`
  }

  _generateModal = (submitType, data) => {
    const { actions } = this.props

    return (
      <ApprovalModal
        clickHandler={this._clickHandler}
        data={data}
        submitType={submitType}
        toggleModal={actions.toggleModal}
      />
    )
  }

  render() {
    const headers = [
      'action', 'object_name', 'version', 'object_type', 'status', 'date_submitted', 'time_elapsed', 'submitter', 'doc_id'
    ]
    const headerMappings = {
      last_updated: 'date_submitted',
      created_by: 'submitter',
      id: 'doc_id',
      module: 'object_type',
      module_name: 'object_name'
    }

    const { history } = this.props
    const cellRendererParams = {
      action: {
        cellRenderer: EllipsisRpmRenderer,
        cellClass: "grid-Column",
        searchArgs: {
          showApprovalModal: this._showApprovalModal,
          viewPath: this._viewUrl,
          history,
          doc_id: 'id',
        },
        onCellClicked: increaseRowHeight,
      },
      status: {
        cellRenderer: ApprovalQueueColorCodedStatusRenderer,
      },
      doc_id: {
        hide: true,
      },
    }

    const { state: { rpm } } = this.props
    const approver = []
    if (rpm.documents !== undefined) {
      rpm.documents?.map(r => {
        if ('approvals' in r) {
          r["approver"] = r.approvals?.map(role => (role.approver))
          r["approval_group"] = r.approvals?.map(role => (role.role))
          return
        }
        return rpm.documents
      })
    }

    rpm?.documents?.forEach((doc) => {
      const firstLastName = doc?.user_data ? `${doc.user_data?.first_name} ${doc.user_data?.last_name}` : doc.last_updated_by
      doc.submitter = firstLastName
    })

    const gridData = rpm.documents.length ? rpm.documents.map((d) => ({
      ...filterRowData(d, headers, headerMappings),
      module: d.module,
      module_name: d.module_name,
    })) : []

    gridData.forEach((row) => {
      row.time_elapsed = this._calculateTimeElapsed(row)
    })

    return (
      <div id="approvalQueue">
        <BreadCrumb {...this.props} />
        <div className="header">
          <h1>Approval Queue</h1>
        </div>
        <div className="content">
          <FliptGrid
            cellRendererParams={cellRendererParams}
            data={gridData}
            headers={headers}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  state: {
    rpm: state.rpm,
  },
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions,
    ...RPMActions,
    ...PlanManagementActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ApprovalQueue)
