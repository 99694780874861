import React, { useState } from "react";
import "./styles.scss";

function FliptAccordion(props) {
  const { title, removeItem } = props;

  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };
  return (
    <section id="flipt-accordion" className="accordion-container">
      <section className="accordion-header">
        <h2 className="accordion-header-title">{title}</h2>
        <div className="accordion-actions">
          <div className="accordion-button-container">
            <button class="accordion-button maximize" onClick={toggleExpansion}>
              <svg
                className="accordion-icon maximize"
                fill="#667085"
                height="512"
                viewBox="0 0 24 24"
                width="512"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g>
                  <path d="m14 3c0 .69036.5597 1.25 1.25 1.25h2.7322l-3.9822 3.98223c-.4881.48816-.4881 1.27962 0 1.76777.4882.4882 1.2796.4882 1.7678 0l3.9822-3.98223v2.73223c0 .69035.5596 1.25 1.25 1.25s1.25-.55964 1.25-1.25v-5.75c0-.69036-.5596-1.25-1.25-1.25h-5.75c-.6903 0-1.25.55964-1.25 1.25z" />
                  <path d="m9.99999 21c0-.6904-.55965-1.25-1.25-1.25h-2.73223l3.98223-3.9822c.48811-.4882.48811-1.2796 0-1.7678-.48816-.4882-1.27962-.4882-1.76777 0l-3.98222 3.9822v-2.7322c0-.6904-.55964-1.25-1.25-1.25s-1.25.5596-1.25 1.25v5.75c0 .6904.55964 1.25 1.25 1.25h5.74999c.69035 0 1.25-.5596 1.25-1.25z" />
                </g>
              </svg>
            </button>
          </div>
          <div className="accordion-button-container">
            <button class="accordion-button trash" onClick={removeItem}>
              <svg
                className="accordion-icon trash"
                fill="#FC0303"
                id="Layer_1"
                enableBackground="new 0 0 24 24"
                height="512"
                viewBox="0 0 24 24"
                width="512"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g>
                  <path d="m21 7h-18c-.6 0-1-.4-1-1s.4-1 1-1h18c.6 0 1 .4 1 1s-.4 1-1 1z" />
                </g>
                <g>
                  <path d="m17 23h-10c-1.7 0-3-1.3-3-3v-14c0-.6.4-1 1-1s1 .4 1 1v14c0 .6.4 1 1 1h10c.6 0 1-.4 1-1v-14c0-.6.4-1 1-1s1 .4 1 1v14c0 1.7-1.3 3-3 3zm-1-16c-.6 0-1-.4-1-1v-2c0-.6-.4-1-1-1h-4c-.6 0-1 .4-1 1v2c0 .6-.4 1-1 1s-1-.4-1-1v-2c0-1.7 1.3-3 3-3h4c1.7 0 3 1.3 3 3v2c0 .6-.4 1-1 1z" />
                </g>
              </svg>
            </button>
          </div>
        </div>
      </section>
      <section
        className={`accordion-content ${isExpanded ? "expanded" : "collapsed"}`}
      >
        {props.children}
      </section>
    </section>
  );
}

export default FliptAccordion;
