import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import './styles.scss'
import BreadCrumb from '../../../../components/breadCrumb'
import Config from '../../../../config'
import FliptButton from '../../../../components/form/fliptButton'
import FliptGrid from '../../../../components/fliptGrid'
import SubmenuNav from '../shared/navigation'
import { Creators as AppActions } from '../../../../redux/reducers/app'
import { Creators as NetworkCreationActions } from '../../../../redux/reducers/api/networkCreationManagement'
import { Creators as NavigationActions } from '../../../../redux/reducers/navigation'
import { LinkRenderer, ColorCodedStatusRenderer, MultiRowRenderer } from '../../../../components/fliptGrid/cellRenderers'
import NewDrugPopupCellRenderer from '../popup/networkTierCellRenderer'
import { calcGridRowHeight, filterRowData } from '../../../../utils/utilities'
import HeaderLayout from "../../../../components/headers/headerLayout";



class PharmacyNetwork extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      actionMenuStyle: {},
      // rowData: {},
      // rowIndex: null,
      showActionsMenu: false,
      gridApi: null,
    };
  }
  componentDidMount() {
    const { actions } = this.props
    // TODO FIX THIS. SO NO MORE SETTIMEOUT
    setTimeout(() => actions.getAllNetworksData(), Config.COMPONENT_DATA_LOAD_TIME)
  }

  _getRowHeight = (params) => {
    return calcGridRowHeight(params.data?.fees)
  }
  _onGridBodyScroll = (params) => {
    const { state } = this;
    const { showActionsMenu } = state;

    if (showActionsMenu) {
      this.setState((prevState) => ({
        showActionsMenu: false,
        actionMenuStyle: {
          ...prevState.actionMenuStyle,
          top: "-9999px",
          left: "9999px",
        },
      }));
      params.api.resetRowHeights();
    }
  };

  render() {
    const { props } = this
    const { state, actions } = props
    const { allNetworksData } = state
    const headers = [
      'doc_id', 'doc_name', 'tier_name', 'doc_version', 'effective_start_date', 'effective_end_date', 'status', 'action', 'tiers',
    ]
    const cellRendererParams = {
      doc_name: {
        cellRenderer: LinkRenderer,
        overrideHeader: 'Network Name',
        path: '/generate-network',
        searchArgs: { doc_id: 'doc_id', doc_version: 'doc_version' },
        state: {
          doc_id: 'doc_id',
          doc_name: 'doc_name',
          doc_version: 'doc_version',
          editMode: false,
          effective_start_date: 'effective_start_date',
          hierarchy: 'hierarchy',
          hierarchyIDs: 'hierarchyIDs',
          is_hierarchy_global: 'is_hierarchy_global',
          tiers: 'tiers',
        },
      },
      tier_name: {
        cellRenderer: MultiRowRenderer,
        overrideHeader: 'Network Tier Name',
        width: 300,
        compact: true,
        autoHeight:true,
        cellClass: "grid-Column",
      },
      status: {
        cellRenderer: ColorCodedStatusRenderer,
      },
      action: {
        cellRenderer: NewDrugPopupCellRenderer,
        searchArgs: {
          approvalParamsMapping: { tier_id: 'id', name: 'tier_name', version: 'tier_version' },
          doc_type: 'global_pharmacy_network',
          moduleViewDrugs: 'tier',
          onApproveClick: actions.approveTier,
          onNewVersionClick: actions.createNewVersion,
          onPublishClick: actions.publishDocument,
          path: '/generate-network',
          qsArgs: { doc_id: 'doc_id' },
          state: {
            doc_id: 'doc_id',
            network_name: 'doc_name',
            doc_name: 'doc_name',
            doc_version: 'doc_version',
            version: 'doc_version',
            editMode: true,
            effective_start_date: 'start_date',
            tiers: 'tiers',
          },
          limit: state.pharmacyNetworkLookupRPG,
          screen: 'pharmacyNetwork',
        },
      },
      doc_id: {
        hide: true,
      },
      doc_version: {
        overrideHeader: 'Version',
      },
      effective_start_date: {
        overrideHeader: 'Start Date',
      },
      effective_end_date: {
        overrideHeader: 'End Date',
      },
      tiers: {
        hide: true,
      },
    }
    const gridData = allNetworksData && allNetworksData.length ? allNetworksData.map((d) => (
      { ...d, tier_name: d.tiers?.length > 0 ? d.tiers?.map((tier) => tier.doc_name) : '' }
    )) : []

    const gridOptions = {
      domLayout: "autoHeight",
      autoSizeStrategy: {
        type: "fitCellContents",
      },
    };

    return (
      <div className="networkTierCreationManagement">
        <BreadCrumb {...this.props} />

        <HeaderLayout
          heading="Pharmacy Network Management"
          nameofButton="Create New Network"
          link="generate-network"
        />

        <div className="tabsContainer">
          <SubmenuNav active="pharmacyNetwork" />
        </div>

        {/* <div className="header">
          <SubmenuNav active="pharmacyNetwork" />
        </div> */}
        {/* <div className="buttonContainer" align="right">
          <div className="header">Pharmacy Network</div>
          <Link to="/generate-network">
            <FliptButton
              className="primary searchButton"
              name="CREATE NEW NETWORK"
            />
          </Link>
        </div> */}
        <div className="agGrid content">
          <FliptGrid
            cellRendererParams={cellRendererParams}
            data={gridData}
            gridOptions={gridOptions}
            getRowHeight={this._getRowHeight}
            onBodyScroll={this._onGridBodyScroll}
            pagination={true}
            rowBuffer={gridData.length * 10}
            headers={headers}
            handleGridSize={true}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  state: {
    allNetworksData: state.networkTierCreation.allNetworksData,
    pharmacyNetworkLookupRPG: state.networkTierCreation.pharmacyNetworkLookupRPG,
  },
})

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions,
    ...NavigationActions,
    ...NetworkCreationActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PharmacyNetwork)
