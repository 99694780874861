import React, { useEffect } from "react";
import { Icon, Button, Loader } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { camelCase } from "lodash";

import "../styles.scss";
import FliptActionMenu from "../../fliptActionMenu";
import FliptActionPopup from "../../fliptActionMenu/popupAction";
import { FliptActionPopupCustom } from "../../fliptActionMenu/popupAction";
import {
  convertSnakeCaseToString,
  capitalizeStr,
} from "../../../utils/utilities";

export function LinkRenderer(props) {
  const { data, path, searchArgs, state, value } = props;
  const qs = Object.keys(searchArgs)
    .reduce((accum, key) => {
      const colName = searchArgs[key];
      const val =
        data[colName] && typeof data[colName] !== "string"
          ? data[colName].toString()
          : data[colName];
      accum += data[colName] ? `${key}=${val.trim()}&` : `${key}=${colName}&`;

      return accum;
    }, "?")
    .slice(0, -1);
  const stateData = {};
  const pathname = typeof path === "function" ? path(data.type) : path;

  if (state) {
    Object.keys(state).forEach((key) => {
      const colName = key === "editMode" ? "editMode" : state[key];
      const val = data[colName];
      stateData[key] = val || data[key] || state[key];
    });
  }

  return (
    <Link
      className="link"
      to={{
        pathname,
        search: qs,
        state: stateData,
      }}
    >
      {value}
    </Link>
  );
}

export function LinkContainerRenderer(props) {
  const { data, searchArgs } = props;
  const len = searchArgs.length;

  return (
    <div className="grid-actions">
      {searchArgs.map((item, i) => {
        const label = item?.key ? data[item.key] : item?.label || "";
        const disable = item?.disable || false;

        return (
          !disable && (
            <span>
              <Link
                className="link"
                to={{
                  pathname: item.path,
                  search: createQS(
                    item.searchArgs,
                    data,
                    item.staticSearchArgs
                  ),
                  state: createState(item.state, item.staticState, data),
                }}
              >
                {label}
              </Link>
              {i < len - 1 && <span> | </span>}
            </span>
          )
        );
      })}
    </div>
  );
}
export function MultiRowLinkRenderer(props) {
  const { value, data, searchArgs, path, state } = props;
  const qs = new URLSearchParams();
  const links = value.map((item, index) => {
    Object.keys(searchArgs).forEach((key) => {
      const colName = searchArgs[key];
      const val =
        data[colName] && typeof data[colName] !== "string"
          ? data[colName].toString()
          : data[colName];

      qs.append(
        key,
        data[colName] ? val.trim() : colName === "rowData" ? item : colName
      );
    });
    //rowData uses the value of the row in cell
    const queryString = qs.toString().replace(/%3A/g, ":");
    const stateData = {};

    if (state) {
      Object.keys(state).forEach((key) => {
        const colName = key === "editMode" ? "editMode" : state[key];
        const val = data[colName];
        stateData[key] = val || data[key] || state[key];
      });
    }

    return (
      <Link
        key={index}
        className="link"
        to={{
          pathname: path,
          search: queryString,
          state: stateData,
        }}
      >
        <div>{item}</div>
      </Link>
    );
  });

  return <div className="actions">{links}</div>;
}
export function NestedRowRenderer(props) {
  const { data, searchArgs, api } = props;
  const { key, className, parseData } = searchArgs;
  const nestedRow = parseData ? parseData(data[key], data) : data[key];

  useEffect(() => {
    const timer = setTimeout(() => {
      if (nestedRow.length > 0) api.resetRowHeights();
    }, 100);
    return () => clearTimeout(timer);
  }, [nestedRow]);

  return (
    <div className="nestedRowContainer">
      {Array.isArray(nestedRow) &&
        nestedRow?.map((item) => {
          return <div className={className || "nestedRow"}>{item}</div>;
        })}
    </div>
  );
}

export function PreviewCellRenderer(props) {
  const { data, searchArgs } = props;
  const { key, className, parseData } = searchArgs;
  const value = parseData ? parseData(data[key], data) : data[key];

  return (
    <div className={className || "nestedRow"}>
      {Array.isArray(value) ? value.join(",") : value}
    </div>
  );
}

// TODO: This render is not generic enough to accomodate any component.
export function FliptGenericDynamicLinkContainerRenderer(props) {
  const { searchArgs, value } = props;
  // const { editMode, actionto } = searchArgs;

  return (
    <div className="actions">
      {value
        ? value.map((item, i) => {
            try {
              const qs = new URLSearchParams();
              // Validate item.searchArgs before accessing it
              if (item.searchArgs) {
                Object.keys(item.searchArgs).forEach((key) => {
                  const val = item.searchArgs[key];
                  qs.append(key, val);
                });
              }
              const queryString = qs.toString().replace(/%3A/g, ":");
              const displayName = item.label || "Link Data Error";
              // const state = item.state;
              // const rootPath = ""; // Include base URL if needed
              const displayPath = `${item.path}`;

              return (
                <div key={i}>
                  <Link
                    className="link"
                    to={{
                      pathname: displayPath,
                      search: queryString,
                      state: { editMode: false },
                    }}
                  >
                    {displayName}
                  </Link>
                </div>
              );
            } catch (error) {
              console.error("Error generating link:", error); // Log any errors
              return (
                <div key={i} className="link-error">
                  {"Link Data Error"}
                </div>
              );
            }
          })
        : "NA - NO OBJECTS FOUND"}
    </div>
  );
}

// TODO: This render is not generic enough to accomodate any component.
export function DynamicLinkContainerRenderer(props) {
  const { searchArgs, value } = props;
  const { path, editMode, qsMap = {}, doc_name } = searchArgs;
  const { displayKey, qsKey } = qsMap;

  return (
    <div className="actions">
      {value.map((item, i) => {
        const [k, v] = Object.entries(item);
        let displayName = k[1];
        let qs = v[1] ? `?${v[0]}=${v[1]}` : "";
        if (displayKey && qsKey) {
          qs = item[displayKey] ? `?${qsKey}=${item[qsKey]}` : "";
          displayName = item[displayKey];
        }
        return (
          <div>
            <Link
              key={i}
              className="link"
              to={{
                pathname: path,
                search: qs,
                state: {
                  editMode: editMode || false,
                  doc_name: doc_name && item[doc_name] ? item[doc_name] : "",
                },
              }}
            >
              {displayName || "Formulary Data Error"}
            </Link>
          </div>
        );
      })}
    </div>
  );
}

export function DynamicLinkVersionContainerRenderer(props) {
  const { searchArgs, value } = props;
  const { path, editMode, qsMap = {}, doc_name } = searchArgs;
  const { displayKey, qsKey, qsVer } = qsMap;

  return (
    <div className="actions">
      {value.map((item, i) => {
        const [k, v] = Object.entries(item);
        let displayName = k[1];
        let qs = v[1] ? `?${v[0]}=${v[1]}` : "";

        if (displayKey && qsKey) {
          qs = item[displayKey] ? `?${qsKey}=${item[qsKey]}` : "";
          displayName = item[displayKey];
        }

        return (
          <div>
            <Link
              key={i}
              className="link"
              to={{
                pathname: path,
                search: qs,
                state: {
                  editMode: editMode || false,
                  doc_name: doc_name && item[doc_name] ? item[doc_name] : "",
                },
              }}
            >
              {displayName || "Formulary Data Error"}
            </Link>
          </div>
        );
      })}
    </div>
  );
}

export function GridRowTextRenderer(props) {
  const { value } = props;
  let data = [];
  let req_value = value;

  if (value == "" || !value) {
    req_value = [];
  }

  req_value.forEach((v) => {
    data = [];

    for (const [key, value] of Object.entries(v)) {
      data.push(
        React.createElement(
          "div",
          { className: "gridRowTextCell" },
          `${capitalizeStr(convertSnakeCaseToString(key))}: ${value}`
        )
      );
    }
  });

  return <div style={{ paddingBottom: "20px" }}> {data}</div>;
}

export function FeeGridRowTextRenderer(props) {
  const { value } = props;
  let data = [];
  let req_value = value;

  if (req_value.length > 0) {
    req_value.forEach((v) => {
      let str = `${convertSnakeCaseToString(v.fee_name)}: ${
        v.fee_type === "FIXED" ? "$" : ""
      }${v.fee_value}${v.fee_type === "VARIABLE" ? "%" : ""}`;
      data.push(
        React.createElement("div", { className: "gridRowTextCell" }, `${str}`)
      );
    });
  } else {
    data.push(
      React.createElement("div", { className: "gridRowTextCell " }, ``)
    );
  }
  return <div style={{ padding: "5px" }}> {data}</div>;
}

export function DiscountRowTextRenderer(props) {
  const { data, value } = props;

  let discountdata = "";
  let discount = value || "";

  if (discount === 0) {
    discount = "";
  }
  discountdata = discount;

  if (discountdata != "" && discountdata != 0) {
    if (data.rate_type === "NEGATIVE") {
      if (discountdata > 0) {
        discountdata = `${-1 * discountdata}`;
      }
    }
    if (data.discount_type === "FIXED") {
      discountdata = `$${discountdata}`;
    } else if (data.discount_type === "VARIABLE") {
      discountdata = `${discountdata}%`;
    }
  }

  return discountdata;
}

export function MultiRowRenderer(props) {
  const { value, compact = false } = props;
  let parsedValue = Array.isArray(value)
    ? value
    : value
    ? Object.values(value)
    : [];

  return (
    <div className="actions">
      {parsedValue?.length ? (
        parsedValue.map((item) => {
          if (!compact) {
            return <div>{item}</div>;
          } else {
            return <div className="gridRowTextCell">{item}</div>;
          }
        })
      ) : (
        <div className="gridRowTextCell"></div>
      )}
      <br />
    </div>
  );
}

export function ButtonRenderer(props) {
  const {
    data,
    rowIndex,
    searchArgs: { className, inputProps = {}, key, onClick, submitType, text },
  } = props;

  const _onClick = () => {
    onClick(data, submitType, rowIndex);
  };

  return (
    <div className="actions">
      <Button
        size="tiny"
        className={className || ""}
        onClick={_onClick}
        {...inputProps}
      >
        {key ? data[key] : text}
      </Button>
    </div>
  );
}

export function EllipsisRenderer(props) {
  const { node, api, rowIndex, data, searchArgs } = props;
  const { onEllipsisClick } = searchArgs;
  const _onClick = (e) => {
    onEllipsisClick(e, node, api, rowIndex, data);
  };

  return (
    <div className="actions">
      <Icon link name="ellipsis horizontal" onClick={_onClick} />
    </div>
  );
}

/* TODO: Make more generic. Clean up */
export function EllipsisRpmRenderer(props) {
  const { data, searchArgs, state } = props;
  const { history, showApprovalModal, viewPath } = searchArgs;
  const settings = {
    view: {
      searchArgs: {
        onClick: () => {
          history.push({
            pathname: viewPath(data.module),
            search: `?doc_id=${data.doc_id}&editMode=false`,
            state: {
              editMode: false,
              doc_id: data.doc_id,
              status: data.status,
            },
          });
        },
        text: "View Document",
      },
    },
    approveForTesting: {
      data,
      searchArgs: {
        className: "approveButton",
        onClick: (d, submitType) => showApprovalModal(submitType, d),
        text: "Approve For Testing",
        submitType: "TEST_APPROVED",
        inputProps: {
          disabled: [
            "APPROVED",
            "TEST_READY",
            "PUBLISH_READY",
            "REJECTED",
            "TEST_APPROVED",
          ].includes(data.status),
        },
      },
    },
    approve: {
      data,
      searchArgs: {
        className: "approveButton",
        onClick: (d, submitType) => showApprovalModal(submitType, d),
        text: "Approve",
        submitType: "APPROVED",
        inputProps: {
          disabled: ["APPROVED", "PUBLISH_READY", "TEST_APPROVED"].includes(
            data.status
          ),
        },
      },
    },
    reject: {
      data,
      searchArgs: {
        className: "rejectButton",
        onClick: (d, submitType) => showApprovalModal(submitType, d),
        text: "Reject",
        submitType: "REJECTED",
        inputProps: {
          disabled: [
            "APPROVED",
            "PUBLISH_READY",
            "TEST_APPROVED",
            "PUBLISHED",
          ].includes(data.status),
        },
      },
    },
    publish: {
      data,
      searchArgs: {
        className: "publishButton",
        onClick: (d, submitType) => showApprovalModal(submitType, d),
        text: "Publish",
        submitType: "PUBLISH_READY",
        inputProps: {
          disabled: !["APPROVED", "TEST_APPROVED"].includes(data.status),
        },
      },
    },
  };

  return (
    <div className="actions">
      <Icon link name="ellipsis horizontal" />
      {Object.keys(settings).map((type) => (
        <div className="btnRe">{ButtonRenderer(settings[type])}</div>
      ))}
    </div>
  );
}

/* TODO: Make more generic. Clean up */
export function EllipsisActionMenuRenderer(props) {
  const { data, searchArgs } = props;
  const {
    path,
    pathDrugs,
    pathHierarchy,
    moduleViewDrugs,
    onApproveClick,
    qsArgs,
    approvalParamsMapping,
    onClick,
  } = searchArgs;

  return (
    <div className="actions">
      <Icon link name="ellipsis horizontal" />
      <FliptActionMenu
        approvalParamsMapping={approvalParamsMapping}
        data={data}
        menuClassName="ellipsisActionMenu"
        moduleViewDrugs={moduleViewDrugs}
        onApproveClick={onApproveClick}
        onClick={onClick}
        pathDrugs={pathDrugs}
        pathHierarchy={pathHierarchy}
        pathname={path}
        qsArgs={qsArgs}
        showActionsMenu
        // approvalData={createApprovalData(data, approvalParamsMapping)}
        // qs={createQS(qsArgs, data)}
      />
    </div>
  );
}

export function ActionPopupMenuRendererCustom(props) {
  const { data, searchArgs } = props;
  const { actionItems, displayValue, rowDataQs, fontSize } = searchArgs;

  return (
    <FliptActionPopupCustom
      actionItems={actionItems}
      data={data}
      displayValue={displayValue}
      fontSize={fontSize}
      menuClassName="ellipsisActionMenu"
      showActionsMenu
    />
  );
}

export function ActionPopupMenuRenderer(props) {
  const { data, searchArgs } = props;
  const {
    path,
    pathDrugs,
    pathHierarchy,
    moduleViewDrugs,
    qsArgs,
    approvalParamsMapping,
    onClick,
  } = searchArgs;

  return (
    <FliptActionPopup
      approvalParamsMapping={approvalParamsMapping}
      data={data}
      menuClassName="ellipsisActionMenu"
      moduleViewDrugs={moduleViewDrugs}
      onClick={onClick}
      pathDrugs={pathDrugs}
      pathHierarchy={pathHierarchy}
      pathname={path}
      qsArgs={qsArgs}
      showActionsMenu
    />
  );
}

export function DeleteRowRenderer(props) {
  const { state, rowIndex, context, data, disabled = false, api } = props;
  const { onClick } = state;
  const { stepLevel } = context;
  const _onClick = () => {
    onClick(rowIndex, stepLevel, data, api);
  };

  return (
    <Button
      compact
      size="tiny"
      icon="trash"
      disabled={disabled}
      onClick={_onClick}
    />
  );
}

export function IconRenderer(props) {
  const {
    data: { uuid },
  } = props;

  return (
    <Link
      to={{
        pathname: "/edit-user",
        state: { uuid },
      }}
    >
      <Button icon="edit" />
    </Link>
  );
}

export function IconClickRenderer(props) {
  const {
    data,
    searchArgs,
    value,
    column: { actualWidth },
  } = props;
  let text = searchArgs?.fieldToRenderInCell
    ? data[searchArgs.fieldToRenderInCell]
    : value;
  const TEXT_LIM = actualWidth / 10;

  if (!text) return null;

  if (text.length > TEXT_LIM) text = text.substring(0, TEXT_LIM) + "...";

  return (
    <div>
      <Icon
        loading={!searchArgs}
        name={searchArgs?.iconName ?? "circle notched"}
        onClick={() => searchArgs?.iconOnClick(props)}
        size={searchArgs?.size ?? "small"}
      />
      {text}
    </div>
  );
}

export function PHILoader(props) {
  const { data, colDef } = props;
  let loading = true;

  if (colDef.field in data) {
    loading = false;
  }

  return (
    <div className="actions">
      {!!loading && <Loader active inline size="small" />}
      {!loading && data[colDef.field]}
    </div>
  );
}

export function ColorCodedStatusRenderer(props) {
  const { data, colDef } = props;
  const value = data[colDef.field];

  return <div className={`${camelCase(value)}StatusClass`}>{value}</div>;
}

export const ColorCodedStatusRendererUpdated = (props) => {
  const { data, colDef } = props;
  const value = data[colDef.field];
  let map = {
    approved: { icon: "/i/validation.png", status: "Approved" },
    default: { icon: "/i/drafts.png", status: "Draft" },
    draft: { icon: "/i/drafts.png", status: "Draft" },
    forReview: { icon: "/i/review.svg", status: "In Review" },
    publishFailed: { icon: "/i/file-failed.svg", status: "Failed" },
    publishInProgress: { icon: "/i/load.svg", status: "Publishing" },
    publishMarkedForRetry: { icon: "/i/queue.svg", status: "Waiting To Retry" },
    publishReady: { icon: "/i/queue.svg", status: "In Queue" },
    published: { icon: "/i/check-circle.svg", status: "Published" },
    rejected: { icon: "/i/file-failed.svg", status: "Rejected" },
  };

  return (
    <div className={`status_value ${camelCase(value)}`}>
      <img src={map[camelCase(value)]?.icon || ""} />
      <p>{map[camelCase(value)]?.status || value}</p>
    </div>
  );
};

export const ApprovalQueueColorCodedStatusRenderer = (props) => {
  const { data, colDef } = props;
  const value = data[colDef.field];
  let map = {
    APPROVED: {
      icon: "/i/validation.png",
      status: "Approved",
      styleStatus: "approved",
    },
    DEFAULT: { icon: "/i/drafts.png", status: "Draft", styleStatus: "draft" },
    DRAFT: { icon: "/i/drafts.png", status: "Draft", styleStatus: "draft" },
    FOR_REVIEW: {
      icon: "/i/review.svg",
      status: "In Review",
      styleStatus: "forReview",
    },
    PUBLISHED: {
      icon: "/i/check-circle.svg",
      status: "Published",
      styleStatus: "published",
    },
    PUBLISH_FAILED: {
      icon: "/i/file-failed.svg",
      status: "Failed",
      styleStatus: "publishFailed",
    },
    PUBLISH_IN_PROGRESS: {
      icon: "/i/load.svg",
      status: "Publishing",
      styleStatus: "publishInProgress",
    },
    PUBLISH_MARKED_FOR_RETRY: {
      icon: "/i/queue.svg",
      status: "Waiting To Retry",
      styleStatus: "publishMarkedForRetry",
    },
    PUBLISH_READY: {
      icon: "/i/queue.svg",
      status: "In Queue",
      styleStatus: "publishReady",
    },
    REJECTED: {
      icon: "/i/file-failed.svg",
      status: "Rejected",
      styleStatus: "rejected",
    },
    TEST_APPROVED: {
      icon: "/i/review.svg",
      status: "Test Approved",
      styleStatus: "testApproved",
    },
    TEST_READY: {
      icon: "/i/review.svg",
      status: "Test Ready",
      styleStatus: "testReady",
    },
  };

  return (
    <div className={`status_value ${map[value]?.styleStatus || ""}`}>
      <img src={map[value]?.icon || ""} />
      <p>{map[value]?.status || value}</p>
    </div>
  );
};

function createQS(searchArgs, data, staticSearchArgs) {
  let staticQS = "";
  let defaultAccumforDynamicQS = "?";

  if (staticSearchArgs) {
    staticQS = Object.keys(staticSearchArgs)
      .reduce((accum, key) => {
        const val =
          staticSearchArgs[key] && typeof data[key] !== "string"
            ? staticSearchArgs[key].toString()
            : staticSearchArgs[key];

        accum += staticSearchArgs[key] ? `${key}=${val.trim()}&` : "";

        return accum;
      }, "?")
      .slice(0, -1);
    defaultAccumforDynamicQS = "";
  }
  const dynamicQS = Object.keys(searchArgs)
    .reduce((accum, key) => {
      const colName = searchArgs[key];
      const val =
        data[colName] && typeof data[colName] !== "string"
          ? data[colName].toString()
          : data[colName];

      accum += data[colName] ? `${key}=${val.trim()}&` : "";

      return accum;
    }, defaultAccumforDynamicQS)
    .slice(0, -1);

  return staticQS ? `${staticQS}&${dynamicQS}` : dynamicQS;
}

function createState(state, staticState, data) {
  let stateData = {};

  if (staticState) {
    stateData = Object.keys(staticState).reduce((accum, key) => {
      const val =
        staticState[key] && typeof staticState[key] !== "string"
          ? staticState[key].toString()
          : staticState[key];
      accum[key] = val ? val.trim() : "";
      return accum;
    }, {});
  }
  if (state) {
    stateData = Object.keys(state).reduce((accum, key) => {
      const colName = state[key];
      const val = data[colName];
      // data[colName] && typeof data[colName] !== "string"
      //   ? data[colName].toString()
      //   : data[colName];
      accum[key] = val; // ? val.trim() : "";
      return accum;
    }, stateData || {});
  }
  return stateData;
}

export function CheckboxRenderer(props) {
  const {
    state,
    column: { colId },
    rowIndex,
    data,
  } = props;
  const { onCheckboxChange } = state;
  const _onCheckboxChange = (params) => {
    onCheckboxChange(params, colId, rowIndex);
  };

  return (
    <div className="checkbox">
      <input
        type="checkbox"
        defaultChecked={data[colId] ?? false}
        onChange={_onCheckboxChange}
      />
    </div>
  );
}

export function LevelCheckboxRenderer(props) {
  const {
    state,
    column: { colId },
    context,
    rowIndex,
    data,
  } = props;
  const { onCheckboxChange } = state;
  const { stepLevel } = context;
  const _onCheckboxChange = (params) => {
    onCheckboxChange(params, colId, stepLevel, rowIndex);
  };

  return (
    <div className="checkbox">
      <input
        type="checkbox"
        defaultChecked={data[colId] ?? false}
        onChange={_onCheckboxChange}
      />
    </div>
  );
}

export function CheckboxUIRenderer(props) {
  const {
    data,
    value,
    rowIndex,
    api,
    searchArgs: { getValue, onClick },
  } = props;
  let checked =
    typeof value === "boolean" ? value : (getValue && getValue(value)) || false;

  return (
    <div className="checkbox">
      <input
        type="checkbox"
        onClick={(e) => onClick && onClick(e, rowIndex, api)}
        checked={checked}
      />
    </div>
  );
}

export function DropdownRenderer(props) {
  const {
    value,
    context: { rowCellInfo },
    column: { colId },
  } = props;
  let name = value;

  if (!!rowCellInfo) {
    if (typeof value === "string")
      name =
        rowCellInfo[colId]?.options?.find((x) => x.value === value)?.text ||
        value;
    else {
      name = value
        .map(
          (v) =>
            rowCellInfo[colId]?.options?.find((x) => x.value === v)?.text || v
        )
        .join(", ");
    }
  }

  return (
    <div className="dropdownRenderer">
      <p>{name}</p>
    </div>
  );
}

// function createApprovalData(data, approvalParamsMapping) {
//   const approvalData = {}
//   Object.keys(approvalParamsMapping).forEach((param) => {
//     approvalData[approvalParamsMapping[param]] = data[param]
//   })
//   return approvalData
// }

export function MultiValueContainerRenderer(props) {
  const { Component, value } = props;
  return (
    <div className="actions">
      <Component {...props} />
      {value.length > 0 &&
        value.map((col) => (
          <div>
            <p>{col}</p>
            <br />
          </div>
        ))}
    </div>
  );
}

export function ActionPopupRenderer(props) {
  const { data, searchArgs } = props;
  const { links } = searchArgs;
  const [visible, setVisible] = useState(false);
  const getLink = ({ type, ...other }, index) => {
    return type !== "button" ? (
      <Link to={other.to}>
        <div role="link" tabIndex={index * -1} className="menu-item">
          {other.label}
        </div>
      </Link>
    ) : (
      <div
        role="button"
        disabled={docStatus}
        tabIndex={0}
        onClick={() =>
          !docStatus && onClickHandler({ open: false, ...data }, 0)
        }
        className={`menu-item`}
      >
        {other.label}
      </div>
    );
  };
  const popupStyle = {
    backgroundColor: "transparent",
    border: 0,
    boxShadow: "none",
    position: "inherit",
  };
  return (
    <FliptPopup
      basic
      eventsEnabled
      onClose={hide}
      onOpen={show}
      open={visible}
      style={popupStyle}
      trigger={
        <button
          type="button"
          className="btn btn-primary"
          onClick={visible ? setVisible(false) : setVisible(true)}
        >
          {" "}
          ...{" "}
        </button>
      }
    >
      <div className="menu-container">
        {links.map((link) => {
          getLink(link);
        })}
      </div>
    </FliptPopup>
  );
}
