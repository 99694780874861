import { call, put, select, takeLatest, take } from "redux-saga/effects";
import { Creators as NavigationActions } from "../../reducers/navigation";
import {
  Types,
  Creators as durActions,
} from "../../reducers/api/drugUtilizationReview";
import { Creators as AppActions, Types as AppTypes } from "../../reducers/app";

import { getAppState, getApiPath, isMenuEmpty } from "../../reducers/selectors";
import { fetchGet, fetchPost } from "../../../utils/fetchApi";

export default [
  addGlobalDurWatcher,
  createNewDurVersionWatcher,
  createNewGlobalDurWatcher,
  editGlobalDurWatcher,
  getClassOptionWatcher,
  getDurDataWatcher,
  getDurSummaryDataWatcher,
  getGlobalDurWatcher,
  getProgramInfoWatcher,
  publishDurWatcher,
  publishGlobalDurWatcher,
  putDurConfigWatcher,
];

function* getDurSummaryDataWatcher() {
  yield takeLatest(Types.GET_DUR_SUMMARY_DATA, getSummaryDataHandler);
}

function* getDurDataWatcher() {
  yield takeLatest(Types.GET_DUR_DATA, getDataHandler);
}

function* putDurConfigWatcher() {
  yield takeLatest(Types.PUT_DUR_DATA, putDurConfigHandler);
}
function* getProgramInfoWatcher() {
  yield takeLatest(Types.GET_PROGRAM_INFO, getProgramInfoHandler);
}

function* getGlobalDurWatcher() {
  yield takeLatest(Types.GET_GLOBAL_DUR, getGlobalDurHandler);
}

function* addGlobalDurWatcher() {
  yield takeLatest(Types.ADD_GLOBAL_DUR, addGlobalDurHandler);
}

function* getClassOptionWatcher() {
  yield takeLatest(Types.GET_CLASS_OPTION, getClassOptionHandler);
}

function* publishDurWatcher() {
  yield takeLatest(Types.PUBLISH_DUR_DOC, publishDocHandler);
}

function* createNewDurVersionWatcher() {
  yield takeLatest(Types.CREATE_NEW_DUR_VERSION, createNewVersionHandler);
}

function* createNewGlobalDurWatcher() {
  yield takeLatest(Types.CREATE_NEW_GLOBAL_DUR, createNewGlobalDurHandler);
}

function* editGlobalDurWatcher() {
  yield takeLatest(Types.EDIT_GLOBAL_DUR, editGlobalDurHandler);
}

function* publishGlobalDurWatcher() {
  yield takeLatest(Types.PUBLISH_GLOBAL_DUR, publishGlobalDurHandler);
}

function* publishGlobalDurHandler({ payload }) {
  try {
    const empty = yield select(isMenuEmpty);
    if (empty) {
      yield take([AppTypes.SET_APP_SETTINGS]);
    }
    const { serviceUrl } = yield select(getAppState);
    const { api_path } = yield select(getApiPath, "publish-global-dur");
    const url = `${serviceUrl}${api_path}`;
    yield call(fetchPost, url, payload);
    const transitionalPortal = {
      header: "DUR Status Updated",
      copy: "New DUR Program Published Successfully",
    };
    yield put(AppActions.displayTransitionalPortal(transitionalPortal));
    yield put(durActions.getGlobalDur());
  } catch (err) {
    console.log("publishGlobalDurHandler Error >Data ", err);
    const transitionalPortal = {
      header: "Publish Global DUR Data Failed",
      copy: err,
    };
    yield put(AppActions.displayTransitionalPortal(transitionalPortal));
  }
}

function* editGlobalDurHandler({ payload }) {
  try {
    const empty = yield select(isMenuEmpty);
    if (empty) {
      yield take([AppTypes.SET_APP_SETTINGS]);
    }
    const { serviceUrl } = yield select(getAppState);
    const { api_path } = yield select(getApiPath, "edit-global-dur");
    const url = `${serviceUrl}${api_path}`;
    const { data } = yield call(fetchPost, url, payload);
    const transitionalPortal = {
      header: "DUR Updated",
      copy: "DUR Program updated Successfully",
    };
    yield put(AppActions.displayTransitionalPortal(transitionalPortal));
    yield put(
      NavigationActions.navigateTo({
        pathname: "/drug-utilization-review-summary",
      })
    );
  } catch (err) {
    console.log("editGlobalDurHandler Error >Data ", err);
    const transitionalPortal = {
      header: "Edit Global DUR Data Failed",
      copy: err,
    };
    yield put(AppActions.displayTransitionalPortal(transitionalPortal));
  }
}

function* createNewGlobalDurHandler({ payload }) {
  try {
    const empty = yield select(isMenuEmpty);
    if (empty) {
      yield take([AppTypes.SET_APP_SETTINGS]);
    }
    const { serviceUrl } = yield select(getAppState);
    const { api_path } = yield select(getApiPath, "create-dur-version");
    const url = `${serviceUrl}${api_path}`;
    yield call(fetchPost, url, payload);
    const transitionalPortal = {
      header: "DUR Added",
      copy: "New DUR Program Version Added Successfully",
    };
    yield put(AppActions.displayTransitionalPortal(transitionalPortal));
    yield put(durActions.getGlobalDur());
  } catch (err) {
    console.log("createNewGlobalDurHandler Error >Data ", err);
    const transitionalPortal = {
      header: "create New Global DUR Data Failed",
      copy: err,
    };
    yield put(AppActions.displayTransitionalPortal(transitionalPortal));
  }
}

function* getSummaryDataHandler({ payload }) {
  try {
    const empty = yield select(isMenuEmpty);
    if (empty) {
      yield take([AppTypes.SET_APP_SETTINGS]);
    }
    const { serviceUrl } = yield select(getAppState);
    const { api_path } = yield select(
      getApiPath,
      "drug-utilization-review-summary"
    );
    const url = `${serviceUrl}${api_path}`;
    const response = yield call(fetchGet, url, payload);
    yield put(durActions.setDurSummaryData({ data: response?.data }));
  } catch (err) {
    console.log("getSummaryDataHandler Error >Data ", err);
    const transitionalPortal = {
      header: "Getting DUR Summary Data Failed",
      copy: err,
    };
    yield put(AppActions.displayTransitionalPortal(transitionalPortal));
  }
}

function* getDataHandler({ payload }) {
  try {
    const empty = yield select(isMenuEmpty);
    if (empty) {
      yield take([AppTypes.SET_APP_SETTINGS]);
    }
    const { serviceUrl } = yield select(getAppState);
    const { api_path } = yield select(
      getApiPath,
      "drug-utilization-review-by-type"
    );
    const url = `${serviceUrl}${api_path}`;
    const response = yield call(fetchPost, url, payload);
    const data = response?.data?.length > 0 ? response?.data[0] : {};
    yield put(durActions.setDurData({ data }));
  } catch (err) {
    console.log("getDataHandler Error >Data ", err);
    const transitionalPortal = {
      header: "Getting DUR Data Failed",
      copy: err,
    };
    yield put(AppActions.displayTransitionalPortal(transitionalPortal));
  }
}

function* getProgramInfoHandler({ payload }) {
  try {
    const empty = yield select(isMenuEmpty);
    if (empty) {
      yield take([AppTypes.SET_APP_SETTINGS]);
    }
    const { serviceUrl } = yield select(getAppState);
    const { api_path } = yield select(getApiPath, "get-dur-program");
    const url = `${serviceUrl}${api_path}`;
    const { data } = yield call(fetchGet, url, payload);
    if (!data.length) {
      return;
    }
    yield put(durActions.setProgramData(data));
  } catch (err) {
    console.log("getProgramInfo Error >Data ", err);
    const transitionalPortal = {
      header: "Getting Program Info Failed",
      copy: err,
    };
    yield put(AppActions.displayTransitionalPortal(transitionalPortal));
  }
}

function* getGlobalDurHandler({ payload }) {
  try {
    const empty = yield select(isMenuEmpty);
    if (empty) {
      yield take([AppTypes.SET_APP_SETTINGS]);
    }
    const { serviceUrl } = yield select(getAppState);
    const { api_path } = yield select(getApiPath, "get-global-dur");
    const url = `${serviceUrl}${api_path}`;
    const { data } = yield call(fetchPost, url, payload);
    yield put(durActions.setGlobalDurData(data));
  } catch (err) {
    console.log("getGlobalDur Error >Data ", err);
    const transitionalPortal = {
      header: "Getting Global DUR Data Failed",
      copy: err,
    };
    yield put(AppActions.displayTransitionalPortal(transitionalPortal));
  }
}

function* addGlobalDurHandler({ payload }) {
  try {
    const empty = yield select(isMenuEmpty);
    if (empty) {
      yield take([AppTypes.SET_APP_SETTINGS]);
    }
    const { serviceUrl } = yield select(getAppState);
    const { api_path } = yield select(getApiPath, "add-global-dur");
    const url = `${serviceUrl}${api_path}`;
    yield call(fetchPost, url, payload);
    const transitionalPortal = {
      header: "DUR Added",
      copy: "New DUR Program Added Successfully",
    };
    yield put(AppActions.displayTransitionalPortal(transitionalPortal));
    yield put(
      NavigationActions.navigateTo({
        pathname: "/drug-utilization-review-summary",
        state: { tabName: "DUR Programs" },
      })
    );
  } catch (err) {
    console.log("addGlobalDur Error >Data ", err);
    const transitionalPortal = {
      header: "Adding Global DUR Data Failed",
      copy: err,
    };
    yield put(AppActions.displayTransitionalPortal(transitionalPortal));
  }
}

function* getClassOptionHandler({ payload }) {
  try {
    const empty = yield select(isMenuEmpty);
    if (empty) {
      yield take([AppTypes.SET_APP_SETTINGS]);
    }
    const { serviceUrl } = yield select(getAppState);
    const { api_path } = yield select(getApiPath, "get-class-options");
    const url = `${serviceUrl}${api_path}`;
    const { data } = yield call(fetchPost, url, payload);
    yield put(durActions.setClassOptionData(data));
  } catch (err) {
    console.log("getClassOption Error >Data ", err);
    const transitionalPortal = {
      header: "Getting Class Data Failed",
      copy: err,
    };
    yield put(AppActions.displayTransitionalPortal(transitionalPortal));
  }
}

function* putDurConfigHandler({ payload }) {
  try {
    const empty = yield select(isMenuEmpty);
    if (empty) {
      yield take([AppTypes.SET_APP_SETTINGS]);
    }
    const { serviceUrl } = yield select(getAppState);
    const { api_path } = yield select(
      getApiPath,
      "drug-utilization-review-add"
    );
    const url = `${serviceUrl}${api_path}`;
    const { data } = yield call(fetchPost, url, payload);
    if (data) {
      const transitionalPortal = {
        header: payload?.doc_id ? "DUR Updated" : "DUR Added",
        copy: payload?.doc_id
          ? "DUR Updated Successfully"
          : "New DUR Added Successfully",
      };
      yield put(AppActions.displayTransitionalPortal(transitionalPortal));
      if (payload.dur_type.includes("Opioid"))
        yield put(
          NavigationActions.navigateTo({
            pathname: "/opioid-management",
            state: { tabName: payload.dur_type },
          })
        );
      else
        yield put(
          NavigationActions.navigateTo({
            pathname: "/drug-utilization-review-summary",
            state: { tabName: payload.dur_type },
          })
        );
    }
    yield put(durActions.setDurData(data));
  } catch (err) {
    console.log("putDurConfigHandler Error >Data ", err);
    const transitionalPortal = {
      header: "Error occurred while saving DUR",
      copy: err,
    };
    yield put(AppActions.displayTransitionalPortal(transitionalPortal));
  }
}

function* publishDocHandler({ payload }) {
  try {
    const empty = yield select(isMenuEmpty);
    if (empty) {
      yield take([AppTypes.SET_APP_SETTINGS]);
    }
    const { serviceUrl } = yield select(getAppState);
    const { api_path } = yield select(
      getApiPath,
      "contract-management-publish-document"
    );
    const url = `${serviceUrl}${api_path}`;
    const response = yield call(fetchPost, url, payload);
    yield put(durActions.getDurSummaryData());
  } catch (err) {
    console.log("publishDocHandler Error >Data ", err);
    const transitionalPortal = {
      header: "publishDocHandler Failed",
      copy: err,
    };
    yield put(AppActions.displayTransitionalPortal(transitionalPortal));
  }
}

function* createNewVersionHandler({ payload }) {
  try {
    const empty = yield select(isMenuEmpty);
    if (empty) {
      yield take([AppTypes.SET_APP_SETTINGS]);
    }
    const { serviceUrl } = yield select(getAppState);
    const { api_path } = yield select(
      getApiPath,
      "contract-management-create-version"
    );
    const url = `${serviceUrl}${api_path}`;
    const response = yield call(fetchPost, url, payload);
    yield put(durActions.getDurSummaryData());
  } catch (err) {
    console.log("createNewVersionHandler Error >Data ", err);
    const transitionalPortal = {
      header: "Version Creation Failed",
      copy: err,
    };
    yield put(AppActions.displayTransitionalPortal(transitionalPortal));
  }
}

export {
  getSummaryDataHandler,
  getDataHandler,
  putDurConfigHandler,
  publishDocHandler,
  getProgramInfoHandler,
  getGlobalDurHandler,
  addGlobalDurHandler,
  getClassOptionHandler,
  createNewGlobalDurHandler,
  editGlobalDurHandler,
  publishGlobalDurHandler,
};
