import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { LicenseManager } from "ag-grid-enterprise";
import { BrowserRouter as Router } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { createBrowserHistory } from "history";
import "./globalStyles/index.scss";

import "./index.scss";
import * as serviceWorker from "./serviceWorker";
import App from "./App";
import Config from "./config";
import reduxStore from "./redux/store";
import ErrorBoundary from "./components/ErrorBoundary";

LicenseManager.setLicenseKey(Config.REACT_APP_AG_GRID_LICENSE_KEY);

const history = createBrowserHistory();

if (Config.ENVIRONMENT === "production" && Config.SENTRY_DSN) {
  Sentry.init({
    dsn: Config.SENTRY_DSN,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],
    environment: Config.ENVIRONMENT,
    tracesSampleRate: 1.0,
    release: Config.appVersion,
    autoSessionTracking: true,
  });
}

ReactDOM.render(
  <ErrorBoundary history={history}>
    <Provider store={reduxStore()}>
      <Router>
        <App />
      </Router>
    </Provider>
  </ErrorBoundary>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
