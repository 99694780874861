import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import _ from "lodash";

import "./styles.scss";
import BreadCrumb from "../../../../../components/breadCrumb";
import Config from "../../../../../config";
import RoleFeatures from "./roleFeatures";
import SaveTierModal from "../../../formularyCreationManagement/formularyTiers/createTier/saveTierModal";
import { Creators as AppActions } from "../../../../../redux/reducers/app";
import { Creators as NavigationActions } from "../../../../../redux/reducers/navigation";
import { Creators as RoleManagementActions } from "../../../../../redux/reducers/roleManagement";
import { parseQueryString } from "../../../../../utils/utilities";

class CreateRole extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        active: "",
        created_by: "",
        description: "",
        effective_end_date: "",
        effective_start_date: "",
        id: "",
        name: "",
        organization: "",
      },
      descriptions: {},
      editMode: true,
      features: [],
      functionHierarchy: [],
      functions: [],
      rowUpdated: null,
    };
  }

  componentDidMount() {
    const {
      actions,
      history: { location },
    } = this.props;

    actions.clearRoleManagementRoleData();
    const functionHierarchy = this.createFunctionHierarchy();
    if (!location?.search) {
      this.state.functionHierarchy = [...functionHierarchy];
      return;
    }

    const { id } = parseQueryString(location.search);
    const s = {
      editMode: id && !!location.state?.editMode,
    };

    this.setState(s);

    setTimeout(
      () => actions.getRoleManagementRole(parseQueryString(location.search)),
      Config.COMPONENT_DATA_LOAD_TIME
    );
  }

  componentDidUpdate(prevProps) {
    const { props } = this;
    const prevState = prevProps.state;
    const currState = props.state;

    if (_.isEqual(prevState, currState)) return;
    this._updateState(currState);
  }

  createFunctionHierarchy = () => {
    //TODO - do something about when/how many times this is being made and just update a structure instead of re-creating every time (probably only build if state changes or something right?)
    const {
      state: { functionHierarchy },
    } = this.props;
    const {
      state: { functions, descriptions },
    } = this;
    if (functionHierarchy.length < 1) return [];

    return functionHierarchy
      .map((application) =>
        application.modules
          .map((module) =>
            module.screens
              .map((screen) =>
                screen.subsections
                  .map((subsection) =>
                    subsection.features
                      .map((feature) =>
                        feature.functions
                          .map((f) => ({
                            application: application.name,
                            application_id: application.id,
                            module: module.name,
                            module_id: module.id,
                            screen: screen.name,
                            screen_id: screen.id,
                            subsection: subsection.name,
                            feature: feature.name,
                            function: f.id,
                            read: {
                              available: f.options.includes("r"),
                              value: !!functions
                                .find(
                                  (d) =>
                                    d.key === f.id &&
                                    d.application === application.name
                                )
                                ?.permissions.includes("r"),
                            },
                            write: {
                              available: f.options.includes("w"),
                              value: !!functions
                                .find(
                                  (d) =>
                                    d.key === f.id &&
                                    d.application === application.name
                                )
                                ?.permissions.includes("w"),
                            },
                            approve: {
                              available: f.options.includes("a"),
                              value: !!functions
                                .find(
                                  (d) =>
                                    d.key === f.id &&
                                    d.application === application.name
                                )
                                ?.permissions.includes("a"),
                            },
                            description: f.description || "",
                          }))
                          .flat()
                      )
                      .flat()
                  )
                  .flat()
              )
              .flat()
          )
          .flat()
      )
      .flat();
  };

  _updateFields = (el, dateData) => {
    const { name, value } = dateData || el.currentTarget;

    this.setState((prevState) => ({
      ...prevState,
      form: {
        ...prevState.form,
        [name]: value,
      },
    }));
  };

  _handleCheckboxChange = (
    el,
    function_name,
    permission,
    application,
    params
  ) => {
    const { state } = this;
    const functions = [...state.functions];
    const { checked } = el.currentTarget;
    const rowToUpdate = functions.find(
      (f) => f.key === function_name && f.application === application
    );

    if (checked) {
      let permsToAdd = [];
      if (permission === "read") permsToAdd.push("r");
      if (permission === "write") permsToAdd.push("r", "w");
      if (permission === "approve") permsToAdd.push("r", "w", "a");
      rowToUpdate.permissions = permsToAdd;
    } else {
      let permsToRemove = rowToUpdate.permissions;
      if (permission === "read") permsToRemove = [];
      if (permission === "write") permsToRemove = ["r"];
      if (permission === "approve") permsToRemove = ["r", "w"];
      rowToUpdate.permissions = permsToRemove;
    }
    this.setState({
      functions,
      rowUpdated: function_name,
      columnUpdate: permission,
    });
  };

  _handleSelectionChange = (enableList, disableList, ignoreCount) => {
    const { state } = this;
    let functions = [...state.functions];

    functions = functions.map((f) => {
      let rowToEnable;
      let rowToDisable;
      if (f.count > 1) {
        const duplicateEnableRows = enableList
          .filter(
            (r) => r.function === f.key && r.application === f.application
          )
          .sort((a, b) => (a.write.available ? -1 : 1));
        rowToEnable = duplicateEnableRows[0];
        const duplicateDisableRows = disableList
          .filter(
            (r) => r.function === f.key && r.application === f.application
          )
          .sort((a, b) => (a.write.available ? -1 : 1));
        rowToDisable = duplicateDisableRows[0];
      } else {
        rowToEnable = enableList.find(
          (r) => r.function === f.key && r.application === f.application
        );
        rowToDisable = disableList.find(
          (r) => r.function === f.key && r.application === f.application
        );
      }
      if (!!rowToEnable) {
        let perms = [];
        if (!!rowToEnable.read.available) perms.push("r");
        if (!!rowToEnable.write.available || f.permissions.length > 1)
          perms.push("w");
        if (!!rowToEnable.approve.available || f.permissions.length > 2)
          perms.push("a");
        return {
          ...f,
          permissions: perms,
        };
      } else if (!!rowToDisable && (f.count < 2 || ignoreCount)) {
        return {
          ...f,
          permissions: [],
        };
      }
      return f;
    });

    this.setState({ functions });
  };

  _goBack = () => {
    const {
      props: { history },
    } = this;
    history.goBack();
  };

  // _submitForm = () => {
  //   const { props, state } = this
  //   const { form, functions, features } = state
  //   const payload = {
  //     ...form,
  //     functions,
  //     features,
  //   }
  //   if (state.editMode && form.id) {
  //     props.actions.editRoleManagementRole(payload)
  //   } else {
  //     props.actions.createRoleManagementRole(payload)
  //   }
  // }

  _saveRoleForm = () => {
    const { props, state } = this;
    const { location } = props.history;
    const { form, functions, features } = state;
    const newRole = !!location.state.createRole && !form.id;
    const headerText = newRole
      ? `You are about to create the Role ${form.name}. Do you wish to continue?`
      : "You are about to save the Role. Do you wish to save?";
    let formData = { ...form };
    if (newRole) {
      formData = {
        ...form,
        functions,
        features,
      };
    }
    props.actions.setModalComponent({
      modalProperies: {
        size: "tiny",
      },
      contents: SaveTierModal({
        conditions: functions,
        form: formData,
        headerText,
        onSaveClick: newRole
          ? props.actions.createRoleManagementRole
          : props.actions.editRoleManagementRole,
        toggleModal: props.actions.toggleModal,
      }),
    });
    props.actions.toggleModal();
  };

  _updateState(currState) {
    const { form } = this.state;
    const formData = Object.keys(form).reduce((acc, curr) => {
      acc[curr] = currState.roleData[curr];

      return acc;
    }, {});
    const { functions } = currState.roleData;
    let updatedFunctions = [];
    if (!!functions) {
      updatedFunctions = this.state.functions.map((f) => {
        const existingFunction = functions.find(
          (r) => r.application === f.application && r.id === f.key
        );
        return {
          ...f,
          permissions: !!existingFunction
            ? existingFunction.permissions
            : f.permissions,
        };
      });
    }

    this.setState((prevState) => ({
      ...prevState,
      form: formData,
      functions: !!updatedFunctions.length
        ? updatedFunctions
        : prevState.functions,
    }));
  }

  render() {
    const {
      props,
      props: {
        history: { location },
      },
      state,
    } = this;
    const { form, editMode, features, descriptions } = state;
    const { createRole } = location.state;
    const functionHierarchy = this.createFunctionHierarchy(); // TODO - move all of this to on a component update or something instead of every render since that takes a while to run
    let functions = this.state.functions;
    if (functions.length == 0 && functionHierarchy.length > 0) {
      functions = [];
      functionHierarchy.forEach((f) => {
        const existingFunction = functions.find(
          (r) => r.application === f.application && r.key === f.function
        );
        if (existingFunction) {
          existingFunction.count = existingFunction.count + 1;
        } else {
          functions.push({
            application: f.application_id,
            module: f.module_id,
            screen: f.screen_id,
            key: f.function,
            permissions: [],
            count: 1,
          });
        }
      });
      this.state.functions = functions;
    }

    let headerText = editMode ? "Create Role" : "View Role";
    const {
      location: { search },
    } = props.history;

    if (search && editMode) headerText = "Edit Role";

    return (
      <div id="createRole">
        <BreadCrumb {...this.props} />
        <div className="header">{headerText}</div>
        <div className="content">
          <RoleFeatures
            // submitForm={this._submitForm}
            createRole={createRole}
            descriptions={descriptions}
            editMode={editMode}
            features={features}
            form={form}
            functionHierarchy={functionHierarchy}
            goBack={this._goBack}
            handleCheckboxChange={this._handleCheckboxChange}
            handleSelectionChange={this._handleSelectionChange}
            saveForm={this._saveRoleForm}
            updateFields={this._updateFields}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  state: {
    roleData: state.roleManagement.roleData,
    functionHierarchy: state.app.roleFunctionHierarchy,
  },
});

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions,
    ...NavigationActions,
    ...RoleManagementActions,
  };

  return {
    actions: bindActionCreators(allActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateRole);
