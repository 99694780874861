import React, { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as _ from "lodash";
import Config from "../../../config";
import FliptGrid from "../../../components/fliptGrid";
import BreadCrumb from "../../../components/breadCrumb";
import {
  LinkContainerRenderer,
  ColorCodedStatusRenderer,
} from "../../../components/fliptGrid/cellRenderers";
import FliptButton from "../../../components/form/fliptButton";
import PopupCellRenderer from "./popup/popupCellRenderer";
import { Link } from "react-router-dom";

import { Creators as OpioidManagementActions } from "../../../redux/reducers/api/opioidManagement";
import { Creators as DrugUtilizationReviewActions } from "../../../redux/reducers/api/drugUtilizationReview";

import "./styles.scss";
import TabBar from "../../../components/tabBar";
import {
  OPIOID_LOCAL_STORAGE_KEY,
  opioidTabConfigs,
  mmeHeaders,
  opioidHeaders,
} from "./tabData";

const OpioidSummary = (props) => {
  const { state, actions } = props;
  const { opioidSummaryData, durSummaryData } = state;
  const [selectedTab, setSelectedTab] = useState(opioidTabConfigs[0].id);
  const [data, setData] = useState([]);
  const [mmeData, setMmeData] = useState([]);

  const mmeCellRendererParams = {
    action: {
      cellRenderer: PopupCellRenderer,
      searchArgs: {
        onClick: (action, rowdata) => checkActionType(action, rowdata),
      },
      width: 100,
    },
    program_name: {
      cellRenderer: LinkContainerRenderer,
      searchArgs: [
        {
          path: "/add-opioid-dur",
          searchArgs: {
            doc_id: "doc_id",
            doc_name: "doc_name",
            doc_version: "doc_version",
          },
          state: { doc_id: "doc_id" },
          key: "doc_name",
          staticState: { selectedTab, viewOnly: true },
          staticSearchArgs: {},
        },
      ],
      overrideHeader: "Opioid Program Name",
      width: 350,
    },
    doc_name: {
      cellRenderer: LinkContainerRenderer,
      searchArgs: [
        {
          path: "/add-opioid-dur",
          searchArgs: {
            doc_id: "doc_id",
            doc_name: "doc_name",
            doc_version: "doc_version",
          },
          state: { doc_id: "doc_id" },
          key: "doc_name",
          staticState: { selectedTab, viewOnly: true },
          staticSearchArgs: {},
        },
      ],
      overrideHeader: "Opioid Program Name",
      width: 350,
    },
    program_version: {
      overrideHeader: "Version",
    },
    program_status: {
      overrideHeader: "Status",
      cellRenderer: ColorCodedStatusRenderer,
    },
    program_create_date: {
      overrideHeader: "Create Date",
    },
  };

  const cellRendererParams = {
    program_action: {
      cellRenderer: PopupCellRenderer,
      searchArgs: {
        onClick: (action, rowdata) => checkActionType(action, rowdata),
        selectedTab: selectedTab,
      },
      width: 100,
      overrideHeader: "Action",
    },
    program_name: {
      cellRenderer: LinkContainerRenderer,
      overrideHeader: "DUR Program Name",
      searchArgs: [
        {
          path: "/create-opioid-management",
          searchArgs: {
            doc_id: "doc_id",
            doc_name: "doc_name",
            doc_version: "doc_version",
          },
          state: { doc_id: "doc_id" },
          key: "doc_name",
          staticState: { selectedTab, viewOnly: true },
          staticSearchArgs: {},
        },
      ],
      width: 350,
    },
    doc_name: {
      cellRenderer: LinkContainerRenderer,
      overrideHeader: "DUR Program Name",
      searchArgs: [
        {
          path: "/create-opioid-management",
          searchArgs: {
            doc_id: "doc_id",
            doc_name: "doc_name",
            doc_version: "doc_version",
          },
          state: { doc_id: "doc_id" },
          key: "doc_name",
          staticState: { selectedTab, viewOnly: true },
          staticSearchArgs: {},
        },
      ],
      width: 350,
    },
    doc_version: {
      overrideHeader: "Version",
    },
    status: {
      cellRenderer: ColorCodedStatusRenderer,
    },
  };

  useEffect(() => {
    if (opioidSummaryData?.length) {
      const parsedData = opioidSummaryData.map((row) => ({
        ...row,
        program_name: row.doc_name,
        program_version: row.doc_version,
        program_status: row.status,
        program_create_date: row.create_date,
        doc_id: row.doc_id,
      }));
      setData(parsedData);
    }
  }, [opioidSummaryData]);

  const checkActionType = (action, rowdata) => {
    if (action === "publish_doc") publishDoc(rowdata);
    if (action === "create_new_version") createNewVersion(rowdata);
  };

  const publishDoc = (form) => {
    if (selectedTab === opioidTabConfigs[0].id) {
      actions.publishGlobalOpioid({
        doc_id: form.doc_id,
        status: "Published",
        doc_version: form.program_version,
        doc_type: "global_opioids",
      });
      return;
    }
    const payload = {
      doc_id: form?.doc_id,
      doc_name: form?.doc_name,
      doc_version: form?.doc_version,
      status: "Published",
      doc_type: "drug_interaction_parameter",
    };
    actions.publishDurDoc(payload, form?.plan_id);
  };

  const createNewVersion = (form) => {
    if (selectedTab === opioidTabConfigs[0].id) {
      actions.createNewGlobalOpioid({
        doc_id: form.doc_id,
        doc_version: form.program_version,
        doc_type: "global_opioids",
      });
      return;
    }
    const payload = {
      doc_id: form?.doc_id,
      doc_name: form?.doc_name,
      doc_version: form?.doc_version,
      status: "Published",
      doc_type: "drug_interaction_parameter",
    };
    actions.createNewDurVersion(payload);
  };

  const setActiveTab = (id) => {
    if (id === opioidTabConfigs[0].id) actions.getOpioidSummary();
    else actions.getDurSummaryData();
    setSelectedTab(id);
  };

  useEffect(() => {
    if (durSummaryData?.length) {
      const filteredData = durSummaryData.filter((data) => {
        return data.dur_type === selectedTab;
      });
      setMmeData(filteredData);
    }
  }, [durSummaryData]);

  return (
    <div id="opioidSummary">
      <div className="heading">
        <BreadCrumb {...props} />
        <div className="header">
          Opioid Management
          <div className="closeButton">
            <Link
              to={{
                pathname:
                  selectedTab === opioidTabConfigs[0].id
                    ? "/create-opioid-management"
                    : "/add-opioid-dur",
                state: {
                  create: true,
                  selectedTab: selectedTab,
                  isOpioid: true,
                },
              }}
              className="link-router"
            >
              <FliptButton
                className="closeButton"
                compact
                name={
                  selectedTab === opioidTabConfigs[0].id
                    ? "Create New Opioid Program"
                    : "Create New Opioid Configuration"
                }
              />
            </Link>
          </div>
        </div>
        <div className="tab-bar">
          <TabBar
            tabs={opioidTabConfigs}
            setActiveTab={(id) => setActiveTab(id)}
            localStorageKey={OPIOID_LOCAL_STORAGE_KEY}
            draghandle
          />
        </div>
      </div>
      {selectedTab === opioidTabConfigs[0].id ? (
        <div className="content maxHeight">
          <FliptGrid
            data={data}
            headers={opioidHeaders}
            cellRendererParams={cellRendererParams}
          />
        </div>
      ) : (
        <div className="content maxHeight">
          <FliptGrid
            data={mmeData}
            headers={mmeHeaders}
            cellRendererParams={mmeCellRendererParams}
          />
        </div>
      )}
    </div>
  );
};
const mapStateToProps = (state) => ({
  state: {
    opioidSummaryData: state.opioidManagement.opioidSummaryData,
    durSummaryData: state.drugUtilizationReview.durSummaryData,
  },
});

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...OpioidManagementActions,
    ...DrugUtilizationReviewActions,
  };

  return {
    actions: bindActionCreators(allActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OpioidSummary);
