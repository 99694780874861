import React, { useState, useEffect } from "react";
import { Creators as AppActions } from "../../../../redux/reducers/app";
import { Creators as DrugUtilizationReviewActions } from "../../../../redux/reducers/api/drugUtilizationReview";
import "./styles.scss";
import FliptHierarchyDropdown from "../../../../components/form/fliptHierarchyDropdown";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import BreadCrumb from "../../../../components/breadCrumb";
import FliptInput from "../../../../components/v2/fliptInput";
import FliptButton from "../../../../components/v2/fliptButton";
import FliptDatePicker from "../../../../components/v2/fliptDatePicker";
import FliptDropdown from "../../../../components/v2/fliptDropdown";
import GeneratedDurProgram from "./generatedDurProgram";
import { convertStrToDateObj } from "../../../../utils/utilities";

const createDurProgram = (props) => {
  const {
    actions,
    state: { programOptionData, programDashboardData },
    location,
  } = props;
  const [programForm, setProgramForm] = useState({
    hierarchy: [],
    hierarchyIDs: false,
    hideHierarchy: false,
    doc_name: "",
    program_name: [],
    doc_version: "1.0",
    effective_start_date: "",
    effective_end_date: "",
    hierarchy: [],
    status: "Draft",
    is_hierarchy_global: true,
  });
  const [programOptions, setProgramOptions] = useState([]);
  const [showAcc, setShowAcc] = useState(false);
  const editMode = location?.state?.editMode;
  const viewOnly = location?.state?.viewOnly;

  useEffect(() => {
    const optionData = programOptionData.map((data) => {
      return { key: data.doc_id, text: data.doc_name, value: data.doc_id };
    });
    setProgramOptions(optionData);
  }, [programOptionData]);

  useEffect(() => {
    actions.getProgramInfo();
    if ((editMode || viewOnly) && programDashboardData?.length) {
      let index = location.state.data
        ? programDashboardData.findIndex(
            (data) => location.state.data?.program_name === data.doc_name
          )
        : location.state?.doc_id
        ? programDashboardData.findIndex(
            (data) => location.state.doc_id === data.doc_id
          )
        : -1;
      if (index >= 0) {
        const params = {
          program_name: programDashboardData[index].dur?.length
            ? programDashboardData[index].dur.map((data) => data.doc_id)
            : [],
          status: programDashboardData[index].status,
          doc_name: programDashboardData[index].doc_name,
          doc_version: programDashboardData[index].doc_version,
          effective_start_date:
            programDashboardData[index].effective_start_date,
          effective_end_date: programDashboardData[index].effective_end_date,
        };
        setProgramForm(params);
      }
    }
  }, []);

  const handleChange = (el, dropdown) => {
    const { name, value } = dropdown || el.currentTarget;
    const newProgramForm = {
      ...programForm,
      [name]: value,
    };
    setProgramForm(newProgramForm);
  };

  const saveDurProgram = () => {
    if (!programForm.program_name?.length) {
      const transitionalPortal = {
        header: "Fields are missing",
        copy: "Please Select Configuration to Proceed",
      };
      actions.displayTransitionalPortal(transitionalPortal);
      return;
    }
    const durData = [];
    programForm.program_name.forEach((id) => {
      const activeIdIndex = programOptionData.findIndex(
        (data) => data.doc_id === id
      );
      if (activeIdIndex >= 0)
        durData.push({
          doc_id: programOptionData[activeIdIndex].doc_id,
          doc_name: programOptionData[activeIdIndex].doc_name,
          dur_type: programOptionData[activeIdIndex].dur_type,
        });
    });
    const params = {
      dur: durData,
      status: programForm.status,
      doc_name: programForm.doc_name,
      doc_version: programForm.doc_version,
      effective_start_date: programForm.effective_start_date,
      effective_end_date: programForm.effective_end_date,
      hierarchy: "",
      is_hierarchy_global: programForm.is_hierarchy_global,
    };
    if (editMode) actions.editGlobalDur(params);
    else actions.addGlobalDur(params);
  };

  return (
    <div id="create-program">
      <BreadCrumb {...props} />
      <div className="header">Create DUR Program</div>
      <FliptHierarchyDropdown
        readOnly
        setForm={setProgramForm}
        form={programForm}
        showHierarchyGlobal={true}
      />
      <div className="field-container">
        <FliptInput
          label="Program Name"
          disabled={editMode || viewOnly}
          onChange={handleChange}
          name="doc_name"
          value={programForm.doc_name}
        />
        <FliptInput label="Version" value={programForm.doc_version} disabled />
        <FliptDatePicker
          label="Start Date"
          onChange={handleChange}
          disabled={viewOnly}
          name="effective_start_date"
          value={convertStrToDateObj(programForm.effective_start_date)}
        />
        <FliptDatePicker
          label="End Date"
          disabled={viewOnly}
          onChange={handleChange}
          name="effective_end_date"
          value={convertStrToDateObj(programForm.effective_end_date)}
        />
      </div>
      <div className="field-container">
        <FliptDropdown
          multiple={true}
          disabled={viewOnly}
          onChange={handleChange}
          name="program_name"
          options={programOptions}
          label="Configuration Name"
          value={programForm.program_name}
        />
      </div>
      <FliptButton
        name="Generate"
        disabled={!programForm.program_name?.length}
        onClick={() => setShowAcc(true)}
        className="primary"
      />
      {showAcc ? (
        <GeneratedDurProgram
          programName={programForm.program_name}
          programOptionData={programOptionData}
        />
      ) : null}
      <div className="grid-container"></div>
      {viewOnly ? null : (
        <FliptButton name="Save" className="primary" onClick={saveDurProgram} />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  state: {
    userHierarchy: state?.user?.userHierarchy ?? {},
    programOptionData: state.drugUtilizationReview.programOptionData,
    dur: state.drugUtilizationReview.durData,
    programDashboardData: state.drugUtilizationReview.programDashboardData,
    carriers:
      state?.user?.carriers && state?.user?.carriers?.length > 0
        ? state.user.carriers
        : [],
  },
});

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions,
    ...DrugUtilizationReviewActions,
  };

  return {
    actions: bindActionCreators(allActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(createDurProgram);
