import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import _ from 'lodash'
import { Creators as PlanManagementActions } from '../../../../redux/reducers/api/planManagement'
import './styles.scss'
import FliptSeparator from '../../../../components/form/fliptSeparator'
import { Table } from "semantic-ui-react/";
import DraggableTableRow from "./draggableList";
import moment from 'moment-timezone'


class ListingHierarchy extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      um_ranking: [],
      tiersRanking: [],
      programsRanking: [],
      clinicalPARanking: [],
      clinicalQLRanking: [],
      clinicalSTRanking: [],
      selectedList: ''
    }
  }

  componentDidMount() {
    const { state, copyFormData } = this.props
    const { umTypes, programs, clinical_pa, clinical_ql, clinical_st, tiers } = state
    const formatedUmTypes = umTypes?.length && umTypes[0].hasOwnProperty('value') ? this.formatUmType(umTypes) : umTypes
    const data = {
      um_ranking: (formatedUmTypes?.length && formatedUmTypes.map((d, i) => {
        return ({
          ...d,
          rank: d.rank || i + 1
        })
      }
      )) || [],
      tiersRanking: (tiers?.length && tiers.map((d, i) => {
        return ({
          ...d,
          um_ui_field: `${d.tier_name} (${moment(d.effective_begin_date).format('MM/DD/YYYY')} - ${d.effective_end_date ? moment(d.effective_end_date).format('MM/DD/YYYY') : ''})`,
          rank: d.rank || i + 1
        })
      }
      )) || [],
      programsRanking: (programs?.length && programs.map((d, i) => {
        return ({
          ...d,
          um_ui_field: `${d.program_name} (${moment(d.effective_begin_date).format('MM/DD/YYYY')} - ${d.effective_end_date ? moment(d.effective_end_date).format('MM/DD/YYYY') : ''})`,
          rank: d.rank || i + 1
        })
      }
      )) || [],
      clinicalPARanking: (clinical_pa?.length && clinical_pa.map((d, i) => {
        return ({
          ...d,
          um_ui_field: `${d.pa_name} (${moment(d.effective_begin_date).format('MM/DD/YYYY')} - ${d.effective_end_date ? moment(d.effective_end_date).format('MM/DD/YYYY') : ''})`,
          rank: d.rank || i + 1
        })
      }
      )) || [],
      clinicalQLRanking: (clinical_ql?.length && clinical_ql.map((d, i) => {
        return ({
          ...d,
          um_ui_field: `${d.ql_name} (${moment(d.effective_begin_date).format('MM/DD/YYYY')} - ${d.effective_end_date ? moment(d.effective_end_date).format('MM/DD/YYYY') : ''})`,
          rank: d.rank || i + 1
        })
      }
      )) || [],
      clinicalSTRanking: (clinical_st?.length && clinical_st.map((d, i) => {
        return ({
          ...d,
          um_ui_field: `${d.step_therapy_name} (${moment(d.effective_begin_date).format('MM/DD/YYYY')} - ${d.effective_end_date ? moment(d.effective_end_date).format('MM/DD/YYYY') : ''})`,
          rank: d.rank || i + 1
        })
      }
      )) || [],
    }
    copyFormData(data)
    this.setState({ ...data, selectedList: '' })
  }


  sortedAsc = (arr) => arr.sort((a, b) => {
    if (a.rank === null || a.rank === undefined) {
      return 1;
    }

    if (b.rank === null || b.rank === undefined) {
      return -1;
    }

    return a.rank < b.rank ? -1 : 1;
  });

  formatUmType = (arr) => {
    const formatedUmType = this.sortedAsc(arr.map((umData) => ({
      um_type: umData.value,
      rank: umData.rank || null
    })))
    return formatedUmType;
  }

  swap = (a, b, selectedList) => {
    const { copyFormData } = this.props
    const customState = this.state
    customState[selectedList][a] = customState[selectedList].splice(b, 1, customState[selectedList][a])[0]
    customState[selectedList].map((item, i) => item['rank'] = i + 1)
    this.setState({
      ...customState,
    })
    delete customState.selectedList
    copyFormData(customState)
  }

  _renderDraggableList = (items, key, selectedList) => {
    return (
      <Table className="table-padding">
        <Table.Body>
          {items.map((obj, i) => (
            <DraggableTableRow key={i} i={i} action={(a, b) => this.swap(a, b, selectedList)}>
              <Table.Cell active textAlign='center'>{i + 1} : {obj[key] || obj['um_ui_field']}</Table.Cell>            </DraggableTableRow>
          ))}
        </Table.Body>
      </Table>
    )
  }

  render() {
    const { um_ranking, clinicalPARanking, clinicalQLRanking, clinicalSTRanking,
      programsRanking, tiersRanking } = this.state
    return (
      <div id="listing_hierachy">
        <style>{`
          .draggable {
            cursor: move; /* fallback if grab cursor is unsupported */
            cursor: grab;
            cursor: -moz-grab;
            cursor: -webkit-grab;
          }
        `}</style>
        <div className="content">
          <div className="umType">UM Ranking</div>
          <div className="hierarchyLevel">
            {this._renderDraggableList(um_ranking, 'um_type', 'um_ranking')}
          </div>
          <FliptSeparator />
          <div className="umType">Drug Lists</div>
          <div className="hierarchyLevel">
            {this._renderDraggableList(tiersRanking, 'um_ui_field', 'tiersRanking')}
          </div>
          <FliptSeparator />
          <div className="umType">Programs</div>
          <div className="hierarchyLevel">
            {this._renderDraggableList(programsRanking, 'um_ui_field', 'programsRanking')}
          </div>
          <FliptSeparator />
          <div className="umType">Prior Authorization</div>
          <div className="hierarchyLevel">
            {this._renderDraggableList(clinicalPARanking, 'um_ui_field', 'clinicalPARanking')}
          </div>
          <FliptSeparator />
          <div className="umType">Quantity Limits</div>
          <div className="hierarchyLevel">
            {this._renderDraggableList(clinicalQLRanking, 'um_ui_field', 'clinicalQLRanking')}
          </div>
          <FliptSeparator />
          <div className="umType">Clinical Step Therapy</div>
          <div className="hierarchyLevel">
            {this._renderDraggableList(clinicalSTRanking, 'um_ui_field', 'clinicalSTRanking')}
          </div>
        </div>
      </div>
    );
  }
}


const mapStateToProps = (state, props) => {
  const { fieldDetails, modelData } = props

  const umTypeField = fieldDetails?.find(({ field, options }) => field === 'um_type' && options.length > 0)
  //umTypes = umTypeField.options.map(()=> {})
  // const umTypes = (modelData.um_ranking.length && modelData.um_ranking) || umTypeField?.options || []
  const umTypes = umTypeField.options.map((field) => ({
    ...field,
    rank: modelData?.um_ranking.find((x) => x?.value === field.display_name || x?.um_type === field.display_name)?.rank,
  }))


  const sortedAsc = (arr) => arr.sort((a, b) => {
    if (a.rank === null || a.rank === undefined) {
      return 1;
    }

    if (b.rank === null || b.rank === undefined) {
      return -1;
    }

    return a.rank < b.rank ? -1 : 1;
  });



  const umTypePlanValuesField = fieldDetails?.find(({ field, options }) => field === 'um_type_plan_values' && options.length > 0)
  const tiers = (modelData.tiers.length && modelData.tiers) || umTypePlanValuesField?.options[0]?.tiers || []
  const programs = (modelData.programs.length && modelData.programs) || umTypePlanValuesField?.options[0]?.programs || []
  const clinical_pa = (modelData.clinical_pa.length && modelData.clinical_pa) || umTypePlanValuesField?.options[0]?.clinical_pa || []
  const clinical_ql = (modelData.clinical_ql.length && modelData.clinical_ql) || umTypePlanValuesField?.options[0]?.clinical_ql || []
  const clinical_st = (modelData.clinical_step_therapy.length && modelData.clinical_step_therapy) || umTypePlanValuesField?.options[0]?.clinical_step_therapy || []
  return ({
    state: {
      app: state.app,
      umTypes: umTypes?.length && umTypes[0].hasOwnProperty('rank') ? sortedAsc(umTypes) : umTypes,
      umTypes: umTypes.filter(field => {
        if (field.display_name === "Formulary") {
          return tiers.length > 0
        }
        if (field.display_name === "Prior Authorization") {
          return clinical_pa.length > 0
        }
        if (field.display_name === "Step Therapy") {
          return clinical_st.length > 0
        }
        if (field.display_name === "Quantity Limit") {
          return clinical_ql.length > 0
        }
        if (field.display_name === "Program List") {
          return programs.length > 0
        }
      }),
      tiers: tiers?.length && tiers[0].hasOwnProperty('rank') ? sortedAsc(tiers) : tiers,
      programs: programs?.length && programs[0].hasOwnProperty('rank') ? sortedAsc(programs) : programs,
      clinical_pa: clinical_pa?.length && clinical_pa[0].hasOwnProperty('rank') ? sortedAsc(clinical_pa) : clinical_pa,
      clinical_ql: clinical_ql?.length && clinical_ql[0].hasOwnProperty('rank') ? sortedAsc(clinical_ql) : clinical_ql,
      clinical_st: clinical_st?.length && clinical_st[0].hasOwnProperty('rank') ? sortedAsc(clinical_st) : clinical_st,
      listingHierarchyData: state.planManagement.listingHierarchyData,
    },
  })
}
const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...PlanManagementActions,
  }

  return {
    actions: bindActionCreators(allActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListingHierarchy)

