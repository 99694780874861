import React, { Component, PureComponent } from "react";
import _ from "lodash";
import { bindActionCreators } from "redux";
import { v4 as uuidv4 } from 'uuid'
import { connect } from "react-redux";

import Config from "../../../../../config";
import { Creators as AppActions } from "../../../../../redux/reducers/app";
import { Creators as AutoSuggestActions } from "../../../../../redux/reducers/api/autoSuggest";
import { Creators as FormularyCreationActions } from "../../../../../redux/reducers/api/formularyCreationManagement";
import { Creators as RPMActions } from "../../../../../redux/reducers/rpm";
import "./styles.scss";
import BreadCrumb from "../../../../../components/breadCrumb";
import SaveTierModal from "./saveTierModal";
import TierConditions from "./tierConditions/index";
import TierDrugs from "./tierDrugs/index";
import drugDemographicData from "../../../../../config/constants/clinical/drugDemographic";
import drugMappingData from "../../../../../config/constants/clinical/drugMapping";
import { drugGroupValues } from "../../../../../config/constants/clinical/drugGroups";
import {
  convertDateToEndTimeObj,
  convertDateToStartTimeObj,
  parseQueryString,
} from "../../../../../utils/utilities";
import FliptButton from "../../../../../components/form/fliptButton";
import ApprovalOptions from "../../../../../components/approvalOptions";
import { deleteUnnecessaryFields } from "../../../../../utils/utilizationManagement";

class CreateFormularyTier extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      form: {
        doc_id: props.state?.formularyTierData?.doc_id || "",
        effective_start_date: "",
        enhanced_tier: "N",
        exchange_tier: "N",
        hideHierarchy: false,
        hierarchy: [],
        is_hierarchy_global: false,
        medicare_drug_list: "N",
        include_inactive_drugs: "Y",
        module: "FORMULARY_TIER",
        name: "",
        status: "",
        supplemental_tier: "N",
        version: "1.0",
        version_effective_date: "",
        target_system_formulary_tier: '',
        target_system_formulary_status: '',
      },
      isLoading: false,
      tierConditions: [],
      defaultTierCondition: {
        action: "",
        condition_id: "",
        include: true,
        medicare_type: "",
        brand_generic: "",
        ddid: "",
        desi_code: "",
        drug_class: "",
        drug_group: "",
        drug_name: "",
        drug_subclass: "",
        effective_end_date: "",
        effective_start_date: "",
        inactive_indicator: "",
        gpi: "",
        include: true,
        manufacturer: "",
        medicare_type: "",
        multi_source: "",
        ndc: "",
        otc_indicator: "",
        route_of_administration: "",
      },
      filterDrugData: [],
      dropdownValues: [
        {
          drug_group: drugGroupValues,
          drug_class: [],
          drug_subclass: [],
        },
      ],
      autoSuggestionValues: [
        {
          manufacturer: { values: [], loading: false },
          drug_name: { values: [], loading: false },
        },
      ],
      autoSuggestionMinChars: 3,
      columnUpdated: null,
      editMode: true,
      columnValue: "",
      rowUpdated: null,
      agGridRef: React.createRef(),
      conditionsGridRef: React.createRef(),
      filterConditions: [],
      cellRenderers: {
        gpi: {
          overrideHeader: "GPI",
          hide: false,
        },
        medicare_type: {
          hide: true,
          overrideHeader: "Medicare Type Code",
        },
        manufacturer: {
          hide: true,
        },
        multi_source: {
          overrideHeader: "Multi-Source Code",
          hide: false,
        },
        ndc: {
          hide: true,
          overrideHeader: "NDC",
        },
        ddid: {
          hide: true,
          overrideHeader: "DDID",
        },
        item_status_flag: {
          hide: true,
          overrideHeader: "Inactive Indicator",
        },
        brand_generic: {
          hide: true,
          overrideHeader: "Brand/Generic",
        },
        otc_indicator: {
          hide: true,
          overrideHeader: "OTC Indicator",
        },
        desi_code: {
          hide: true,
        },
        route_of_administration: {
          hide: true,
        },
        maintenance_drug_code: {
          hide: true,
          overrideHeader: "Maintenance Drug Indicator",
        },
        application_type_flag: {
          hide: true,
          overrideHeader: 'App Type',
        },
        mfg_labeler_id: {
          hide: true,
          overrideHeader: 'Labler Code',
        },
        repackaged_code: {
          hide: true,
          overrideHeader: 'Repackaging Indicator',
        },
        third_party_restriction_code: {
          hide: true,
          overrideHeader: 'TPR Code',
        },
        dosage_form_cd: {
          hide: true,
          overrideHeader: 'Dosage Form Code',
        },
        clinic_pack_code: {
          hide: true,
          overrideHeader: 'Clinic Pack',
        },
        innerpack_code: {
          hide: true,
          overrideHeader: 'Inner Pack',
        },
        unit_dose: {
          hide: true,
          overrideHeader: 'Unit Dose',
        },
        dea_class_code: {
          hide: true,
          overrideHeader: 'DEA Class',
        },
        fda_therapeutic_equivalence_code: {
          hide: true,
          overrideHeader: 'FDA Equivalence',
        },
        marketing_category: {
          hide: true,
          overrideHeader: 'Marketing Category',
        },
        storage_condition_code: {
          hide: true,
          overrideHeader: 'Storage Condition',
        },
        unit_dose_with_non_unit_dose_non_repackager_in_gpi: {
          hide: true,
          overrideHeader: 'UD With Non UD RC In GPI',
        },
        rx_with_otc_in_gpi: {
          hide: true,
          overrideHeader: 'Rx With OTC In GPI',
        },
        member_notes: {
          hide: true,
        },
        agent_notes: {
          hide: true,
        },
        internal_notes: {
          hide: true,
        },
        claim_message_code: {
          hide: true,
        },
        claim_message_type: {
          hide: true,
        },
        target_system_formulary_tier: {
          hide: true,
        },
        target_system_formulary_status: {
          hide: true
        },
      },
      isAddingRow: false,
      isEditingRow: false,
    };

    // this object will be send to ApprovalOptions as a prop
    this.docData = {
      doc_id: "",
      module_name: "",
      module: "",
      version: "",
      status: "",
    };
  }

  clearFormData() {
    this.setState({
      form: {
        doc_id: "",
        effective_start_date: "",
        module: "FORMULARY_TIER",
        name: "",
        status: "DRAFT",
        version: "1.0",
        enhanced_tier: "N",
        exchange_tier: "N",
        include_inactive_drugs: "Y",
        medicare_drug_list: "N",
        supplemental_tier: "N",
      },
    });
  }

  sanitizeString(text) {
    // Keep only alphanumeric characters and spaces, convert to uppercase
    const sanitizedText = text.replace(/[^a-zA-Z0-9 ]/g, '-').toUpperCase();

    // Replace spaces with underscores
    return sanitizedText.replace(/ /g, '-');
  }

  generateConditionId(module, name, version, level, rowNumber) {
    const moduleStr = this.sanitizeString(module)
    const nameStr = this.sanitizeString(name || '')
    let versionStr = version || '1.0'
    let levelStr = level || 1
    levelStr = levelStr.toString().padStart(5, '0')
    let rowNumberStr = rowNumber || 1
    rowNumberStr = rowNumberStr.toString().padStart(7, '0')
    return `${moduleStr}:::${nameStr}:::${versionStr}:::L${levelStr}:::${rowNumberStr}`
  }

  componentDidMount() {
    const { actions, history } = this.props;
    const { search } = history.location;
    if (!search) {
      this.clearFormData();
    } else {
      actions.getFormularyTierData(parseQueryString(search));
    }
  }

  componentWillReceiveProps(nextProps) {
    const { formularyTierData, formularyTierCondData } = nextProps.state;
    if (formularyTierData.doc_id && formularyTierData.doc_id !== this.state.form.doc_id) {
      const clonedConds = _.cloneDeep(formularyTierCondData || []);
      const newTierConditions = clonedConds.map((row, index) => {
        return {
          ...row,
          rowId: uuidv4(),
          condition_id: row.condition_id || this.generateConditionId('tier', formularyTierData?.name || this.state.form?.name, formularyTierData?.version || this.state.form?.version, 1, index + 1),
          rank: row.rank || index + 1,
        };
      });
      this.setState({
        tierConditions: newTierConditions,
        form: {
          ...this.state.form,
          doc_id: formularyTierData.doc_id,
          effective_start_date: formularyTierData.effective_start_date,
          enhanced_tier: formularyTierData.enhanced_tier,
          exchange_tier: formularyTierData.exchange_tier,
          hideHierarchy: formularyTierData.hideHierarchy,
          hierarchy: formularyTierData.hierarchy,
          is_hierarchy_global: formularyTierData.is_hierarchy_global,
          medicare_drug_list: formularyTierData.medicare_drug_list,
          name: formularyTierData.name,
          status: formularyTierData.status,
          supplemental_tier: formularyTierData.supplemental_tier,
          version: formularyTierData.version,
          version_effective_date: formularyTierData.version_effective_date,
        }
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { props } = this;
    const prevState = prevProps.state;
    const currState = props.state;

    if (_.isEqual(prevState, currState)) return;

    if (
      !this.state.isAddingRow &&
      prevState.formularyTierCondData.length !== currState.formularyTierCondData.length &&
      !!currState.formularyTierCondData.length
    ) {
      this._updateState(prevProps);
    }
    if (!_.isEqual(prevState?.formularyTierData, currState?.formularyTierData) && currState.formularyTierData?.doc_id)
      this._updateState(prevProps);
    if (prevState.autoSuggestData !== currState.autoSuggestData)
      this._updateAutoSuggestions();
    if (
      !_.isEqual(
        prevState.formularyTierDrugData,
        currState.formularyTierDrugData
      )
    )
      this._updateFilterState(prevProps);

    const { formularyTierCondData } = this.props.state;
    const { isEditingRow } = this.state;

    // Only update state if there's new condition data
    // and we're not actively editing a row
    if (
      !isEditingRow &&
      prevProps.state.formularyTierCondData !== formularyTierCondData &&
      formularyTierCondData.length
    ) {
      this.setState({
        tierConditions: [...formularyTierCondData],
      });
    }
  }

  // eslint-disable-next-line react/destructuring-assignment
  _constructDropdownOptions = () =>
    this.state.dropdownValues.map((row) => this._mapValueToOption(row));

  _updateFields = (el, dateData, componentRef) => {
    const { name, value } = dateData || el.currentTarget


    // in current behavior, FliptDatePicker/SemanticDatepicker fires onChange event on first render.
    // that trigger this function (_updateFields) and causes effective_start_date for the whole conditions grid to
    // change without any user action. below is the fix for that - we don't change effective_start_date
    // on first call/initial mount. consider this behavior before making any changes.
    if (name === 'effective_start_date' && componentRef.current) {
      componentRef.current = false
      return;
    }

    if (['effective_start_date', 'target_system_formulary_tier', 'target_system_formulary_status'].includes(name)) {
      this.setState((prevState) => {
        let updatedTierConditionLevels = prevState.tierConditions.map(
          (condition) => {
            return {
              ...condition,
              [name]: value,
            }
          }
        )

        if (name === 'effective_start_date' && typeof value !== typeof prevState.form.effective_start_date) {
          updatedTierConditionLevels = prevState.tierConditions
        }
        return {
          ...prevState,
          form: {
            ...prevState.form,
            [name]: value,
          },
          tierConditions: updatedTierConditionLevels,
        }
      })
    } else {
      this.setState((prevState) => {
        let updatedTierConditionLevels = prevState.tierConditions
        if ((name === 'name')) {
          updatedTierConditionLevels = updatedTierConditionLevels.map(
            (condition, index) => {
              return {
                ...condition,
                condition_id: this.generateConditionId('tier', value, prevState?.form?.version, 1, index + 1),
              }
            }
          )
        }
        if ((name === 'version')) {
          updatedTierConditionLevels = updatedTierConditionLevels.map(
            (condition, index) => {
              return {
                ...condition,
                condition_id: this.generateConditionId('tier', prevState?.form?.name, value, 1, index + 1),
              }
            }
          )
        }

        return {
          ...prevState,
          form: {
            ...prevState.form,
            [name]: value,
          },
          tierConditions: updatedTierConditionLevels
        }
      });
    }
  };
  setForm = (newForm) => {
    this.setState((prevState) => ({
      ...prevState,
      form: newForm,
    }));
  };

  exportAll = (content, filename) => {
    if (!content) return
    const headers = Object.keys(content[0]).sort()
    const csvString = [
      headers,
      ...content.map(row => headers.map(header => row[header]))
    ].map(e => e.join(",")).join("\n");
    let pom = document.createElement('a');
    let blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    let url = URL.createObjectURL(blob);
    pom.href = url;

    pom.setAttribute('download', filename);
    pom.click();

  };

  _exportAllDrugs = () => {

    const { props, state } = this;
    const { tierConditions, form } = state;
    const { name, version } = form;
    // need to figure out how to default include
    const { formattedTierConditions, invalidDate } =
      this.checkDateValidation(tierConditions);
    if (invalidDate) return;
    let filename = `formulary_tier_${name}_${version}_${Date.now()}.csv`
    const payload = {
      tierConditions: formattedTierConditions.filter((x) => x.include).map(dict => {
        return deleteUnnecessaryFields(dict)
      }),
      tierExclusions: formattedTierConditions.filter((x) => !x.include).map(dict => {
        return deleteUnnecessaryFields(dict)
      }),
      medicare_drug_list: form.medicare_drug_list,
      supplemental_tier: form.supplemental_tier,
      filename: filename
    };
    props.actions.getFormularyTierDrugsExportAll(payload, this.exportAll);
  }

  _handleChange = (
    el,
    dropdown,
    rowIndex,
    gridApi,
    stepLevel,
    setAutoSuggestionValues
  ) => {
    const { props, state } = this;
    const { actions } = props;
    const tierConditions = [...state.tierConditions];
    const dropdownValues = [...state.dropdownValues];
    const autoSuggestionValues = [...state.autoSuggestionValues];
    const rowToUpdate = tierConditions[rowIndex];
    const dropDownRowToUpdate = dropdownValues[rowIndex];
    const { name, value } = dropdown || el.currentTarget;

    if ((state?.form?.status || "DRAFT") === "PUBLISHED") {
      let condition_grid_version = (parseInt(state.form.version) + 1).toString() + ".0"
      rowToUpdate["condition_id"] = this.generateConditionId("tier", state.form.name, condition_grid_version, 1, rowIndex + 1)
    } else if (!rowToUpdate["condition_id"]) {
      rowToUpdate["condition_id"] = this.generateConditionId("tier", state.form.name, state.form.version, 1, rowIndex + 1)
    }
    rowToUpdate[name] = value;

    if (state.rowUpdated !== rowIndex) {
      actions.clearAutoSuggestSearchTerm()
    }

    switch (name) {
      case "gpi": {
        this._onGpiChange(value, rowToUpdate, dropDownRowToUpdate);
        break;
      }
      case "drug_group":
        this._onDrugGroupChange(rowToUpdate, dropDownRowToUpdate);
        break;
      case "drug_class":
        this._onDrugClassChange(rowToUpdate, dropDownRowToUpdate);
        break;
      case "drug_subclass":
        break;
      case "manufacturer":
        if (value.length >= state.autoSuggestionMinChars) {
          autoSuggestionValues[rowIndex].manufacturer.loading = true;
          actions.getAutoSuggestData({
            search_string: value,
            search_in: "fts_ndc_drugs",
            search_for: "mfg",
          });
          this.setAutoSuggestionValues = setAutoSuggestionValues;
        }
        break;
      case "drug_name":
        if (value.length >= state.autoSuggestionMinChars) {
          autoSuggestionValues[rowIndex].drug_name.loading = true;
          actions.getAutoSuggestData({
            search_string: value,
            search_in: "fts_ndc_drugs",
            search_for: "drug_name",
          });
          this.setAutoSuggestionValues = setAutoSuggestionValues;
        }
        break;
      default:
        break;
    }
    tierConditions[rowIndex] = rowToUpdate;
    dropdownValues[rowIndex] = dropDownRowToUpdate;
    const activeColumns = gridApi.columnModel.columnApi
      .getAllGridColumns()
      .filter((col) => {
        if (col.visible) return col.colId;
      });
    let cellRenderers = { ...state.cellRenderers };
    const getColumn = Object.keys(cellRenderers);

    activeColumns.forEach((col) => {
      if (
        getColumn.includes(col.colId) &&
        col.colId != "action" &&
        col.colId != "include"
      ) {
        cellRenderers[col.colId]["hide"] = false;
      }
    });

    this.setState({
      tierConditions,
      dropdownValues,
      rowUpdated: rowIndex,
      columnUpdated: name,
      columnValue: value,
      cellRenderers: cellRenderers,
    });

    gridApi.refreshCells()
  };

  _refreshDrugDemographicDropdowns = (rowToUpdate, dropDownRowToUpdate) => {
    dropDownRowToUpdate.drug_class = [];
    dropDownRowToUpdate.drug_group = drugGroupValues;
    dropDownRowToUpdate.drug_subclass = [];
    rowToUpdate.drug_class = "";
    rowToUpdate.drug_group = "";
    rowToUpdate.drug_subclass = "";
  };

  _selectDrugSubclass = (
    drugGroupCode,
    drugClassCode,
    drugSubClassCode,
    rowToUpdate
  ) => {
    const validDrugClassCode =
      drugGroupCode in drugMappingData &&
      drugClassCode in drugMappingData[drugGroupCode].drug_classes;
    const validDrugSubClassCode =
      validDrugClassCode &&
      drugSubClassCode in
      drugMappingData[drugGroupCode].drug_classes[drugClassCode]
        .drug_subclasses;

    if (validDrugSubClassCode) {
      rowToUpdate.drug_subclass =
        drugMappingData[drugGroupCode].drug_classes[
          drugClassCode
        ].drug_subclasses[drugSubClassCode].drug_subclass;
    }
  };

  _onDrugGroupChange = (rowToUpdate, dropDownRowToUpdate) => {
    dropDownRowToUpdate.drug_class = this._extractDrugDemographics(rowToUpdate);
    dropDownRowToUpdate.drug_subclass = [];
    rowToUpdate.drug_class = "";
    rowToUpdate.drug_subclass = "";
  };

  _onGpiChange = (value, rowToUpdate, dropDownRowToUpdate) => {
    const gpiCode = value;
    const gpiLen = gpiCode.length;

    if (gpiLen === 0) {
      this._refreshDrugDemographicDropdowns(rowToUpdate, dropDownRowToUpdate);
      return;
    }

    const drugGroupCode = gpiCode.slice(0, 2);
    const drugClassCode = gpiCode.slice(2, 4);
    const drugSubClassCode = gpiCode.slice(4, 6);
    this._selectDrugGroup(drugGroupCode, rowToUpdate, dropDownRowToUpdate);
    this._selectDrugClass(
      drugGroupCode,
      drugClassCode,
      rowToUpdate,
      dropDownRowToUpdate
    );
    this._selectDrugSubclass(
      drugGroupCode,
      drugClassCode,
      drugSubClassCode,
      rowToUpdate
    );
  };

  _selectDrugGroup = (gpiCode, rowToUpdate, dropDownRowToUpdate) => {
    if (gpiCode in drugMappingData) {
      rowToUpdate.drug_group = drugMappingData[gpiCode].drug_group;
      this._onDrugGroupChange(rowToUpdate, dropDownRowToUpdate);
    }
  };

  _selectDrugClass = (
    drugGroupCode,
    drugClassCode,
    rowToUpdate,
    dropDownRowToUpdate
  ) => {
    const validDrugClassCode =
      drugGroupCode in drugMappingData &&
      drugClassCode in drugMappingData[drugGroupCode].drug_classes;

    if (validDrugClassCode) {
      rowToUpdate.drug_class =
        drugMappingData[drugGroupCode].drug_classes[drugClassCode].drug_class;
      this._onDrugClassChange(rowToUpdate, dropDownRowToUpdate);
    }
  };

  _onDrugClassChange = (rowToUpdate, dropDownRowToUpdate) => {
    dropDownRowToUpdate.drug_subclass =
      this._extractDrugDemographics(rowToUpdate);
    rowToUpdate.drug_subclass = "";
  };

  _autoSuggestionResultSelect = (data, rowIndex) => {
    const { state } = this;
    const tierConditions = [...state.tierConditions];
    const rowToUpdate = tierConditions[rowIndex];
    const { name, result } = data;
    const value = result.title;

    rowToUpdate[name] = value;
    tierConditions[rowIndex] = rowToUpdate;

    this.setState({
      tierConditions,
    });
  };

  _onRowDragEnd = (e) => {
    const { node, overIndex, overNode } = e
    let newTierConditions = [...this.state.tierConditions]
    let newDropdownValues = [...this.state.dropdownValues]

    // move dragged element to a new position
    const condition = newTierConditions.splice(node.rowIndex, 1)[0]
    newTierConditions.splice(overIndex, 0, condition)
    const dropdownValueObj = newDropdownValues.splice(node.rowIndex, 1)[0]
    newDropdownValues.splice(overIndex, 0, dropdownValueObj)

    // re-rank conditions
    newTierConditions.forEach((c, index) => c.rank = index + 1)

    this.setState({
      tierConditions: newTierConditions,
      dropdownValues: newDropdownValues,
    })
  }

  _delRow = (rowIndex, stepLevel, data, api) => {
    const { tierConditions, dropdownValues, autoSuggestionValues } = this.state;

    if (tierConditions.length <= 1) return;

    const newTierConditions = tierConditions.filter(
      (cond) => tierConditions.indexOf(cond) !== rowIndex
    ).map((cond, i) => {
      return {
        ...cond,
        rank: i + 1,
      }
    })

    this.setState({
      autoSuggestionValues: autoSuggestionValues.filter(
        (value) => autoSuggestionValues.indexOf(value) !== rowIndex
      ),
      dropdownValues: dropdownValues.filter(
        (value) => dropdownValues.indexOf(value) !== rowIndex
      ),
      tierConditions: newTierConditions,
    });

    api.setRowData(newTierConditions)
  };

  _addRow = () => {
    const { tierConditions, dropdownValues, autoSuggestionValues, form } = this.state;

    this.setState({ isAddingRow: true }, () => {
      const existingRanks = tierConditions.map(c => Number(c.rank) || 0);
      const nextRank = Math.max(...existingRanks, 0) + 1;
      let version = form?.version;
      if ((form?.status || "DRAFT") === "PUBLISHED") {
        version = (parseInt(form?.version || "1.0") + 1).toString() + ".0";
      }

      // Give each new row a unique ID so React sees a completely separate object
      const new_id = this.generateConditionId("tier", form?.name, version, 1, nextRank);

      const newCondition = {
        condition_id: new_id,
        rowId: uuidv4(), // <-- add a unique rowId
        rank: nextRank,
        gpi: '',
        medicare_type: '',
        drug_group: '',
        drug_class: '',
        drug_subclass: '',
        manufacturer: '',
        drug_name: '',
        multi_source: '',
        ddid: '',
        brand_generic: '',
        otc_indicator: '',
        desi_code: '',
        ndc: '',
        route_of_administration: '',
        maintenance_drug_code: '',
        effective_start_date: form.effective_start_date || '',
        effective_end_date: '',
        rxcui: '',
        frf_category: '',
        usp_category: '',
        usp_class: '',
        usp_pharmacotherapeutic_group: '',
        target_system_formulary_tier: form.target_system_formulary_tier || '',
        target_system_formulary_status: form.target_system_formulary_status || ''
      };

      // Deep clone your arrays just to avoid referencing the same objects
      const newConditions = _.cloneDeep(tierConditions);
      newConditions.push(newCondition);

      // same for dropdowns & autosuggestions, if needed
      const newDropdownVals = _.cloneDeep(dropdownValues);
      newDropdownVals.push({
        drug_group: [],
        drug_class: [],
        drug_subclass: [],
        multi_source: [],
        brand_generic: [],
        otc_indicator: []
      });

      const newAutoSuggestVals = _.cloneDeep(autoSuggestionValues);
      newAutoSuggestVals.push({
        manufacturer: { values: [], loading: false },
        drug_name: { values: [], loading: false }
      });

      this.setState({
        tierConditions: newConditions,
        dropdownValues: newDropdownVals,
        autoSuggestionValues: newAutoSuggestVals
      }, () => {
        if (this.gridApi) {
          this.gridApi.setRowData(this.state.tierConditions);
        }
        this.setState({ isAddingRow: false });
      });
    });
  };

  _onCheckboxChange = (el, name, rowIndex) => {
    const { state } = this;
    const tierConditions = [...state.tierConditions];
    const rowToUpdate = tierConditions[rowIndex];
    const {
      target: { checked: value },
    } = el;

    rowToUpdate[name] = value;
    if ((state?.form?.status || "DRAFT") === "PUBLISHED") {
      let condition_grid_version = (parseInt(state?.form?.version || "1.0") + 1).toString() + ".0"
      rowToUpdate["condition_id"] = this.generateConditionId("tier", state?.form?.name, condition_grid_version, 1, rowIndex + 1)
    } else if (!rowToUpdate["condition_id"]) {
      rowToUpdate["condition_id"] = this.generateConditionId("tier", state?.form?.name, form?.version, 1, rowIndex + 1)
    }

    this.setState({
      tierConditions,
      rowUpdated: rowIndex,
      columnUpdated: name,
      columnValue: value,
    });
  };

  // _onDrugCheckboxChange = (el, name, rowIndex) => {
  //   const { state } = this;
  //   const filterDrugData = [...state.filterDrugData];
  //   const rowToUpdate = filterDrugData[rowIndex];
  //   const {
  //     target: { checked: value },
  //   } = el;

  //   rowToUpdate[name] = value;
  //   this.setState({ filterDrugData });
  // };

  _updateFilterConditions = (newFilterConditions) => {
    this.setState({ filterConditions: newFilterConditions });
  };

  _sendForReview = () => {
    const {
      props: { actions },
      state: { form },
    } = this;
    const headerText = "Send Formulary Tier For Review?";

    actions.setModalComponent({
      modalProperties: {
        size: "tiny",
      },
      contents: SaveTierModal({
        form,
        headerText,
        onSaveClick: actions.formularySendForReview,
        toggleModal: actions.toggleModal,
      }),
    });
    actions.toggleModal();
  };

  checkDateValidation = (tierConditions) => {
    const { actions } = this.props;
    let invalidDate = false;
    let formattedTierConditions = tierConditions.map((tierData) => {
      if (!tierData.effective_start_date) {
        invalidDate = true;
      }
      return {
        ...tierData,
        effective_start_date: convertDateToStartTimeObj(
          tierData.effective_start_date
        ),
        effective_end_date: convertDateToEndTimeObj(
          tierData.effective_end_date
        ),
      };
    });
    if (invalidDate) {
      const transitionalPortal = {
        header: "Formulary Tier Save Failed",
        copy: `Please Add effective Start Date before saving`,
      };
      actions.displayTransitionalPortal(transitionalPortal);
    }
    return { formattedTierConditions, invalidDate };
  };
  _saveFormularyTier = () => {
    const { tierConditions, form } = this.state;
    this.props.actions.saveFormularyTierData({
      form,
      tierConditions
    });
  };

  _mapValueToOption = (row) => {
    const data = {
      ...row,
    };

    Object.keys(data).forEach((col) => {
      data[col] = data[col].map((group, index) => ({
        key: index,
        text: group,
        value: group,
      }));
    });
    return data;
  };

  _extractDrugDemographics = (selectedValues) => {
    const validKeys = ["drug_group", "drug_class"];
    const extractedKeys = validKeys.filter((key) => !!selectedValues[key]);
    const extractedData = extractedKeys.reduce(
      (obj, key) => ({
        ...obj[selectedValues[key]],
      }),
      drugDemographicData
    );
    return Object.keys(extractedData);
  };

  _updateDropdownFromCond(initDropdownValues) {
    const { state } = this.props;
    const { formularyTierCondData } = state;
    const dropdownValues = Array(
      formularyTierCondData.length > 0 ? formularyTierCondData.length : 1
    )
      .fill()
      .map(() => ({ ...initDropdownValues[0] }));
    formularyTierCondData?.length &&
      formularyTierCondData.forEach((cond, index) => {
        const values = {};
        if (cond.drug_group) {
          values.drug_group = cond.drug_group;
          dropdownValues[index].drug_class =
            this._extractDrugDemographics(values);
        }
        if (cond.drug_class) {
          values.drug_class = cond.drug_class;
          dropdownValues[index].drug_subclass =
            this._extractDrugDemographics(values);
        }
        if (cond.drug_subclass) {
          values.drug_subclass = cond.drug_subclass;
        }
      });
    return dropdownValues;
  }
  

  _updateState(prevProps) {
    const { props, state } = this;
    const { dropdownValues, autoSuggestionValues, tierConditions: previousState } = state;
    const { formularyTierData, formularyTierCondData } = props.state;
    const { formularyTierCondData: prevFormularyTierCondData } = prevProps.state;

    // Deep clone your default array to ensure each row is unique
    const updatedDropdownValues = _.cloneDeep(this._updateDropdownFromCond(dropdownValues));
    const updatedAutoSuggestionVals = Array(
      formularyTierCondData.length > 0 ? formularyTierCondData.length : 1
    ).fill().map(() => _.cloneDeep(autoSuggestionValues[0]));

    // Only update if there are actual changes from Redux in the conditions array
    let newTierConditions = previousState;
    if (!_.isEqual(prevFormularyTierCondData, formularyTierCondData)) {
      if (Array.isArray(formularyTierCondData) && formularyTierCondData.length === 0) {
        newTierConditions = [_.cloneDeep(state.defaultTierCondition)];
      } else {
        // Important: fully clone each row from Redux
        newTierConditions = formularyTierCondData.map((row, index) => {
          const cloned = _.cloneDeep(row);
          cloned.condition_id = row.condition_id ||
            this.generateConditionId("tier",
              formularyTierData?.name || state?.form?.name,
              formularyTierData?.version || state?.form?.version, 1, index + 1
            );
          cloned.rowId = uuidv4(); // give each row a unique local ID
          cloned.include = cloned.hasOwnProperty("include") ? cloned.include : true;
          cloned.rank = cloned.rank || (index + 1);
          return cloned;
        });
      }
    }

    const form = {
      ...state.form,
      doc_id: formularyTierData.doc_id,
      effective_start_date: formularyTierData.effective_start_date,
      enhanced_tier: formularyTierData.enhanced_tier,
      exchange_tier: formularyTierData.exchange_tier,
      hideHierarchy: formularyTierData.hideHierarchy,
      hierarchy: formularyTierData.hierarchy,
      is_hierarchy_global: formularyTierData.is_hierarchy_global,
      medicare_drug_list: formularyTierData.medicare_drug_list,
      name: formularyTierData.name,
      status: formularyTierData.status,
      supplemental_tier: formularyTierData.supplemental_tier,
      version: formularyTierData.version,
      version_effective_date: formularyTierData.version_effective_date,
    };

    // set updated arrays, ensuring new references
    this.setState({
      autoSuggestionValues: updatedAutoSuggestionVals,
      dropdownValues: updatedDropdownValues,
      form,
      tierConditions: newTierConditions,
    });

    this.docData = {
      doc_id: formularyTierData.doc_id,
      module: this.state.form.module,
      module_name: formularyTierData.name,
      status: formularyTierData.status,
      version: formularyTierData.version,
    };
  }

  _updateFilterState(prevProps) {
    const { props, state } = this;
    const { filterDrugData: previousState } = state;
    const { formularyTierDrugData } = props.state;
    const { formularyTierDrugData: previousFormularyTierDrugData } =
      prevProps.state;
    let filterDrugData = previousState;
    if (!_.isEqual(previousFormularyTierDrugData, formularyTierDrugData)) {
      filterDrugData = formularyTierDrugData.map((row) => ({
        ...row,
        include: true,
      }));
    }

    this.setState({
      filterDrugData,
    });
  }

  // _addFilterConditionsToTierConditions = () => {
  //   const { state } = this;
  //   const { filterConditions, tierConditions } = state;

  //   this.setState({
  //     filterConditions: [],
  //     tierConditions: [...tierConditions, ...filterConditions],
  //   });
  // };

  _updateAutoSuggestions() {
    const { props, state } = this;
    const { autoSuggestData } = props.state;
    const autoSuggestionValues = [...state.autoSuggestionValues];

    if (state.columnUpdated === "manufacturer") {
      autoSuggestionValues[state.rowUpdated].manufacturer.values =
        autoSuggestData["mfg"].map((val) => ({
          title: val,
        }));
      autoSuggestionValues[state.rowUpdated].manufacturer.loading = false;
      if (this.setAutoSuggestionValues)
        this.setAutoSuggestionValues(autoSuggestionValues);
    } else if (state.columnUpdated === "drug_name") {
      autoSuggestionValues[state.rowUpdated].drug_name.values = autoSuggestData[
        "drug_name"
      ].map((val) => ({
        title: val,
      }));
      autoSuggestionValues[state.rowUpdated].drug_name.loading = false;
      if (this.setAutoSuggestionValues)
        this.setAutoSuggestionValues(autoSuggestionValues);
    }

    this.setState(autoSuggestionValues);
  }
  openFrfSearch = () => {
    window.open("/frf-search", "Search FRF", "height=600,width=1000");
  };

  openUspSearch = () => {
    window.open("/usp-search", "Search USP", "height=600,width=1000");
  };

  _updateFormularyTierDrugData = (filteredData) => {
    this.setState({ filterDrugData: filteredData });
  };

  approvalOptionsCallback = () => {
    const doc_name = this.state.form.name;
    this.props.actions.getFormularyTierData({ doc_name });
  };

  render() {
    const { history: { location } } = this.props
    const {
      agGridRef,
      autoSuggestionMinChars,
      autoSuggestionValues,
      cellRenderers,
      conditionsGridRef,
      defaultTierCondition,
      filterConditions,
      filterDrugData,
      form,
      // form: { hideHierarchy },
      tierConditions,
    } = this.state;
    let { editMode } = this.state;
    const { conditionsDropDownOptions } = this.props.state
    // if (hideHierarchy) editMode = false;
    const dropdownOptions = this._constructDropdownOptions();
    let headerText = editMode ? "Create Drug Lists" : "View Drug Lists";
    if (form.doc_id && editMode) headerText = "Edit Drug Lists";
    return (
      <div id="create-drug-list">
        <div className="breadCrumbContainer">
          <BreadCrumb {...this.props} changeToDrugList />
        </div>
        <div className="header">
          {headerText}
          {form.medicare_drug_list === "Y" && (
            <div>
              <FliptButton
                className="primary"
                name="FRF Search"
                onClick={this.openFrfSearch}
              />{" "}
              <FliptButton
                className="primary"
                name="USP Search"
                onClick={this.openUspSearch}
              />
            </div>
          )}
        </div>

        {
          <ApprovalOptions
            docData={this.docData}
            actionCallback={this.approvalOptionsCallback}
          />
        }
        <div className="content">
          <TierConditions
            agGridRef={conditionsGridRef}
            addRow={this._addRow}
            autoSuggestionMinChars={autoSuggestionMinChars}
            autoSuggestionResultSelect={this._autoSuggestionResultSelect}
            autoSuggestionValues={autoSuggestionValues}
            cellRenderers={cellRenderers}
            changeToDrugList
            conditionsDropDownOptions={conditionsDropDownOptions}
            delRow={this._delRow}
            doc_id={form.doc_id}
            dropdownOptions={dropdownOptions}
            editMode={editMode}
            exportAll={this._exportAllDrugs}
            form={form}
            handleChange={this._handleChange}
            onCheckboxChange={this._onCheckboxChange}
            onRowDragEnd={this._onRowDragEnd}
            saveFormularyTier={this._saveFormularyTier}
            sendForReview={this._sendForReview}
            setForm={this.setForm}
            tierConditions={tierConditions}
            updateFields={this._updateFields}
          />
          {/* <TierDrugs
            addFilterConditionsToTierConditions={this._addFilterConditionsToTierConditions}
            agGridRef={agGridRef}
            changeToDrugList
            defaultTierCondition={defaultTierCondition}
            doc_id={form.doc_id}
            editMode={editMode}
            filterConditions={filterConditions}
            filterDrugData={filterDrugData}
            form={form}
            onDrugCheckboxChange={this._onDrugCheckboxChange}
            saveFormularyTier={this._saveFormularyTier}
            sendForReview={this._sendForReview}
            tierConditions={tierConditions}
            updateFilterConditions={this._updateFilterConditions}
            updateFormularyTierDrugData={this._updateFormularyTierDrugData}
          /> */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  state: {
    allFormularyTiersData: state.formularyCreation.allFormularyTiersData,
    formularyDrugClassData: state.formularyCreation.formularyDrugClassData,
    formularyDrugSubClassData:
      state.formularyCreation.formularyDrugSubClassData,
    autoSuggestData: state.autoSuggest.autoSuggestData,
    autoSuggestSearchTerm: state.autoSuggest.autoSuggestSearchTerm,
    formularyTierDrugDemographicData:
      state.formularyCreation.formularyTierDrugDemographicData,
    formularyTierData: state.formularyCreation.formularyTierData,
    formularyTierCondData: state.formularyCreation.formularyTierCondData,
    formularyTierDrugData: state.formularyCreation.formularyTierDrugData,
    conditionsDropDownOptions: state.rpm.conditionsDropDownOptions,
  },
});

const mapDispatchToProps = (dispatch) => {
  const allActions = {
    ...AppActions,
    ...AutoSuggestActions,
    ...FormularyCreationActions,
    ...RPMActions,
  };

  return {
    actions: bindActionCreators(allActions, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateFormularyTier);
